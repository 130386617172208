<template>
  <div class="flex-grow-1"></div>
  <footer
    class="footer-area text-center text-md-start bg-white ps-15 pe-15 pt-20 pb-20 ps-md-40 pe-md-40 pt-md-25 pb-md-25"
  >
    <div class="row align-items-center">
        <p class="text-body-secondary fw-medium mb-0" style="text-align: center;">
          Desarrolado por <a class="text-decoration-none fw-bold transition" href="https://www.idoogroup.com/">IdooGroup</a>
          © <span class="fw-bold text-primary">{{new Date().getFullYear()}}</span>. Todos los derechos reservados.
          <a
            href="https://envytheme.com/"
            class="text-decoration-none fw-bold transition"
            target="_blank"
            >STREET RENT A CAR</a
          >
        </p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "MainFooter",
};
</script>
