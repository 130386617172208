<template>
  <div class="Cargando">
    <div v-show="loading" :class="['modal_cargando', { 'show': loading }]">
      <div class="spinner"></div>
    </div>
  </div>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div
        class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25"
    >
      <div class="d-sm-flex align-items-center">
        <form class="search-box position-relative" @submit.prevent="loadData">
          <input
              type="text" v-model="search"
              @keyup.enter="loadData"
              @change="loadData"
              class="form-control shadow-none text-black rounded-0 border-0"
              placeholder="Buscar ciudad"
          />
          <button class="bg-transparent text-primary transition p-0 border-0">
            <i class="flaticon-search-interface-symbol"></i>
          </button>
        </form>
      </div>
      <div class="d-sm-flex align-items-center mt-10 mt-lg-0">
        <button
            @click="Nuevo"
            class="bg-primary position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block d-inline-block text-decoration-none"
        >
          Nueva ciudad
          <i class="flaticon-plus position-relative ms-5 fs-12"></i>
        </button>
      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
          <tr>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Ciudad
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Abreviatura
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Estado
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              IVA
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
            >
              Acciones
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in ciudadesByName" :key="item.id" v-show="decodeBase64(item.estado.id)==parseInt(estado.id)">
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.name }}
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.siglas }}
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.estado.name }}
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.iva }} %
            </td>
            <td class="shadow-none lh-1 fw-bold text-body-tertiary">
              <div class="btn-group" role="group">
                <button v-if="item.isActive" data-bs-toggle="tooltip"
                        style="padding: 0.8rem"
                        data-bs-placement="top" title="Desactivar ciudad" data-container="body"
                        data-animation="true" class="btn text-success" @click="Desactivar(item.id)">
                  <i class="flaticon-ban d-flex justify-content-center align-items-center"></i></button>
                <button v-else data-bs-toggle="tooltip" data-bs-placement="top"
                        style="padding: 0.8rem"
                        title="Activar ciudad" data-container="body" data-animation="true"
                        class="btn text-warning" @click="Activar(item.id)">
                  <i class="flaticon-check-mark-1 d-flex justify-content-center align-items-center"></i></button>
                <button data-bs-toggle="tooltip" @click="Actualizar(item)"
                        data-bs-placement="top"
                        style="padding: 0.8rem"
                        title="Editar ciudad" data-container="body" data-animation="true"
                        class="btn text-info">
                  <i class="flaticon-pen d-flex justify-content-center align-items-center "></i></button>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="Nuevo text-dark">
    <div v-if="Nuevo_Modal" :class="['modal', { 'show': Nuevo_Modal }]">
      <div class="modal-content bg-white">
        <div class="row mb-3 d-flex align-items-center">
          <h4 class="text-start fw-bold">
            Nueva ciudad
            <i @click="Close_nuevo" class="flaticon-close opacity-10 modal-icon fs-15"></i>
          </h4>
        </div>
        <div class="row mb-3 text-start">
          <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
            <label for="nameCreate" class="form-label ms-1">Nombre</label>
            <input :class="name===''?'is-invalid':''" id="nameCreate" autofocus v-model="name"
                   class="form-control shadow-none rounded-0 text-black">
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
            <label for="abrCreate" class="form-label ms-1">Abreviatura</label>
            <input :class="siglas==='' || siglas.length>3?'is-invalid':''" id="abrCreate" v-model="siglas" maxlength="3"
                   class="form-control shadow-none rounded-0 text-black">
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
            <label for="ivaCreate" class="form-label ms-1">IVA (%)</label>
            <select id="ivaCreate" class="form-control form-select" v-model="iva" required
                    :class="iva==8.00 || iva == 16.00?'is-valid': 'is-invalid'">
              <option value="8.00">8</option>
              <option value="16.00" selected>16</option>
            </select>
          </div>
        </div>
        <div class="row mb-3">
          <div v-show="error_vacios" class="col-12 text-center p-2"
               style="background-color: rgba(255,19,28,0.08)">
              <span class="d-flex align-items-center justify-content-center">
                <i class="flaticon-warning me-2 fs-15"></i>
                No pueden haber campos vac&iacute;os
              </span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-lg-6 d-flex justify-content-start mb-3">
            <button @click="Close_nuevo" class="btn btn-secondary d-block w-100" type="button">Cancelar</button>
          </div>
          <div class="col-md-12 col-lg-6 d-flex justify-content-end mb-3">
            <button @click="Crear" class="btn btn-danger d-block w-100" type="button">Guardar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="Actualizar text-dark">
    <div v-if="Actualizar_Modal" :class="['modal', { 'show': Actualizar_Modal }]">
      <div class="modal-content bg-white">
        <div class="row mb-3 d-flex align-items-center">
          <h4 class="text-start fw-bold">
            Actualizar ciudad
            <i @click="Close_update" class="flaticon-close opacity-10 modal-icon fs-15"></i>
          </h4>
        </div>
        <div class="row mb-3 text-start">
          <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
            <label for="nameUpdate" class="form-label">Nombre</label>
            <input :class="name===''?'is-invalid':''" id="nameUpdate" autofocus v-model="name"
                   class="form-control shadow-none rounded-0 text-black">
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
            <label for="abrUpdate" class="form-label ms-1">Abreviatura</label>
            <input :class="siglas==='' || siglas.length > 3?'is-invalid':''" id="abrUpdate" v-model="siglas"
                   maxlength="3" class="form-control shadow-none rounded-0 text-black">
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
            <label for="ivaUpdate" class="form-label ms-1">IVA (%)</label>
            <select id="ivaCreate" class="form-control form-select" v-model="iva" required
                    :class="iva==8.00 || iva == 16.00?'is-valid': 'is-invalid'">
              <option value="8.00">8</option>
              <option value="16.00">16</option>
            </select>
          </div>
        </div>
        <div class="row mb-3">
          <div v-show="error_vacios" class="col-12 text-center p-2"
               style="background-color: rgba(255,19,28,0.08)">
              <span class="d-flex align-items-center justify-content-center">
                <i class="flaticon-warning me-2 fs-15"></i>
                No pueden haber campos vac&iacute;os
              </span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-lg-6 d-flex justify-content-start mb-3">
            <button @click="Close_update" class="btn btn-secondary d-block w-100" type="button">Cancelar</button>
          </div>
          <div class="col-md-12 col-lg-6 d-flex justify-content-end mb-3">
            <button @click="Editar" class="btn btn-danger d-block w-100" type="button">Actualizar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import {decodeBase64} from "@/util";
import {
  createCity, disableCity, enableCity,
  getCitiesByName, updateCity
} from "@/actions";

export default {
  name: "CiudadList",
  data() {
    return {
      loading: false,
      ciudadesByName: [],
      search: "",
      Nuevo_Modal: false,
      Actualizar_Modal: false,
      name: '',
      id: 0,
      siglas: '',
      iva: 0,
      estado: this.$store.state.estado,
      error_vacios: false,
    }
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData(){
      this.loading = true;
      getCitiesByName(this.search).then(resp=>{
        this.ciudadesByName = resp.data.data.ciudadesByName;
      }).catch(err=>console.log(err)).finally(()=>{
        this.loading = false;
      })
    },
    decodeBase64,
    Nuevo() {
      this.Nuevo_Modal = true
    },
    Actualizar(item) {
      this.name = item.name
      this.siglas = item.siglas
      this.id = item.id
      this.iva = item.iva
      this.Actualizar_Modal = true
    },
    Editar() {
      if (this.name === '' || this.id === 0 || this.estado === null || this.siglas === '' || this.siglas.length > 3
          || !(this.iva==8.00 || this.iva == 16.00)) {
        this.error_vacios = true
        return false
      }
      this.error_vacios = false
      updateCity({
        name: this.name,
        siglas: this.siglas,
        iva: parseInt(this.iva),
        estado: parseInt(this.estado.id),
        id: parseInt(this.id)
      }).then(response => {
        if (response.data.data.updateCiudad.success) {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            customClass: {
              popup: 'bg-white',
              title: 'text-dark'
            },
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "success",
            title: "Operación realizada con éxito"
          });
          this.Close_update();
          this.loadData();
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            customClass: {
              popup: 'bg-white',
              title: 'text-dark'
            },
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "error",
            title: response.data.data.updateCiudad.error
          });
        }
      }).catch(error => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          customClass: {
            popup: 'bg-white',
            title: 'text-dark'
          },
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "error",
          title: error
        });
      })
    },
    Close_nuevo() {
      this.id = 0;
      this.name = ''
      this.iva = 0
      this.siglas = ""
      this.Nuevo_Modal = false
    },
    Close_update() {
      this.id = 0;
      this.name = ''
      this.iva = 0
      this.siglas = ""
      this.Actualizar_Modal = false
    },
    Crear() {
      if (this.name === '' || this.estado === null || this.siglas === '' || this.siglas.length > 3
          || !(this.iva==8.00 || this.iva == 16.00)) {
        this.error_vacios = true
        return false
      }
      this.error_vacios = false
      createCity({
        name: this.name,
        siglas: this.siglas,
        estado: parseInt(this.estado.id),
        iva: parseInt(this.iva)
      }).then(response => {
        if (response.data.data.createCiudad.success) {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            customClass: {
              popup: 'bg-white',
              title: 'text-dark'
            },
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "success",
            title: "Operación realizada con éxito"
          });
          this.Close_nuevo();
          this.loadData();
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            customClass: {
              popup: 'bg-white',
              title: 'text-dark'
            },
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "error",
            title: response.data.data.createCiudad.error
          });
        }
      }).catch(error => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          customClass: {
            popup: 'bg-white',
            title: 'text-dark'
          },
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "error",
          title: error
        });
      })
    },
    Activar(id) {
      enableCity({
        id: parseInt(id)
      }).then(response => {
        if (response.data.data.activeCiudad.success) {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            customClass: {
              popup: 'bg-white',
              title: 'text-dark'
            },
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "success",
            title: "Operación realizada con éxito"
          });
          this.loadData();
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            customClass: {
              popup: 'bg-white',
              title: 'text-dark'
            },
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "error",
            title: "Error al activar la ciudad"
          });
        }
      }).catch(error => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          customClass: {
            popup: 'bg-white',
            title: 'text-dark'
          },
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "error",
          title: error
        });
      })
    },
    Desactivar(id) {
      disableCity({
        id: parseInt(id)
      }).then(response => {
        if (response.data.data.desactiveCiudad.success) {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            customClass: {
              popup: 'bg-white',
              title: 'text-dark'
            },
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "success",
            title: "Operación realizada con éxito"
          });
          this.loadData();
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            customClass: {
              popup: 'bg-white',
              title: 'text-dark'
            },
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "error",
            title: "Error al desactivar la ciudad"
          });
        }
      }).catch(error => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          customClass: {
            popup: 'bg-white',
            title: 'text-dark'
          },
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "error",
          title: error
        });
      })
    },
  },
};
</script>
<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.modal-content {
  padding: 20px;
  width: 50%;
}

@media (max-width: 767px) {
  .modal-content {
    border-radius: 15px;
    padding: 10px;
    width: 90%;
  }
}

.modal {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.modal.show {
  opacity: 1;
}

</style>
