import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import * as apolloProvider from "./apollo.provider";
import BootstrapVueNext from "bootstrap-vue-next";
import VueApexCharts from "vue3-apexcharts";
import {QuillEditor} from '@vueup/vue-quill'
import Vue3Prism from 'vue3-prism/lib/Vue3Prism.common.js'
import VueDatePicker from '@vuepic/vue-datepicker';
import * as Sentry from "@sentry/vue";
import { vMask } from "@opentf/vue-input-mask";


import '@vuepic/vue-datepicker/dist/main.css';
import 'maz-ui/styles';
import '@sweetalert2/theme-wordpress-admin/wordpress-admin.scss';
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue-next/dist/bootstrap-vue-next.css";
import "swiper/css";
import "swiper/css/bundle";
import 'flatpickr/dist/flatpickr.css';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import '@vueup/vue-quill/dist/vue-quill.bubble.css';
import "vue3-prism/lib/Vue3Prism.css";
import "./assets/custom.scss";
import "@/assets/js/cookie";


const appInstance = createApp(App);

if(process.env.NODE_ENV != "development"){
    Sentry.init({
        appInstance,
        dsn: "https://a7588b3462c708ef556b516b4d717609@o4507392224067584.ingest.us.sentry.io/4507423830507520",
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration(),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}

appInstance.use(store)
appInstance.use(apolloProvider.provider)
appInstance.use(BootstrapVueNext)
appInstance.use(Vue3Prism)
appInstance.use(VueApexCharts)
appInstance.component('vMask', vMask);
appInstance.component('QuillEditor', QuillEditor)
appInstance.component('VueDatePicker', VueDatePicker);
appInstance.use(router);
appInstance.mount('#app');

if (localStorage.getItem("store")) {
    store.replaceState(Object.assign({}, store.state, JSON.parse(localStorage.getItem("store"))));
}

window.addEventListener("beforeunload",()=>{
    localStorage.setItem("store",JSON.stringify(store.state));
});
