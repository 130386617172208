<template>
  <div class="Cargando">
    <div v-show="loading || loadingDif" :class="['modal_cargando', { 'show': loading || cargando }]">
      <div class="spinner"></div>
    </div>
  </div>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div
        class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25"
    >
      <div class="d-sm-flex align-items-center">
        <form class="search-box position-relative" @submit.prevent="()=>loadDatas(true)">
          <input
              type="text" v-model="search"
              @keyup.enter="()=>loadDatas(true)"
              @change="()=>loadDatas(true)"
              class="form-control shadow-none text-black rounded-0 border-0"
              placeholder="Buscar cliente"
          />
          <button class="bg-transparent text-primary transition p-0 border-0">
            <i class="flaticon-search-interface-symbol"></i>
          </button>
        </form>
      </div>
      <div class="d-sm-flex align-items-center mt-10 mt-lg-0">
        <button
            @click="Nuevo"
            class="bg-primary position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block d-inline-block text-decoration-none"
        >
          Nuevo cliente
          <i class="flaticon-plus position-relative ms-5 fs-12"></i>
        </button>
      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
          <tr>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Foto de cliente
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              ID
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Nombre
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Apellidos
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Email
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Puntos
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
            >
              Acciones
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in clientesByName" :key="item.id">
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              <div class="ms-3" v-if="item.photo!==''">
                <img class="rounded-circle img-categoria cursor-pointer"
                     :src="getImageUrl(item.photo)"></div>
              <div class="ms-3" v-else>
                <img class="rounded-circle img-categoria cursor-pointer"
                     src="@/assets/images/clientes/no_photo.png"></div>
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ formatearNumero(decodeIDTable(item.id)) }}
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.nombre }}
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.apellidos }}
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.user ? item.user.email : "" }}
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.puntos }}
            </td>
            <td class="shadow-none lh-1 fw-bold text-body-tertiary">
              <div class="btn-group" role="group">
                <button v-if="item.isActive" data-bs-toggle="tooltip"
                        style="padding: 0.8rem"
                        data-bs-placement="top" title="Desactivar cliente" data-container="body"
                        data-animation="true" class="btn text-success" @click="Desactivar(item.id)">
                  <i class="flaticon-ban d-flex justify-content-center align-items-center"></i>
                </button>
                <button v-else data-bs-toggle="tooltip" data-bs-placement="top"
                        style="padding: 0.8rem"
                        title="Activar cliente" data-container="body" data-animation="true"
                        class="btn text-warning" @click="Activar(item.id)">
                  <i class="flaticon-check-mark-1 d-flex justify-content-center align-items-center"></i>
                </button>
                <button data-bs-toggle="tooltip" @click="Actualizar(item.id)"
                        style="padding: 0.8rem"
                        data-bs-placement="top"
                        title="Editar cliente" data-container="body" data-animation="true"
                        class="btn text-info">
                  <i class="flaticon-pen d-flex justify-content-center align-items-center "></i>
                </button>
                <button data-bs-toggle="tooltip" @click="Details(item.id)"
                        data-bs-placement="top"
                        style="padding: 0.8rem"
                        title="Ver detalles del cliente" data-container="body" data-animation="true"
                        class="btn text-secondary">
                  <i class="flaticon-eye d-flex justify-content-center align-items-center "></i>
                </button>
                <button data-bs-toggle="tooltip" @click="Open_Points(item)"
                        style="padding: 0.8rem"
                        data-bs-placement="top"
                        title="Ver puntos del cliente" data-container="body" data-animation="true"
                        class="btn text-secondary">
                  <i class="flaticon-wallet  d-flex justify-content-center align-items-center"></i>
                </button>
                <button data-bs-toggle="tooltip" @click="Open_files(item)"
                        data-bs-placement="top"
                        style="padding: 0.8rem"
                        title="Subir documentos del cliente" data-container="body" data-animation="true"
                        class="btn text-info">
                  <i class="flaticon-document d-flex justify-content-center align-items-center "></i>
                </button>
                <div v-if="item.loadingInfo" class="spinner-border text-danger icon-loading-custom" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
                <button v-else data-bs-toggle="tooltip" @click="getInfo(item)"
                        data-bs-placement="top"
                        style="padding: 0.8rem"
                        title="Información de facturación" data-container="body" data-animation="true"
                        class="btn text-info">
                  <i class="flaticon-money d-flex justify-content-center align-items-center "></i>
                </button>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing pt-2 pb-2">
    <div class="row">
      <div class="col-lg-6 d-flex align-items-center justify-content-center text-dark">
        <p class="me-3" style="margin-bottom: 0px">Elementos por p&aacute;gina</p>
        <select class="form-control" style="width: 50px; height: 40px; padding: 5px" v-model="cant"
                @change="loadDatas(true)">
          <option selected :value=10>10</option>
          <option :value=50>50</option>
          <option :value=100>100</option>
        </select>
      </div>
      <div class="col-lg-3 d-flex align-items-center justify-content-center text-dark">
        <button class="btn btn-dark me-3" style="height: 40px" @click="prevPage" :disabled="!hasPreviousPage"><i
            class="flaticon-chevron-1 "></i></button>
        <button class="btn btn-dark" style="height: 40px" @click="nextPage" :disabled="!hasNextPage"><i
            class="flaticon-chevron"></i></button>
      </div>
    </div>
  </div>

  <div class="Nuevo text-dark">
    <div v-if="Nuevo_Modal" :class="['modal', { 'show': Nuevo_Modal }]">
      <div class="modal-content bg-white">
        <div class="row mb-3 d-flex align-items-center">
          <h4 class="text-start fw-bold">
            {{ accion === 'nuevo' ? 'Nuevo cliente' : 'Actualizar cliente' }}
            <i @click="Close_nuevo" class="flaticon-close opacity-10 modal-icon fs-15"></i>
          </h4>
        </div>
        <div v-show="ventana===0">
          <div class="row mb-3 text-start">
            <h6>Datos personales</h6>
            <div class="col-lg-4 col-md-6 col-sm-12 mb-3 tratamiento">
              <label class="form-label ms-1">Tratamiento</label>
              <input v-model="tratamiento" id="tratamiento"
                     :class="`form-control shadow-none rounded-0 text-black ${tratamiento!=='' && tratamiento !== null?'':'is-invalid'}`">
              <div v-if="tratamiento =='' || tratamiento == null" class="invalid-feedback">
                Campo no puede estar vac&iacute;o
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 mb-3 nombre">
              <label class="form-label ms-1">Nombre</label>
              <input id="nombre_cliente" v-model="nombre"
                     :class="`form-control shadow-none rounded-0 text-black ${nombre!==''?'':'is-invalid'}`">
              <div v-if="nombre==''" class="invalid-feedback">
                Campo no puede estar vac&iacute;o
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 mb-3 apellidos">
              <label class="form-label ms-1">Apellidos</label>
              <input id="apellidos_cliente" v-model="apellidos"
                     :class="`form-control shadow-none rounded-0 text-black ${apellidos!==''?'':'is-invalid'}`">
              <div v-if="apellidos==''" class="invalid-feedback">
                Campo no puede estar vac&iacute;o
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 mb-3 nacimiento">
              <label class="form-label ms-1">Fecha de nacimiento</label>
              <input id="nacimiento_cliente" v-model="fechaNacimiento" type="date" :max="minDateBorn"
                     :class="`form-control shadow-none rounded-0 text-black ${fechaNacimiento!=='' && fechaNacimiento!==null?'':'is-invalid'}`">
              <div v-if="fechaNacimiento=='' || fechaNacimiento==null" class="invalid-feedback">
                Campo no puede estar vac&iacute;o
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 mb-3 nacionalidad">
              <label class="form-label ms-1">Nacionalidad</label>
              <input id="nacionalidad_cliente" v-model="nacionalidad"
                     :class="`form-control shadow-none rounded-0 text-black ${nacionalidad!=='' && nacionalidad!==null?'':'is-invalid'}`">
              <div v-if="nacionalidad=='' || nacionalidad==null" class="invalid-feedback">
                Campo no puede estar vac&iacute;o
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 mb-3 tarjeta_numero">
              <label class="form-label ms-1">N&uacute;mero de tarjeta</label>
              <input id="tarjeta_numero" v-model="tarjeta_numero" type="number" maxlength="16"
                     :class="`form-control shadow-none rounded-0 text-black ${tarjeta_numero!=='' && tarjeta_numero!==null && tarjeta_numero.toString().length<16?'':'is-invalid'}`">
              <div v-if="tarjeta_numero==='' || tarjeta_numero==null "
                   class="invalid-feedback">
                Campo no puede estar vac&iacute;o y tiene que tener menos de 16 d&iacute;gitos
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 mb-3 tarjeta_vencimiento">
              <label class="form-label ms-1">Vencimiento de la tarjeta</label>
              <VueDatePicker v-model="tarjeta_vencimiento"
                             id="tarjeta_vencimiento"
                             auto-apply
                             locale="es"
                             :dark="this.$store.state.isDarkMode"
                             teleport-center
                             month-picker
                             required
                             :min-date="new Date()"
                             :class="tarjeta_vencimiento=='' || tarjeta_vencimiento==null?'is-invalid':'border-success'"
                             :state="!(tarjeta_vencimiento=='' || tarjeta_vencimiento==null)"
                             time-picker-inline
                             :enable-time-picker="false"
                             format="MM/yyyy"
                             class="shadow-none rounded-0 text-black"/>
              <div v-if="tarjeta_vencimiento=='' || tarjeta_vencimiento==null" class="invalid-feedback">
                Campo no puede estar vac&iacute;o
              </div>
            </div>
            <h6>Datos de contacto</h6>
            <div class="col-lg-6 col-md-6 col-sm-12 mb-3 correo">
              <label class="form-label ms-1">Correo</label>
              <input id="email_cliente" v-model="email"
                     :class="`form-control shadow-none rounded-0 text-black ${email!=='' && email!==null && validateEmail?'':'is-invalid'}`">
              <div v-if="email=='' || email==null" class="invalid-feedback">
                Campo no puede estar vac&iacute;o
              </div>
              <div v-else-if="!validateEmail"
                   class="invalid-feedback">
                Formato de correo inv&aacute;lido
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 mb-3 idioma">
              <label class="form-label ms-1">Idioma</label>
              <input v-model="idioma" class="form-control shadow-none rounded-0 text-black">
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12 mb-3 telefono">
              <label class="form-label ms-1">Tel&eacute;fono</label><br>
              <MazPhoneNumberInput
                  v-model="telefono"
                  v-model:country-code="codigo"
                  no-radius
                  :error="error_telefono"
                  :preferred-countries="['MX', 'US', 'CU']"
                  :translations="{
                  countrySelector: {
                    placeholder: 'Código de país',
                    error: 'Seleccione un país',
                    searchPlaceholder: 'Buscar un país',
                  },
                  phoneInput: {
                    placeholder: 'Número de teléfono',
                    example: 'Ejemplo:',
                  },
                }"
              />
              <div v-if="error_telefono" class="invalid-feedback">
                Campo no puede estar vac&iacute;o y tiene que tener el formato correcto
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-lg-6 d-flex justify-content-start mb-3">
              <button @click="Close_nuevo" class="btn btn-secondary d-block w-100" type="button">Cancelar</button>
            </div>
            <div class="col-md-12 col-lg-6 d-flex justify-content-end mb-3">
              <button @click="Siguiente_Dos" class="btn btn-danger d-block w-100"
                      type="button">Siguiente
              </button>
            </div>
          </div>
        </div>
        <div v-show="ventana===1">
          <div class="row mb-3 text-start">
            <h6>Direcci&oacute;n</h6>
            <div class="col-lg-4 col-md-6 col-sm-12 mb-3 pais">
              <label class="form-label ms-1">Pa&iacute;s</label>
              <input v-model="direccionPais" v-if="accion!=='nuevo'"
                     :class="`form-control shadow-none rounded-0 text-black ${direccionPais!=='' && direccionPais !== null?'':'is-invalid'}`">
              <vue-google-autocomplete @input="onInputPais" v-else
                                       ref="addresscountry"
                                       id="addresscountry"
                                       classname="form-control shadow-none rounded-0 text-black"
                                       placeholder="Escriba el país"
                                       v-on:placechanged="getAddressCountry"
                                       types="(regions)"
              ></vue-google-autocomplete>
              <div v-if="direccionPais==='' || direccionPais==null || direccionPais===0" class="invalid-feedback">
                Campo no puede estar vac&iacute;o
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 mb-3 estado">
              <label class="form-label ms-1">Estado</label>
              <input v-model="direccionEstado" v-if="accion!=='nuevo'"
                     :class="`form-control shadow-none rounded-0 text-black ${direccionEstado!=='' && direccionEstado !== null?'':'is-invalid'}`">
              <vue-google-autocomplete @input="onInputEstado" v-else
                                       ref="addressstate"
                                       id="addressstate"
                                       classname="form-control shadow-none rounded-0 text-black"
                                       placeholder="Escriba el estado"
                                       v-on:placechanged="getAddressState"
                                       types="(regions)"
              ></vue-google-autocomplete>
              <div v-if="direccionEstado=='' || direccionEstado==null || direccionEstado===0" class="invalid-feedback">
                Campo no puede estar vac&iacute;o
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 mb-3 ciudad">
              <label class="form-label ms-1">Ciudad</label>
              <input v-model="direccionCiudad" v-if="accion!=='nuevo'"
                     :class="`form-control shadow-none rounded-0 text-black ${direccionCiudad!=='' && direccionCiudad !== null?'':'is-invalid'}`">
              <vue-google-autocomplete @input="onInputCiudad" v-else
                                       ref="addresscity"
                                       id="addresscity"
                                       classname="form-control shadow-none rounded-0 text-black"
                                       placeholder="Escriba la ciudad"
                                       v-on:placechanged="getAddressCity"
                                       types="(cities)"
              ></vue-google-autocomplete>
              <div v-if="direccionCiudad=='' || direccionCiudad === null || direccionCiudad === 0"
                   class="invalid-feedback">
                Campo no puede estar vac&iacute;o
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 mb-3 codigo_postal">
              <label class="form-label ms-1">C&oacute;digo postal</label>
              <input id="direccion_postal" v-model="postal" type="number" maxlength="5"
                     :class="`form-control shadow-none rounded-0 text-black ${postal!=='' && postal.toString().length>=4?'':'is-invalid'}`">
              <div v-if="postal=='' || postal.toString().length < 4" class="invalid-feedback">
                Campo no puede estar vac&iacute;o y debe tener al menos 4 d&iacute;gitos
              </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 mb-3 direccion">
              <label class="form-label ms-1">Direcci&oacute;n</label>
              <textarea id="direccion_cliente" v-model="direccion"
                        :class="`form-control shadow-none rounded-0 text-black ${direccion!=='' && direccion!==null?'':'is-invalid'}`">
              </textarea>
              <div v-if="direccion=='' || direccion==null" class="invalid-feedback">
                Campo no puede estar vac&iacute;o
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-lg-6 d-flex justify-content-start mb-3">
              <button @click="Regresar" class="btn btn-secondary d-block w-100" type="button">Regresar</button>
            </div>
            <div class="col-md-12 col-lg-6 d-flex justify-content-end mb-3">
              <button @click="Siguiente_Tres" class="btn btn-danger d-block w-100"
                      type="button">Siguiente
              </button>
            </div>
          </div>
        </div>
        <div v-show="ventana===2">
          <div class="row mb-3 text-start">
            <h6>Documento de identidad</h6>
            <div class="col-lg-4 col-md-6 col-sm-12 mb-3 pais">
              <label class="form-label ms-1">Pa&iacute;s de expedici&oacute;n</label>
              <input v-model="identidadPais" v-if="accion!=='nuevo'"
                     :class="`form-control shadow-none rounded-0 text-black ${identidadPais!=='' && identidadPais !== null?'':'is-invalid'}`">
              <vue-google-autocomplete @input="onInputPaisIdentidad" v-else
                                       ref="identitycountry"
                                       id="identitycountry"
                                       classname="form-control shadow-none rounded-0 text-black"
                                       placeholder="Escriba el país"
                                       v-on:placechanged="getIdentityCountry"
                                       types="(regions)"
              ></vue-google-autocomplete>
              <div v-if="identidadPais==='' || identidadPais===null" class="invalid-feedback">
                Campo no puede estar vac&iacute;o
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 mb-3 tipo">
              <label class="form-label ms-1">Tipo de identidad</label>
              <input id="identidad_tipo" v-model="identidadTipo"
                     :class="`form-control shadow-none rounded-0 text-black ${identidadTipo!=='' && identidadTipo!==null?'':'is-invalid'}`">
              <div v-if="identidadTipo=='' || identidadTipo==null" class="invalid-feedback">
                Campo no puede estar vac&iacute;o
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 mb-3 numero_identidad">
              <label class="form-label ms-1">N&uacute;mero de identidad</label>
              <input id="identidad_numero" v-model="identidadNumero" maxlength="11"
                     :class="`form-control shadow-none rounded-0 text-black ${identidadNumero!=='' && identidadNumero!==null && !validateNotNumbers(identidadNumero)?'':'is-invalid'}`">
              <div v-if="identidadNumero==='' || identidadNumero===null" class="invalid-feedback">
                Campo no puede estar vac&iacute;o
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 mb-3 fecha_expedicion">
              <label class="form-label ms-1">Fecha de expedici&oacute;n</label>
              <input id="identidad_expedicion" v-model="identidadExpedicion" :min="fechaNacimiento"
                     :class="`form-control shadow-none rounded-0 text-black ${identidadExpedicion!=='' && identidadExpedicion!==null?'':'is-invalid'}`"
                     type="date">
              <div v-if="identidadExpedicion=='' || identidadExpedicion==null" class="invalid-feedback">
                Campo no puede estar vac&iacute;o
              </div>
            </div>
            <h6>Licencia de conducci&oacute;n</h6>
            <div class="col-lg-4 col-md-6 col-sm-12 mb-3 numero_licencia">
              <label class="form-label ms-1">N&uacute;mero de licencia</label>
              <input id="licencia_numero" v-model="licenciaNumero" maxlength="15"
                     :class="`form-control shadow-none rounded-0 text-black ${licenciaNumero!=='' && licenciaNumero!==null?'':'is-invalid'}`">
              <div v-if="licenciaNumero=='' || licenciaNumero==null" class="invalid-feedback">
                Campo no puede estar vac&iacute;o
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 mb-3 clase">
              <label class="form-label ms-1">Clase</label>
              <input id="licencia_clase" v-model="licenciaClase"
                     :class="`form-control shadow-none rounded-0 text-black ${licenciaClase!=='' && licenciaClase!==null?'':'is-invalid'}`">
              <div v-if="licenciaClase=='' || licenciaClase==null" class="invalid-feedback">
                Campo no puede estar vac&iacute;o
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 mb-3 fecha_otorgamiento">
              <label class="form-label ms-1">Fecha de otorgamiento</label>
              <input id="licencia_otoramiento" v-model="licenciaOtorgamiento" :min="fechaNacimiento" :max="dateNow"
                     :class="`form-control shadow-none rounded-0 text-black ${licenciaOtorgamiento!=='' && licenciaOtorgamiento!==null?'':'is-invalid'}`"
                     type="date">
              <div v-if="licenciaOtorgamiento=='' || licenciaOtorgamiento==null" class="invalid-feedback">
                Campo no puede estar vac&iacute;o
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 mb-3 fecha_vencimiento">
              <label class="form-label ms-1">Fecha de vencimiento</label>
              <input id="licencia_vencimiento" v-model="licenciaVencimiento" :min="expDate"
                     :class="`form-control shadow-none rounded-0 text-black ${licenciaVencimiento!=='' && licenciaVencimiento!==null?'':'is-invalid'}`"
                     type="date">
              <div v-if="licenciaVencimiento=='' || licenciaVencimiento==null" class="invalid-feedback">
                Campo no puede estar vac&iacute;o
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-lg-6 d-flex justify-content-start mb-3">
              <button @click="Regresar" :disabled="saveChanges" class="btn btn-secondary d-block w-100" type="button">
                Regresar
              </button>
            </div>
            <div class="col-md-12 col-lg-6 d-flex justify-content-end mb-3">
              <button v-if="accion==='nuevo'" :disabled="validateThree || saveChanges" @click="Crear"
                      class="btn btn-danger d-block w-100">
                Guardar
              </button>
              <button v-else @click="Editar" :disabled="validateThree || saveChanges"
                      class="btn btn-danger d-block w-100">
                Guardar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="Points text-dark">
    <div v-if="Points_Modal" :class="['modal', { 'show': Points_Modal }]">
      <div class="modal-content bg-white">
        <div class="row mb-3 d-flex align-items-center">
          <h4 class="text-start fw-bold">
            Puntos del cliente
            <i @click="Close_points" class="flaticon-close opacity-10 modal-icon fs-15"></i>
          </h4>
        </div>
        <div class="row mb-3 text-start">
          <div class="col-lg-12 col-md-12 col-sm-12 mb-3 points">
            <label class="form-label ms-1">Puntos</label>
            <input v-model="points" id="points" type="number" min="0"
                   :class="`form-control shadow-none rounded-0 text-black ${points<0 || points==='' || points===null?'is-invalid':''}`"
            >
            <div v-if="points<0 || points==='' || points===null" class="invalid-feedback">
              Campo no puede estar vac&iacute;o y no puede ser negativo
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-lg-6 d-flex justify-content-start mb-3">
            <button :disabled="saveChanges" @click="Close_points" class="btn btn-secondary d-block w-100" type="button">
              Cancelar
            </button>
          </div>
          <div class="col-md-12 col-lg-6 d-flex justify-content-end mb-3">
            <button :disabled="saveChanges || points<0 || points==='' || points===null" @click="Guardar_points"
                    class="btn btn-danger d-block w-100" type="button">Guardar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="Files text-dark">
    <div v-if="Files_Modal" :class="['modal', { 'show': Files_Modal }]">
      <div class="modal-content bg-white">
        <div class="row mb-3 d-flex align-items-center">
          <h4 class="text-start fw-bold">
            Subir documentos del cliente
            <i @click="close_Files" class="flaticon-close opacity-10 modal-icon fs-15"></i>
          </h4>
        </div>
        <div class="row mb-3 text-start">
          <div class="col-lg-12 col-md-12 col-sm-12 mb-3 Carnet">
            <div class="row">
              <h6>Documento de identidad</h6>
              <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
                <label class="form-label ms-1">Frente</label>
                <input :class="photo_carnet_front===null?'is-invalid':''" @change="onCarnet_front"
                       class="form-control shadow-none rounded-0 text-black" type="file">
                <div v-if="photo_carnet_front===null" class="invalid-feedback">
                  Campo no puede estar vac&iacute;o
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
                <label class="form-label ms-1">Dorso</label>
                <input :class="photo_carnet_back===null?'is-invalid':''" @change="onCarnet_back"
                       class="form-control shadow-none rounded-0 text-black" type="file">
                <div v-if="photo_carnet_back===null" class="invalid-feedback">
                  Campo no puede estar vac&iacute;o
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 mb-3 Licencia">
            <div class="row">
              <h6>Licencia</h6>
              <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
                <label class="form-label ms-1">Frente</label>
                <input :class="photo_licencia_front===null?'is-invalid':''" @change="onLicencia_front"
                       class="form-control shadow-none rounded-0 text-black" type="file">
                <div v-if="photo_licencia_front===null" class="invalid-feedback">
                  Campo no puede estar vac&iacute;o
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
                <label class="form-label ms-1">Dorso</label>
                <input :class="photo_licencia_back===null?'is-invalid':''" @change="onLicencia_back"
                       class="form-control shadow-none rounded-0 text-black" type="file">
                <div v-if="photo_licencia_back===null" class="invalid-feedback">
                  Campo no puede estar vac&iacute;o
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-3 text-start">
            <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
              <label class="form-label ms-1">Foto de cliente</label>
              <input :class="cliente_photo===null?'is-invalid':''" @change="onPhoto"
                     class="form-control shadow-none rounded-0 text-black" type="file">
            </div>
          </div>
          <div class="row mb-3 text-start">
            <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
              <img class="img-fluid img-cliente" :src="preview" alt="Imagen del cliente">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-lg-6 d-flex justify-content-start mb-3">
            <button :disabled="saveChanges" @click="close_Files" class="btn btn-secondary d-block w-100" type="button">
              Cancelar
            </button>
          </div>
          <div class="col-md-12 col-lg-6 d-flex justify-content-end mb-3">
            <button :disabled="validateFiles || saveChanges" @click="Subir_Photo" class="btn btn-danger d-block w-100"
                    type="button">Guardar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="Details text-dark">
    <div v-if="Details_Modal" :class="['modal', { 'show': Details_Modal }]">
      <div class="modal-content bg-white">
        <div class="row mb-3 d-flex align-items-center">
          <h4 class="text-start fw-bold">
            Detalles del cliente
            <i @click="close_Details" class="flaticon-close opacity-10 modal-icon fs-15"></i>
          </h4>
        </div>
        <div v-show="pagina===0" class="row mb-1 text-start">
          <h5>Datos personales</h5>
          <div class="col-lg-3 col-md-6 col-sm-12">
            <h6>Tratamiento:</h6>
            <p>{{ item.tratamiento }}</p>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12">
            <h6>Nombre:</h6>
            <p>{{ item.nombre }}</p>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12">
            <h6>Apellidos:</h6>
            <p>{{ item.apellidos }}</p>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12">
            <h6>Fecha de nacimiento:</h6>
            <p>{{ item.nacimiento }}</p>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12">
            <h6>Nacionalidad:</h6>
            <p>{{ item.nacionalidad }}</p>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12">
            <h6>N&uacute;mero de tarjeta:</h6>
            <p>{{ item.tarjetaSet.length > 0 ? item.tarjetaSet[0].numero : "" }}</p>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12">
            <h6>Autorizaci&oacute;n de la tarjeta:</h6>
            <p>{{ item.tarjetaSet.length > 0 ? item.tarjetaSet[0].autorizacion : "" }}</p>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12">
            <h6>Vencimiento de la tarjeta:</h6>
            <p>{{ item.tarjetaSet.length > 0 ? item.tarjetaSet[0].vencimiento : "" }}</p>
          </div>
        </div>
        <div v-show="pagina===0" class="row mb-3 text-start">
          <h5>Documento de identidad</h5>
          <div class="col-lg-3 col-md-6 col-sm-12">
            <h6>País de expedici&oacute;n:</h6>
            <p>{{ item.identidadSet.length > 0 ? item.identidadSet[0].pais : "" }}</p>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12">
            <h6>Tipo de documento:</h6>
            <p>{{ item.identidadSet.length > 0 ? item.identidadSet[0].tipo : "" }}</p>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12">
            <h6>No. documento:</h6>
            <p>{{ item.identidadSet.length > 0 ? item.identidadSet[0].numero : "" }}</p>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12">
            <h6>Fecha de expedici&oacute;n:</h6>
            <p>{{ item.identidadSet.length > 0 ? item.identidadSet[0].expedicion : "" }}</p>
          </div>
        </div>
        <div v-show="pagina===0" class="row">
          <div class="col-md-12 col-lg-6 d-flex justify-content-start mb-3">
            <button @click="close_Details" class="btn btn-secondary d-block w-100" type="button">Cancelar</button>
          </div>
          <div class="col-md-12 col-lg-6 d-flex justify-content-end mb-3">
            <button @click="pagina=1" class="btn btn-danger d-block w-100" type="button">
              Siguiente
            </button>
          </div>
        </div>
        <div v-show="pagina===1" class="row mb-1 text-start">
          <h5>Licencia de conducci&oacute;n</h5>
          <div class="col-lg-3 col-md-6 col-sm-12">
            <h6>No. licencia:</h6>
            <p>{{ item.licenciaSet.length > 0 ? item.licenciaSet[0].numero : "" }}</p>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12">
            <h6>Clase:</h6>
            <p>{{ item.licenciaSet.length > 0 ? item.licenciaSet[0].clase : "" }}</p>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12">
            <h6>Fecha de otorgamiento:</h6>
            <p>{{ item.licenciaSet.length > 0 ? item.licenciaSet[0].otorgamiento : "" }}</p>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12">
            <h6>Fecha de vencimiento:</h6>
            <p>{{ item.licenciaSet.length > 0 ? item.licenciaSet[0].vencimiento : "" }}</p>
          </div>
        </div>
        <div v-show="pagina===1" class="row mb-1 text-start">
          <h5>Direcci&oacute;n</h5>
          <div class="col-lg-3 col-md-6 col-sm-12">
            <h6>Pa&iacute;s:</h6>
            <p>{{ item.direccionSet.length > 0 ? item.direccionSet[0].pais : "" }}</p>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12">
            <h6>Ciudad:</h6>
            <p>{{ item.direccionSet.length > 0 ? item.direccionSet[0].ciudad : "" }}</p>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12">
            <h6>Provincia:</h6>
            <p>{{ item.direccionSet.length > 0 ? item.direccionSet[0].estado : "" }}</p>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12">
            <h6>C&oacute;digo postal:</h6>
            <p>{{ item.direccionSet.length > 0 ? item.direccionSet[0].postal : "" }}</p>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12">
            <h6>Direcci&oacute;n:</h6>
            <p>{{ item.direccionSet.length > 0 ? item.direccionSet[0].direccion : "" }}</p>
          </div>
        </div>
        <div v-show="pagina===1" class="row mb-3 text-start">
          <h5>Datos de contacto</h5>
          <div class="col-lg-3 col-md-6 col-sm-12">
            <h6>Tel&eacute;fono:</h6>
            <p>{{ item.contactoSet.length > 0 ? item.contactoSet[0].telefono : "" }}</p>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12">
            <h6>Idioma:</h6>
            <p>{{ item.contactoSet.length > 0 ? item.contactoSet[0].idioma : "" }}</p>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12">
            <h6>Email:</h6>
            <p>{{ item.user ? item.user.email : "" }}</p>
          </div>
        </div>
        <div v-show="pagina===1" class="row">
          <div class="col-md-12 col-lg-6 d-flex justify-content-start mb-3">
            <button @click="pagina=0" class="btn btn-secondary d-block w-100"
                    type="button">Regresar
            </button>
          </div>
          <div class="col-md-12 col-lg-6 d-flex justify-content-end mb-3">
            <button @click="pagina=2" class="btn btn-danger d-block w-100" type="button">Siguiente</button>
          </div>
        </div>
        <div v-show="pagina===2" class="row mb-3 text-start">
          <h5>Im&aacute;genes de documento de identidad</h5>
          <div class="col-lg-6 col-md-6 col-sm-12">
            <h6>Frente:</h6>
            <div v-if="this.item.identidadSet.length>0 && this.item.identidadSet[0].imagenFront!==''">
              <a target="_blank" v-show="this.item.identidadSet[0].imagenFront!==''"
                 :href="this.item.identidadSet.length>0?getImageUrl(this.item.identidadSet[0].imagenFront):''"
                 data-bs-toggle="tooltip" data-bs-placement="top"
                 title="Descargar imagen del frente" data-container="body" data-animation="true"
                 class="btn btn-danger btn-sm p-1 ms-1">
                Descargar</a>
            </div>
            <div v-else>
              No tiene imagen
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12">
            <h6>Dorso:</h6>
            <div v-if="this.item.identidadSet.length>0 && this.item.identidadSet[0].imagenBack!==''">
              <a target="_blank" v-show="this.item.identidadSet[0].imagenBack!==''"
                 :href="this.item.identidadSet.length>0?getImageUrl(this.item.identidadSet[0].imagenBack):''"
                 data-bs-toggle="tooltip" data-bs-placement="top"
                 title="Descargar imagen del dorso" data-container="body" data-animation="true"
                 class="btn btn-danger btn-sm p-1 ms-1">
                Descargar</a>
            </div>
            <div v-else>
              No tiene imagen
            </div>
          </div>
        </div>
        <div v-show="pagina===2" class="row mb-3 text-start">
          <h5>Im&aacute;genes de licencia de conducci&oacute;n</h5>
          <div class="col-lg-6 col-md-6 col-sm-12">
            <h6>Frente:</h6>
            <div v-if="this.item.licenciaSet.length>0 && this.item.licenciaSet[0].imagenFront!==''">
              <a target="_blank" v-show="this.item.licenciaSet[0].imagenFront!==''"
                 :href="this.item.licenciaSet.length>0?getImageUrl(this.item.licenciaSet[0].imagenFront):''"
                 data-bs-toggle="tooltip" data-bs-placement="top"
                 class="btn btn-danger btn-sm p-1 ms-1">
                Descargar</a>
            </div>
            <div v-else>
              No tiene imagen
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12">
            <h6>Dorso:</h6>
            <div v-if="this.item.licenciaSet.length>0 && this.item.licenciaSet[0].imagenBack!==''">
              <a target="_blank" v-show="this.item.licenciaSet[0].imagenBack!==''"
                 :href="this.item.licenciaSet.length>0?getImageUrl(this.item.licenciaSet[0].imagenBack):''"
                 data-bs-toggle="tooltip" data-bs-placement="top"
                 title="Descargar imagen del dorso" data-container="body" data-animation="true"
                 class="btn btn-danger btn-sm p-1 ms-1">
                Descargar</a>
            </div>
            <div v-else>
              No tiene imagen
            </div>
          </div>
        </div>
        <div v-show="pagina===2" class="row mb-3 text-start">
          <h5>Imagen del cliente</h5>
          <div class="col-lg-12 col-md-12 col-sm-12">
            <div v-if="item.photoAdmin !==''">
              <a target="_blank"
                 :href="getImageUrl(this.item.photoAdmin)"
                 data-bs-toggle="tooltip" data-bs-placement="top"
                 title="Descargar imagen del cliente" data-container="body" data-animation="true"
                 class="btn btn-danger btn-sm p-1 ms-1">
                Descargar</a>
            </div>
            <div v-else>
              No tiene imagen
            </div>
          </div>
        </div>
        <div v-show="pagina===2" class="row">
          <div class="col-md-12 col-lg-6 d-flex justify-content-start mb-3">
            <button @click="pagina=1" class="btn btn-secondary d-block w-100"
                    type="button">Regresar
            </button>
          </div>
          <div class="col-md-12 col-lg-6 d-flex justify-content-end mb-3">
            <button @click="close_Details" class="btn btn-danger d-block w-100" type="button">Cerrar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="Subir_foto text-dark">
    <div v-if="Photo_Modal" :class="['modal', { 'show': Photo_Modal }]">
      <div class="modal-content bg-white">
        <div class="row mb-3 d-flex align-items-center">
          <h4 class="text-start fw-bold">
            Subir o cambiar foto del cliente
            <i @click="close_Photo" class="flaticon-close opacity-10 modal-icon fs-15"></i>
          </h4>
        </div>
        <div class="row mb-3 text-start">
          <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">Foto de cliente</label>
            <input :class="cliente_photo===null?'is-invalid':''" @change="onPhoto"
                   class="form-control shadow-none rounded-0 text-black" type="file">
          </div>
        </div>
        <div class="row mb-3 text-start">
          <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
            <img class="img-fluid img-cliente" :src="preview" alt="Imagen del cliente">
          </div>
        </div>
        <div class="row mb-3">
          <div v-show="error_vacios" class="col-12 text-center p-2"
               style="background-color: rgba(255,19,28,0.08)">
              <span class="d-flex align-items-center justify-content-center">
                <i class="flaticon-warning me-2 fs-15"></i>
                Debe seleccionar una foto
              </span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-lg-6 d-flex justify-content-start mb-3">
            <button @click="close_Photo" class="btn btn-secondary d-block w-100" type="button">Cancelar</button>
          </div>
          <div class="col-md-12 col-lg-6 d-flex justify-content-end mb-3">
            <button @click="Subir_Photo" class="btn btn-danger d-block w-100" type="button">Guardar</button>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="Subir_foto text-dark">
    <div v-if="dialogInvoice" :class="['modal', { 'show': dialogInvoice }]">
      <div class="modal-content bg-white">
        <div class="row mb-3 d-flex align-items-center">
          <h4 class="text-start fw-bold">
            Informaci&oacute;n fiscal
            <i @click="resetInvoice" class="flaticon-close opacity-10 modal-icon fs-15"></i>
          </h4>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
            <h5><strong>Cliente: </strong>{{ fullNameClient }}</h5>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3">
            <label for="person" class="form-label ms-1">Persona</label>
            <select id="person" class="form-control form-select" v-model="objInvoice.person" required
                    :disabled="saveChanges" :class="objInvoice.person === ''?'is-invalid':'is-valid'">
              <option value="P">F&iacute;sica</option>
              <option value="M">Moral</option>
            </select>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3">
            <label for="reason" class="form-label ms-1">Raz&oacute;n social</label>
            <input id="reason" class="form-control" v-model="objInvoice.socialReason" required
                   :disabled="saveChanges"
                   :class="objInvoice.socialReason === '' || objInvoice.socialReason.length === 0?'is-invalid':'is-valid'"/>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3">
            <label for="rfc" class="form-label ms-1">RFC</label>
            <input id="rfc" class="form-control" v-model="objInvoice.rfc" required
                   :disabled="saveChanges" :maxlength="maxLengthRFC"
                   :class="objInvoice.rfc === '' || objInvoice.rfc.length !== maxLengthRFC ||
                   !(/[A-Z&Ñ]{3,4}[0-9]{2}(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])[A-Z0-9]{2}[0-9A]/.test(objInvoice.rfc))?'is-invalid':'is-valid'"/>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3">
            <label for="email" class="form-label ms-1">Email de facturaci&oacute;n</label>
            <input id="email" class="form-control" v-model="objInvoice.emailInvoice" required
                   :disabled="saveChanges"
                   :class="!(/^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(objInvoice.emailInvoice))?'is-invalid':'is-valid'"/>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3">
            <label for="colonia" class="form-label ms-1">Colonia</label>
            <input id="colonia" class="form-control" v-model="objInvoice.colonia" required
                   :disabled="saveChanges" :class="objInvoice.colonia === ''?'is-invalid':'is-valid'"/>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3">
            <label for="state" class="form-label ms-1">Estado</label>
            <input id="state" class="form-control" v-model="objInvoice.stateInvoice" required
                   :disabled="saveChanges" :class="objInvoice.stateInvoice === ''?'is-invalid':'is-valid'"/>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3">
            <label for="municipality" class="form-label ms-1">Municipio</label>
            <input id="municipality" class="form-control" v-model="objInvoice.municipality" required
                   :disabled="saveChanges"
                   :class="objInvoice.municipality === ''?'is-invalid':'is-valid'"/>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3">
            <label for="postal" class="form-label ms-1">Direcci&oacute;n fiscal</label>
            <input id="postal" class="form-control" v-model="objInvoice.fiscalAddress" required
                   :disabled="saveChanges"
                   :class="objInvoice.fiscalAddress === ''?'is-invalid':'is-valid'"/>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3">
            <label for="postal" class="form-label ms-1">C&oacute;digo postal</label>
            <input id="postal" class="form-control" v-model="objInvoice.postalCodeInvoice" required type="number"
                   :disabled="saveChanges" :class="objInvoice.postalCodeInvoice === null ||
                   objInvoice.postalCodeInvoice<=10000 || objInvoice.postalCodeInvoice>99999?'is-invalid':'is-valid'"/>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-lg-6 d-flex justify-content-start mb-3">
            <button @click="resetInvoice" :disabled="saveChanges"
                    class="btn btn-secondary d-block w-100" type="button">Cancelar
            </button>
          </div>
          <div class="col-md-12 col-lg-6 d-flex justify-content-end mb-3">
            <button @click="saveFiscalInformation" class="btn btn-danger d-block w-100" type="button"
                    :disabled="saveChanges || isValidInvoice">Guardar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import moment from "moment";
import axios from "axios";
import {
  searchClients,
  searchPrevClients,
  searchNextClients,
  searchClientById,
  getCountriesByName,
  getCitiesByName,
  getStatesByName,
  clientPoint,
  disableClient,
  enableClient,
  createClient,
  updateClient,
  fiscalInformationClient,
  getInfoInvoiceClient, clientePhoto, clienteLicencia, clienteIdentidad
} from "@/actions";
import MazPhoneNumberInput from "maz-ui/components/MazPhoneNumberInput";
import {decodeBase64} from "@/util";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import {srac_backend_url, srac_url_image} from "@/actions/constants";

const auxInvoice = {
  client: "",
  colonia: "",
  rfc: "",
  socialReason: "",
  municipality: "",
  fiscalAddress: "",
  stateInvoice: "",
  emailInvoice: "",
  person: "",
  postalCodeInvoice: ""
};

export default {
  name: "ClienteList",
  components: {
    MazPhoneNumberInput,
    VueGoogleAutocomplete
  },
  data() {
    return {
      objInvoice: Object.assign({}, auxInvoice),
      dialogInvoice: false,
      hasNextPage: false,
      hasPreviousPage: false,
      startCursor: "",
      apiKey: process.env.VUE_APP_API_KEY,
      lastStartCursor: "",
      endCursor: "",
      lastEndCursor: "",

      clientesByName: [],
      paisesByName: [],
      ciudadesByName: [],
      estadosByName: [],
      cliente_id: 0,
      apellidos: "",
      direccion: "",
      direccionCiudad: 0,
      email: "",
      rfc: "",
      fechaNacimiento: null,
      identidadExpedicion: null,
      identidadNumero: "",
      identidadPais: 0,
      identidadTipo: "",
      licenciaClase: "",
      licenciaOtorgamiento: null,
      licenciaVencimiento: null,
      licenciaNumero: "",
      nacionalidad: "",
      nombre: "",
      postal: "",
      telefono: "",
      tratamiento: "",
      idioma: "",
      error_vacios: false,
      error_nacimiento: false,
      error_expiracion: false,
      error_expedicion: false,
      Nuevo_Modal: false,
      Actualizar_Modal: false,
      direccionPais: 0,
      direccionEstado: 0,
      ventana: 0,
      cant: 10,
      search: '',
      Files_Modal: false,
      photo_carnet_front: null,
      photo_carnet_back: null,
      photo_licencia_front: null,
      photo_licencia_back: null,
      photo_tarjeta_front: null,
      photo_tarjeta_back: null,
      accion: '',
      longitudDeseada: 6,
      relleno: '0',
      numeroFormateado: '',
      pagina: 0,
      Details_Modal: false,
      item: {
        id: null,
        tratamiento: null,
        nombre: null,
        apellidos: null,
        nacionalidad: null,
        nacimiento: null,
        isActive: null,
        photo: null,
        photoAdmin: null,
        user: null,
        direccionSet: [],
        identidadSet: [],
        licenciaSet: [],
        contactoSet: [],
        tarjetaSet: []
      },
      fullNameClient: "",
      tarjeta_autorizacion: '',
      tarjeta_numero: '',
      tarjeta_vencimiento: null,
      cliente_photo: null,
      preview: null,
      Photo_Modal: false,
      cargando: false,
      Points_Modal: false,
      points: 0,
      codigo: "",
      loadingDif: false,
      error_telefono: true,
      loading: true,
      saveChanges: false,
      toast: Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        customClass: {
          popup: 'bg-white',
          title: 'text-dark'
        },
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        }
      }),
    }
  },
  created() {
    const q = this.$route.query["client"]
    if (q) {
      this.loadingDif = true
      searchClientById(q).then(resp => {
        this.getInfo({
          ...resp.data.data.searchClientById,
          loadingInfo: true,
        });
      }).catch(err => {
        console.log(err)
      }).finally(() => {
        this.loadingDif = false;
      })
    }
  },
  computed: {
    maxLengthRFC(){
      return this.objInvoice.person == 'M'? 12 : 13;
    },
    isValidInvoice() {
      return this.objInvoice.socialReason === '' || this.objInvoice.socialReason.length === 0 ||
        this.objInvoice.rfc === '' || this.objInvoice.rfc.length !== this.maxLengthRFC ||
          !(/[A-Z&Ñ]{3,4}[0-9]{2}(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])[A-Z0-9]{2}[0-9A]/.test(this.objInvoice.rfc))
          || this.objInvoice.client === '' || this.objInvoice.colonia === '' || this.objInvoice.stateInvoice === ''
          || this.objInvoice.municipality === '' || this.objInvoice.fiscalAddress === '';
    },
    validateFiles() {
      return this.photo_carnet_front === null || this.photo_carnet_back === null || this.photo_licencia_front === null
          || this.photo_licencia_back === null
    },
    validateEmail() {
      return /.+@.+\..+/.test(this.email)
    },
    minDate() {
      const d = new Date(this.fechaNacimiento ?? Date.now());
      const month = d.getMonth() < 9 ? `0${d.getMonth() + 1}` : d.getMonth() + 1;
      return `${d.getFullYear()}-${month}-${d.getDate()}`;
    },
    expDate() {
      const d = new Date(Date.now() + (1000 * 60 * 60 * 24 * 7));
      const month = d.getMonth() < 9 ? `0${d.getMonth() + 1}` : d.getMonth() + 1;
      return `${d.getFullYear()}-${month}-${d.getDate()}`;
    },
    dateVencMin() {
      const d = new Date();
      const month = d.getMonth() < 9 ? `0${d.getMonth() + 1}` : d.getMonth() + 1;
      return `${d.getFullYear()}-${month}-${d.getDate()}`;
    },
    dateBornMin() {
      const d = new Date(Date.now() - (1000 * 60 * 60 * 24 * 365 * 18));
      const month = d.getMonth() < 9 ? `0${d.getMonth() + 1}` : d.getMonth() + 1;
      return `${d.getFullYear()}-${month}-${d.getDate()}`;
    },
    validateFirst() {
      return this.tratamiento === '' || this.tratamiento == null || this.nombre === '' ||
          this.apellidos === '' || this.fechaNacimiento === '' || this.tarjeta_vencimiento === '' ||
          this.fechaNacimiento == null || this.nacionalidad === '' || this.nacionalidad == null || this.tarjeta_numero === '' ||
          this.tarjeta_numero == null  ||
          this.tarjeta_vencimiento == null || this.email === '' || this.email == null ||
          this.error_telefono || /^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(this.email) === false
    },
    validateSecond() {
      return this.direccionPais === '' || this.direccionPais == null || this.direccionEstado === ''
          || this.direccionEstado == null || this.direccionEstado === 0 || this.direccionPais === 0
          || this.direccionCiudad === '' || this.direccionCiudad === null || this.direccionCiudad === 0
          || this.postal === '' || this.postal.toString().length < 4 || this.direccion === ''
          || this.direccion == null
    },
    validateThree() {
      return this.identidadPais === '' || this.identidadPais == null || this.identidadTipo === '' || this.identidadTipo == null
          || this.identidadNumero === '' || this.identidadNumero == null
          || this.identidadExpedicion === '' || this.identidadExpedicion == null || this.licenciaNumero === '' || this.licenciaNumero == null
          || this.licenciaClase === '' || this.licenciaClase == null || this.licenciaOtorgamiento === ''
          || this.licenciaOtorgamiento == null || this.licenciaVencimiento === '' || this.licenciaVencimiento == null
    },
    dateVenc() {
      if (!this.tarjeta_vencimiento) {
        return null
      }
      let d = null;
      if (typeof (this.tarjeta_vencimiento) === 'string') {
        d = new Date(this.tarjeta_vencimiento);
      } else {
        d = new Date(this.tarjeta_vencimiento.year, this.tarjeta_vencimiento.month - 1);
      }
      const month = d.getMonth() < 9 ? `0${d.getMonth() + 1}` : d.getMonth() + 1;
      const day = d.getDate() > 9 ? d.getDate() : `0${d.getDate()}`
      return `${d.getFullYear()}-${month}-${day}`;
    },
    minDateBorn() {
      const d = new Date(Date.now() - (18 * 365 * 24 * 60 * 60 * 1000));
      const month = d.getMonth() < 9 ? `0${d.getMonth() + 1}` : d.getMonth() + 1;
      const day = d.getDate() > 9 ? d.getDate() : `0${d.getDate()}`
      return `${d.getFullYear()}-${month}-${day}`;
    },
    dateNow() {
      const d = new Date(Date.now() - (24 * 60 * 60 * 1000));
      const month = d.getMonth() < 9 ? `0${d.getMonth() + 1}` : d.getMonth() + 1;
      const day = d.getDate() > 9 ? d.getDate() : `0${d.getDate()}`
      return `${d.getFullYear()}-${month}-${day}`;
    }
  },
  watch: {
    telefono(value) {
      if (value !== '') {
        this.error_telefono = false
      }
    },
    clientesByName() {
      this.$store.state.Cargando = false
    },

    search() {
      this.loadDatas(true)
    }
  },
  methods: {
    getImageUrl(imagen) {
      return srac_url_image + imagen;
    },
    onInputCiudad(event) {
      this.direccionCiudad = event.target.value;
    },
    onInputEstado(event) {
      this.direccionEstado = event.target.value;
    },
    onInputPais(event) {
      this.direccionPais = event.target.value;
    },
    onInputPaisIdentidad(event) {
      this.identidadPais = event.target.value;
    },
    getInfo(item) {
      item.loadingInfo = true;
      getInfoInvoiceClient(item.id).then(resp => {
        const aux = resp.data.data.searchClientById;
        this.fullNameClient = `${aux.nombre} ${aux.apellidos}`;
        this.objInvoice = {
          rfc: aux.rfc !== null ? aux.rfc : '',
          colonia: aux.colonia !== null ? aux.colonia : '',
          client: item.id,
          fiscalAddress: aux.fiscalAddress !== null ? aux.fiscalAddress : '',
          municipality: aux.municipality !== null ? aux.municipality : '',
          socialReason: aux.socialReason !== null ? aux.socialReason : '',
          stateInvoice: aux.stateInvoice !== null ? aux.stateInvoice : '',
          emailInvoice: aux.emailInvoice !== null ? aux.emailInvoice : '',
          person: aux.person !== null ? aux.person : '',
          postalCodeInvoice: aux.postalCodeInvoice !== null ? aux.postalCodeInvoice : ''
        }
        this.dialogInvoice = true;
      }).catch(err => console.log(err)).finally(() => {
        item.loadingInfo = false;
      });
    },
    resetInvoice() {
      this.objInvoice = Object.assign({}, auxInvoice);
      this.dialogInvoice = false;
      const q = this.$route.query["client"];
      if (q) {
        this.$router.push({
          name: this.$route.query["view"],
        });
      }
    },
    saveFiscalInformation() {
      this.saveChanges = true;
      this.objInvoice.postalCodeInvoice = this.objInvoice.postalCodeInvoice.toString();
      fiscalInformationClient(this.objInvoice).then(resp => {
        this.loadDatas();
        this.resetInvoice();
        const q = this.$route.query["client"];
        if (q) {
          this.$router.push({
            name: this.$route.query["view"],
            query: {
              "contract": this.$route.query["contract"],
            }
          });
        }
      }).catch(err => console.log(err)).finally(() => {
        this.saveChanges = false;
      })
    },
    validateNotNumbers(str) {
      return /[^0-9]/.test(str);
    },
    decodeIDTable(id) {
      return decodeBase64(id)
    },
    loadDatas(init = false) {
      this.loading = true;
      let next = '';
      let before = '';
      if (init === false) {
        before = this.lastEndCursor;
        next = this.lastStartCursor;
      }
      searchClients({
        before: before,
        after: next,
        cant: this.cant,
        name: this.search
      }).then((resp) => {
        if (init === true) {
          this.hasNextPage = resp.data.data.searchClients.pageInfo.hasNextPage;
          this.hasPreviousPage = resp.data.data.searchClients.pageInfo.hasPreviousPage;
        }
        this.startCursor = resp.data.data.searchClients.pageInfo.startCursor;
        this.endCursor = resp.data.data.searchClients.pageInfo.endCursor;
        this.clientesByName = resp.data.data.searchClients.edges.map(value => {
          return {
            ...value.node,
            loadingInfo: false,
          };
        });
      }).catch((err) => {
        this.toast.fire({
          icon: "error",
          title: err.message
        });
      }).finally(() => {
        this.loading = false;
      });
    },
    nextPage() {
      this.loading = true;

      searchNextClients({
        after: this.endCursor,
        cant: this.cant,
        name: this.search
      }).then((resp) => {
        this.lastEndCursor = "";
        this.lastStartCursor = this.endCursor;
        this.hasNextPage = resp.data.data.searchClients.pageInfo.hasNextPage;
        this.hasPreviousPage = true;
        this.startCursor = resp.data.data.searchClients.pageInfo.startCursor;
        this.endCursor = resp.data.data.searchClients.pageInfo.endCursor;
        this.clientesByName = resp.data.data.searchClients.edges.map(value => {
          return {
            ...value.node,
            loadingInfo: false,
          };
        });
      }).catch((err) => {
        this.toast.fire({
          icon: "error",
          title: err.message
        });
      }).finally(() => {
        this.loading = false;
      });
    },
    prevPage() {
      this.loading = true;
      searchPrevClients({
        before: this.startCursor,
        cant: this.cant,
        name: this.search
      }).then((resp) => {
        this.lastEndCursor = this.startCursor;
        this.lastStartCursor = "";
        this.hasNextPage = true;
        this.hasPreviousPage = resp.data.data.searchClients.pageInfo.hasPreviousPage;
        this.startCursor = resp.data.data.searchClients.pageInfo.startCursor;
        this.endCursor = resp.data.data.searchClients.pageInfo.endCursor;
        this.clientesByName = resp.data.data.searchClients.edges.map(value => {
          return {
            ...value.node,
            loadingInfo: false,
          };
        });
      }).catch((err) => {
        this.toast.fire({
          icon: "error",
          title: err.message
        });
      }).finally(() => {
        this.loading = false;
      });
    },
    Siguiente_Dos() {
      var validacion = true
      if (this.nombre === '') {
        validacion = false
      }
      if (this.apellidos === '') {
        validacion = false
      }
      if (this.fechaNacimiento === null) {
        validacion = false
      }
      if (this.Difechas(this.fechaNacimiento, Date.now()) < 24) {
        this.error_nacimiento = true
        validacion = false
      }
      if (this.nacionalidad === '') {
        validacion = false
      }
      if (this.email === '') {
        validacion = false
      }
      if (/^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(this.email) === false) {
        validacion = false
        document.getElementById('email_cliente').classList.add('is-invalid')
      }
      if (this.telefono === '') {
        validacion = false
        this.error_telefono = true
      }
      if (validacion) {
        this.ventana = 1
      } else {
        return false
      }
    },
    Siguiente_Tres() {
      var validacion = true
      if (this.direccion === '') {
        validacion = false
      }
      if (this.direccionEstado === "") {
        validacion = false
      }
      if (this.direccionPais === "") {
        validacion = false
      }
      if (this.direccionCiudad === "") {
        validacion = false
      }
      if (this.postal === '') {
        validacion = false
      }
      if (validacion) {
        this.ventana = 2
      } else {
        return false
      }

    },
    Regresar() {
      this.ventana = this.ventana - 1
    },
    Nuevo() {
      this.loadDataExtra();
      this.accion = 'nuevo'
      this.Nuevo_Modal = true
    },
    Open_files(item) {
      this.cliente_id = decodeBase64(item.id)
      searchClientById(item.id).then(resp => {
        const aux = resp.data.data.searchClientById;
        if (aux.identidadSet.edges.length === 0 || aux.licenciaSet.edges.length === 0) {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            customClass: {
              popup: 'bg-white',
              title: 'text-dark'
            },
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "error",
            title: "Debe completar los datos del cliente antes de subir los documentos"
          });
          return false
        } else {
          if (item.photoAdmin) {
            this.preview = this.getImageUrl(item.photoAdmin);
          }
          this.Files_Modal = true
        }
      }).catch(err => console.log(err)).finally(() => {
        this.loading = false;
      })
    },
    close_Files() {
      this.$store.state.Cargando = false
      this.photo_licencia = null
      this.photo_carnet = null
      this.cliente_id = 0
      this.saveChanges = false
      this.Files_Modal = false
      this.$store.state.Cargando = false
      this.cliente_photo = null
      this.preview = null
    },
    Details(id) {
      this.loading = true
      searchClientById(id).then(resp => {
        const aux = resp.data.data.searchClientById;
        this.item = {
          id: aux.id,
          tratamiento: aux.tratamiento,
          nombre: aux.nombre,
          apellidos: aux.apellidos,
          nacionalidad: aux.nacionalidad,
          nacimiento: aux.nacimiento,
          isActive: aux.isActive,
          photo: aux.photo,
          photoAdmin: aux.photoAdmin,
          user: aux.user,
          rfc: aux.rfc,
          direccionSet: aux.direccionSet.edges.map(e => {
            e.node.id = decodeBase64(e.node.id);
            return e.node
          }),
          identidadSet: aux.identidadSet.edges.map(e => {
            e.node.id = decodeBase64(e.node.id);
            return e.node
          }),
          licenciaSet: aux.licenciaSet.edges.map(e => {
            e.node.id = decodeBase64(e.node.id);
            return e.node;
          }),
          contactoSet: aux.contactoSet.edges.map(e => {
            e.node.id = decodeBase64(e.node.id);
            return e.node;
          }),
          tarjetaSet: aux.tarjetaSet.edges.map(e => {
            e.node.id = decodeBase64(e.node.id);
            return e.node;
          }),
        };
        this.Details_Modal = true;
      }).catch(err => console.log(err)).finally(() => {
        this.loading = false;
      })
    },

    close_Details() {
      this.Details_Modal = false;
      this.pagina = 0;
      this.item = {
        id: null,
        tratamiento: null,
        nombre: null,
        apellidos: null,
        nacionalidad: null,
        nacimiento: null,
        isActive: null,
        photo: null,
        user: null,
        rfc: null,
        direccionSet: [],
        identidadSet: [],
        licenciaSet: [],
        contactoSet: [],
        tarjetaSet: []
      };
    },

    loadDataExtra() {
      getCountriesByName().then(resp => {
        this.paisesByName = resp.data.data.paisesByName;
      }).catch(err => console.log(err));

      getStatesByName().then(resp => {
        this.estadosByName = resp.data.data.estadosByName.map(e => {
          e.pais.id = decodeBase64(e.pais.id);
          return e;
        });
      }).catch(err => console.log(err));

      getCitiesByName().then(resp => {
        this.ciudadesByName = resp.data.data.ciudadesByName.map(e => {
          e.estado.id = decodeBase64(e.estado.id);
          return e;
        });
      }).catch(err => console.log(err));
    },

    Actualizar(id) {
      this.loadDataExtra();
      this.loading = true
      searchClientById(id).then(resp => {
        const aux = resp.data.data.searchClientById;
        const item = {
          id: decodeBase64(aux.id),
          tratamiento: aux.tratamiento,
          nombre: aux.nombre,
          apellidos: aux.apellidos,
          nacionalidad: aux.nacionalidad,
          nacimiento: aux.nacimiento,
          isActive: aux.isActive,
          photo: aux.photo,
          user: aux.user,
          rfc: aux.rfc,
          direccionSet: aux.direccionSet.edges.map(e => {
            e.node.id = decodeBase64(e.node.id);
            return e.node
          }),
          identidadSet: aux.identidadSet.edges.map(e => {
            e.node.id = decodeBase64(e.node.id);
            return e.node
          }),
          licenciaSet: aux.licenciaSet.edges.map(e => {
            e.node.id = decodeBase64(e.node.id);
            return e.node;
          }),
          contactoSet: aux.contactoSet.edges.map(e => {
            e.node.id = decodeBase64(e.node.id);
            return e.node;
          }),
          tarjetaSet: aux.tarjetaSet.edges.map(e => {
            e.node.id = decodeBase64(e.node.id);
            return e.node;
          }),
        };
        this.cliente_id = item.id;
        this.apellidos = item.apellidos;
        this.direccion = item.direccionSet.length > 0 ? item.direccionSet[0].direccion : "";
        this.direccionCiudad = item.direccionSet.length > 0 ? item.direccionSet[0].ciudad : "";
        this.direccionEstado = item.direccionSet.length > 0 ? item.direccionSet[0].estado : "";
        this.direccionPais = item.direccionSet.length > 0 ? item.direccionSet[0].pais : "";
        this.email = item.user ? item.user.email : "";
        this.fechaNacimiento = item.nacimiento;
        this.rfc = item.rfc;
        this.identidadExpedicion = item.identidadSet.length > 0 ? item.identidadSet[0].expedicion : "";
        this.identidadNumero = item.identidadSet.length > 0 ? item.identidadSet[0].numero : "";
        this.identidadPais = item.identidadSet.length > 0 ? item.identidadSet[0].pais : "";
        this.identidadTipo = item.identidadSet.length > 0 ? item.identidadSet[0].tipo : "";
        this.licenciaClase = item.licenciaSet.length > 0 ? item.licenciaSet[0].clase : "";
        this.licenciaOtorgamiento = item.licenciaSet.length > 0 ? item.licenciaSet[0].otorgamiento : null;
        this.licenciaVencimiento = item.licenciaSet.length > 0 ? item.licenciaSet[0].vencimiento : null;
        this.licenciaNumero = item.licenciaSet.length > 0 ? item.licenciaSet[0].numero : "";
        this.nacionalidad = item.nacionalidad;
        this.nombre = item.nombre;
        this.postal = item.direccionSet.length > 0 ? item.direccionSet[0].postal ? item.direccionSet[0].postal : "" : "";
        this.telefono = item.contactoSet.length > 0 ? item.contactoSet[0].telefono ? item.contactoSet[0].telefono : "" : "";
        this.codigo = item.contactoSet.length > 0 ? item.contactoSet[0].codigo ? item.contactoSet[0].codigo : "" : "";
        this.tratamiento = item.tratamiento;
        this.idioma = item.contactoSet.length > 0 ? item.contactoSet[0].idioma : "";
        this.tarjeta_numero = item.tarjetaSet.length > 0 ? item.tarjetaSet[0].numero : "";
        this.tarjeta_autorizacion = item.tarjetaSet.length > 0 ? item.tarjetaSet[0].autorizacion : "";
        this.tarjeta_vencimiento = item.tarjetaSet.length > 0 ? item.tarjetaSet[0].vencimiento : "";
        this.accion = 'update';
        this.Nuevo_Modal = true;
      }).catch(err => console.log(err)).finally(() => {
        this.loading = false;
      });
    },
    Close_nuevo() {
      if (this.saveChanges === false) {
        this.$store.state.Cargando = false
        this.cliente_id = 0;
        this.apellidos = "";
        this.direccion = "";
        this.direccionCiudad = 0;
        this.email = "";
        this.fechaNacimiento = null;
        this.identidadExpedicion = null;
        this.identidadNumero = "";
        this.identidadPais = 0;
        this.identidadTipo = "";
        this.licenciaClase = "";
        this.licenciaOtorgamiento = null;
        this.licenciaVencimiento = null;
        this.licenciaNumero = "";
        this.nacionalidad = "";
        this.nombre = "";
        this.postal = "";
        this.rfc = "";
        this.telefono = "";
        this.tratamiento = "";
        this.idioma = "";
        this.ventana = 0;
        this.tarjeta_autorizacion = ''
        this.tarjeta_numero = ''
        this.tarjeta_vencimiento = null
        this.Nuevo_Modal = false
      }
    },
    Close_update() {
      if (this.saveChanges === false) {
        this.$store.state.Cargando = false
        this.ventana = 0
        this.cliente_id = 0;
        this.apellidos = "";
        this.direccion = "";
        this.direccionCiudad = 0;
        this.email = "";
        this.fechaNacimiento = null;
        this.identidadExpedicion = null;
        this.identidadNumero = "";
        this.identidadPais = 0;
        this.identidadTipo = "";
        this.licenciaClase = "";
        this.rfc = "";
        this.licenciaOtorgamiento = null;
        this.licenciaVencimiento = null;
        this.licenciaNumero = "";
        this.nacionalidad = "";
        this.nombre = "";
        this.postal = "";
        this.telefono = "";
        this.tratamiento = "";
        this.idioma = "";
        this.tarjeta_autorizacion = ''
        this.tarjeta_numero = ''
        this.tarjeta_vencimiento = null
        this.Nuevo_Modal = false
      }
    },
    Crear() {
      this.saveChanges = true;
      createClient({
        apellidos: this.apellidos,
        direccion: this.direccion,
        direccionCiudad: this.direccionCiudad,
        direccionEstado: this.direccionEstado,
        direccionPais: this.direccionPais,
        email: this.email,
        fechaNacimiento: this.fechaNacimiento,
        identidadExpedicion: this.identidadExpedicion,
        identidadNumero: this.identidadNumero,
        identidadPais: this.identidadPais,
        identidadTipo: this.identidadTipo,
        licenciaClase: this.licenciaClase,
        licenciaOtorgamiento: this.licenciaOtorgamiento,
        licenciaVencimiento: this.licenciaVencimiento,
        licenciaNumero: this.licenciaNumero,
        nacionalidad: this.nacionalidad,
        nombre: this.nombre,
        postal: this.postal.toString(),
        telefono: this.telefono,
        tratamiento: this.tratamiento,
        idioma: this.idioma,
        tarjeta_autorizacion: this.tarjeta_autorizacion,
        tarjeta_numero: this.tarjeta_numero.toString(),
        tarjeta_vencimiento: this.dateVenc,
        codigo: this.codigo
      }).then(response => {
        if (response.data.data.createCliente.success) {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            customClass: {
              popup: 'bg-white',
              title: 'text-dark'
            },
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "success",
            title: "Operación realizada con éxito"
          });
          this.saveChanges = false;
          this.Close_nuevo();
          this.loadDatas();
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            customClass: {
              popup: 'bg-white',
              title: 'text-dark'
            },
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "error",
            title: response.data.data.createCliente.error
          });
        }
      }).catch(error => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          customClass: {
            popup: 'bg-white',
            title: 'text-dark'
          },
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "error",
          title: error
        });
      }).finally(() => {
        this.saveChanges = false;
      });
    },
    Difechas(fecha1, fecha2) {
      return moment(fecha2).diff(moment(fecha1), "hours");
    },
    Editar() {
      this.saveChanges = true;
      updateClient({
        apellidos: this.apellidos,
        direccion: this.direccion,
        direccionCiudad: this.direccionCiudad,
        direccionEstado: this.direccionEstado,
        direccionPais: this.direccionPais,
        email: this.email,
        fechaNacimiento: this.fechaNacimiento,
        identidadExpedicion: this.identidadExpedicion,
        identidadNumero: this.identidadNumero,
        identidadPais: this.identidadPais,
        identidadTipo: this.identidadTipo,
        licenciaClase: this.licenciaClase,
        licenciaOtorgamiento: this.licenciaOtorgamiento,
        licenciaVencimiento: this.licenciaVencimiento,
        licenciaNumero: this.licenciaNumero,
        nacionalidad: this.nacionalidad,
        nombre: this.nombre,
        postal: this.postal.toString(),
        telefono: this.telefono,
        tratamiento: this.tratamiento,
        idioma: this.idioma ? this.idioma : "",
        tarjeta_numero: this.tarjeta_numero.toString(),
        tarjeta_vencimiento: this.dateVenc,
        cliente_id: this.cliente_id,
        codigo: this.codigo
      }).then(response => {
        if (response.data.data.updateCliente.success) {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            customClass: {
              popup: 'bg-white',
              title: 'text-dark'
            },
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "success",
            title: "Operación realizada con éxito"
          });
          this.saveChanges = false;
          this.Close_update();
          this.loadDatas();
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            customClass: {
              popup: 'bg-white',
              title: 'text-dark'
            },
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "error",
            title: response.data.data.updateCliente.error
          });
        }
      }).catch(error => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          customClass: {
            popup: 'bg-white',
            title: 'text-dark'
          },
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "error",
          title: error
        });
      }).finally(() => {
        this.saveChanges = false;
      });
    },
    Activar(id) {
      enableClient({
        id: decodeBase64(id)
      }).then(response => {
        if (response.data.data.activeCliente.success) {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            customClass: {
              popup: 'bg-white',
              title: 'text-dark'
            },
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "success",
            title: "Operación realizada con éxito"
          });
          this.loadDatas();
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            customClass: {
              popup: 'bg-white',
              title: 'text-dark'
            },
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "error",
            title: "Error al activar el vehículo"
          });
        }
      }).catch(error => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          customClass: {
            popup: 'bg-white',
            title: 'text-dark'
          },
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "error",
          title: error
        });
      })
    },
    Desactivar(id) {
      disableClient({
        id: decodeBase64(id)
      }).then(response => {
        if (response.data.data.desactiveCliente.success) {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            customClass: {
              popup: 'bg-white',
              title: 'text-dark'
            },
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "success",
            title: "Operación realizada con éxito"
          });
          this.loadDatas();
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            customClass: {
              popup: 'bg-white',
              title: 'text-dark'
            },
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "error",
            title: "Error al desactivar el vehículo"
          });
        }
      }).catch(error => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          customClass: {
            popup: 'bg-white',
            title: 'text-dark'
          },
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "error",
          title: error
        });
      })
    },
    onCarnet_front(event) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        this.photo_carnet_front = reader.result
      }
      reader.readAsDataURL(file);
    },
    onCarnet_back(event) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        this.photo_carnet_back = reader.result
      }
      reader.readAsDataURL(file);
    },
    onLicencia_front(event) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        this.photo_licencia_front = reader.result
      }
      reader.readAsDataURL(file);
    },
    onLicencia_back(event) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        this.photo_licencia_back = reader.result
      }
      reader.readAsDataURL(file);
    },
    formatearNumero(numero) {
      return "SRAC" + String(numero).padStart(this.longitudDeseada, this.relleno);
    },
    onPhoto(event) {
      const file = event.target.files[0];
      this.preview = URL.createObjectURL(file);
      const reader = new FileReader();
      reader.onload = () => {
        this.cliente_photo = reader.result
      }
      reader.readAsDataURL(file);
    },
    close_Photo() {
      this.$store.state.Cargando = false
      this.cliente_photo = null
      this.cliente_id = 0
      this.preview = null
      this.Photo_Modal = false
    },
    Subir_Photo() {
      this.$store.state.Cargando = true
      if (this.photo_carnet_front === null || this.photo_carnet_back === null || this.photo_licencia_front === null ||
          this.photo_licencia_back === null) {
        this.error_vacios = true
        return false;
      }
      this.saveChanges = true;
      const id = decodeBase64(this.cliente_id);
      clienteIdentidad({
        id: id,
        carnetBack: this.photo_carnet_front,
        carnetFront: this.photo_carnet_back
      }).then(resp => {
        if (resp.data.data.clienteIdentidad.success) {
          clienteLicencia({
            id: id,
            licenciaBack: this.photo_licencia_front,
            licenciaFront: this.photo_licencia_back
          }).then(respo => {
            if (respo.data.data.clienteLicencia.success) {
              if (this.cliente_photo !== null) {
                clientePhoto({
                  id: id,
                  photo: this.cliente_photo
                }).then(response => {
                  if (response.data.data.clientePhoto.success) {
                    this.loadDatas();
                    const Toast = Swal.mixin({
                      toast: true,
                      position: "top-end",
                      showConfirmButton: false,
                      timer: 3000,
                      customClass: {
                        popup: 'bg-white',
                        title: 'text-dark'
                      },
                      timerProgressBar: true,
                      didOpen: (toast) => {
                        toast.addEventListener("mouseenter", Swal.stopTimer);
                        toast.addEventListener("mouseleave", Swal.resumeTimer);
                      }
                    });
                    Toast.fire({
                      icon: "success",
                      title: "Operación realizada con éxito"
                    });
                    this.saveChanges = false
                    this.close_Files()
                  } else {
                    const Toast = Swal.mixin({
                      toast: true,
                      position: "top-end",
                      showConfirmButton: false,
                      timer: 3000,
                      customClass: {
                        popup: 'bg-white',
                        title: 'text-dark'
                      },
                      timerProgressBar: true,
                      didOpen: (toast) => {
                        toast.addEventListener("mouseenter", Swal.stopTimer);
                        toast.addEventListener("mouseleave", Swal.resumeTimer);
                      }
                    });
                    Toast.fire({
                      icon: "error",
                      title: "Error al subir los archivos"
                    });
                    this.saveChanges = false
                  }
                })
              } else {
                this.loadDatas();
                const Toast = Swal.mixin({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  customClass: {
                    popup: 'bg-white',
                    title: 'text-dark'
                  },
                  timerProgressBar: true,
                  didOpen: (toast) => {
                    toast.addEventListener("mouseenter", Swal.stopTimer);
                    toast.addEventListener("mouseleave", Swal.resumeTimer);
                  }
                });
                Toast.fire({
                  icon: "success",
                  title: "Operación realizada con éxito"
                });
                this.saveChanges = false
                this.close_Files()
              }
            } else {
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                customClass: {
                  popup: 'bg-white',
                  title: 'text-dark'
                },
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                }
              });
              Toast.fire({
                icon: "error",
                title: "Error al subir los archivos"
              });
              this.saveChanges = false
            }
          })
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            customClass: {
              popup: 'bg-white',
              title: 'text-dark'
            },
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "error",
            title: "Error al subir los archivos"
          });
          this.saveChanges = false
        }
      })
    },
    Open_Points(item) {
      this.cliente_id = decodeBase64(item.id);
      this.points = item.puntos;
      this.Points_Modal = true;
    },
    Close_points() {
      this.cliente_id = 0;
      this.points = 0;
      this.Points_Modal = false;
    },
    Guardar_points() {
      this.saveChanges = true;
      clientPoint({
        puntos: this.points,
        cliente: decodeBase64(this.cliente_id)
      }).then(response => {
        if (response.data.data.updatePuntosClientes.success) {
          this.Close_points()
          this.loadDatas();
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            customClass: {
              popup: 'bg-white',
              title: 'text-dark'
            },
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "success",
            title: "Operación realizada con éxito"
          });
        } else {
          this.Close_points()
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            customClass: {
              popup: 'bg-white',
              title: 'text-dark'
            },
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "error",
            title: "Error al actualizar los puntos del cliente"
          });
        }
      }).catch(error => {
        this.Close_points()
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          customClass: {
            popup: 'bg-white',
            title: 'text-dark'
          },
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "error",
          title: "Error al actualizar los puntos del cliente"
        });
      }).finally(() => {
        this.saveChanges = false;
      })
    },

    getAddressCountry: function (addressData, placeResultData, id) {
      this.direccionPais = addressData.country;
    },
    getAddressState: function (addressData, placeResultData, id) {
      this.direccionEstado = addressData.administrative_area_level_1;
    },
    getAddressCity: function (addressData, placeResultData, id) {
      this.direccionCiudad = addressData.locality;
    },
    getIdentityCountry: function (addressData, placeResultData, id) {
      this.identidadPais = addressData.country;
    },
  },
  mounted() {
    this.loadDatas(true);
  },
};
</script>
<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.modal-content {
  padding: 20px;
  width: 60%;
}

@media (max-width: 767px) {
  .modal-content {
    border-radius: 15px;
    padding: 10px;
    width: 90%;
  }
}

.modal {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.modal.show {
  opacity: 1;
}

.img-categoria {
  height: 50px;
  width: 50px;
  object-fit: cover;
}

.img-cliente {
  height: 150px;
  width: 150px;
  object-fit: cover;
}

.icon-loading-custom {
  width: 22px !important;
  height: 22px !important;
  margin-top: auto !important;
  margin-bottom: auto !important;
}
</style>
