<template>
  <div
    class="mb-20 d-sm-flex align-items-center justify-content-between letter-spacing bg-white"
  >
    <h4 class="mb-5 ms-3 mb-sm-0 fw-bold text-dark">{{ PageTitle }}</h4>
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb mb-0 list-unstyled ps-0">
        <li class="breadcrumb-item">
          <router-link to="/auth/dashboard" class="text-decoration-none text-dark">
            {{Subtitle}}
          </router-link>
        </li>
        <li class="breadcrumb-item active text-dark" aria-current="page">
          {{ PageTitle }}
        </li>
      </ol>
    </nav>
  </div>
</template>

<script>

export default {
  name: "BreadCrumb",
  props: ["PageTitle", "Subtitle"],
};
</script>
