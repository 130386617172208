<template>
  <div class="Cargando">
    <div v-show="loading" :class="['modal_cargando', { 'show': loading }]">
      <div class="spinner"></div>
    </div>
  </div>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div
        class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25"
    >
      <div class="d-sm-flex align-items-center">
        <form class="search-box position-relative">
          <input
              type="text" v-model="search"
              class="form-control shadow-none text-black rounded-0 border-0"
              placeholder="Buscar cliente"
          />
          <button class="bg-transparent text-primary transition p-0 border-0">
            <i class="flaticon-search-interface-symbol"></i>
          </button>
        </form>
      </div>
      <div class="d-sm-flex align-items-center mt-10 mt-lg-0">
        <button
            @click="Nuevo"
            class="bg-primary position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block d-inline-block text-decoration-none"
        >
          Asignar puntos a clientes
          <i class="flaticon-plus position-relative ms-5 fs-12"></i>
        </button>
      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
          <tr>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Cliente
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Puntos
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
            >
              Acciones
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in puntosClientes" :key="item.id">
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.cliente.nombre }} {{ item.cliente.apellidos }}
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.puntos }}
            </td>
            <td class="shadow-none lh-1 fw-bold text-body-tertiary">
              <div class="btn-group" role="group">
                <button v-if="item.isActive" data-bs-toggle="tooltip"
                        style="padding: 0.8rem"
                        data-bs-placement="top" title="Desactivar estado" data-container="body"
                        data-animation="true" class="btn text-success" @click="Desactivar(item.id)">
                  <i class="flaticon-ban d-flex justify-content-center align-items-center"></i></button>
                <button v-else data-bs-toggle="tooltip" data-bs-placement="top"
                        style="padding: 0.8rem"
                        title="Activar estado" data-container="body" data-animation="true"
                        class="btn text-warning" @click="Activar(item.id)">
                  <i class="flaticon-check-mark-1 d-flex justify-content-center align-items-center"></i></button>
                <button data-bs-toggle="tooltip" @click="Actualizar(item)"
                        style="padding: 0.8rem"
                        data-bs-placement="top"
                        title="Editar estado" data-container="body" data-animation="true"
                        class="btn text-info">
                  <i class="flaticon-pen d-flex justify-content-center align-items-center "></i></button>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="Nuevo text-dark">
    <div v-if="Nuevo_Modal" :class="['modal', { 'show': Nuevo_Modal }]">
      <div class="modal-content bg-white">
        <div class="row mb-3 d-flex align-items-center">
          <h4 class="text-start fw-bold">
            Asignar puntos a cliente
            <i @click="Close_nuevo" class="flaticon-close opacity-10 modal-icon fs-15"></i>
          </h4>
        </div>
        <div class="row mb-3 text-start">
          <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
            <MazSelect
                v-model="cliente"
                label="Seleccione el cliente"
                :options="options"
                search
                searchPlaceholder="Buscar cliente"
                no-radius
                :error="error_cliente"
            />
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
            <MazInput
                v-model="puntos"
                label="Puntos"
                no-radius
                :error="error_puntos"
            />
          </div>
        </div>
        <div class="row mb-3">
          <div v-show="error_vacios" class="col-12 text-center p-2"
               style="background-color: rgba(255,19,28,0.08)">
              <span class="d-flex align-items-center justify-content-center">
                <i class="flaticon-warning me-2 fs-15"></i>
                No pueden haber campos vacíos
              </span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-lg-6 d-flex justify-content-start mb-3">
            <button @click="Close_nuevo" class="btn btn-secondary d-block w-100" type="button">Cancelar</button>
          </div>
          <div class="col-md-12 col-lg-6 d-flex justify-content-end mb-3">
            <button @click="Crear" class="btn btn-danger d-block w-100" type="button">Guardar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="Actualizar text-dark">
    <div v-if="Actualizar_Modal" :class="['modal', { 'show': Actualizar_Modal }]">
      <div class="modal-content bg-white">
        <div class="row mb-3 d-flex align-items-center">
          <h4 class="text-start fw-bold">
            Modificar puntos de clientes
            <i @click="Close_update" class="flaticon-close opacity-10 modal-icon fs-15"></i>
          </h4>
        </div>
        <div class="row mb-3 text-start">
          <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
            <MazSelect
                v-model="cliente"
                label="Seleccione el cliente"
                :options="options"
                search
                searchPlaceholder="Buscar cliente"
                no-radius
                :error="error_cliente"
            />
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
            <MazInput
                v-model="puntos"
                label="Puntos"
                no-radius
                :error="error_puntos"
            />
          </div>
        </div>
        <div class="row mb-3">
          <div v-show="error_vacios" class="col-12 text-center p-2"
               style="background-color: rgba(255,19,28,0.08)">
              <span class="d-flex align-items-center justify-content-center">
                <i class="flaticon-warning me-2 fs-15"></i>
                No pueden haber campos vacíos
              </span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-lg-6 d-flex justify-content-start mb-3">
            <button @click="Close_update" class="btn btn-secondary d-block w-100" type="button">Cancelar</button>
          </div>
          <div class="col-md-12 col-lg-6 d-flex justify-content-end mb-3">
            <button @click="Editar" class="btn btn-danger d-block w-100" type="button">Actualizar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";
import Swal from "sweetalert2";
import MazSelect from "maz-ui/components/MazSelect";
import MazInput from "maz-ui/components/MazInput";

const CLIENTES = gql`
query ClientesByName {
    clientesByName(name: "") {
        id
        nombre
        apellidos
        isActive
    }
}
`;
const BUSCAR = gql`
  query PuntosClientes($name:String!) {
    puntosClientes(name: $name) {
        id
        puntos
        isActive
        cliente {
            id
            tratamiento
            nombre
            apellidos
            nacionalidad
            nacimiento
            isActive
            photo
        }
    }
}`;
const NUEVO = gql`
mutation CreatePuntosClientes($cliente:ID!, $puntos:Int!) {
    createPuntosClientes(obj: { cliente: $cliente, puntos: $puntos }) {
        success
        error
    }
}`;
const ACTUALIZAR = gql`
mutation UpdatePuntosClientes($id:ID!, $cliente:ID!, $puntos:Int!) {
    updatePuntosClientes(obj: { id: $id, cliente: $cliente, puntos: $puntos }) {
        success
        error
    }
}`;
const ACTIVAR = gql`
mutation ActivePuntosClientes($id:ID!) {
    activePuntosClientes(obj: { id: $id }) {
        success
        error
    }
}`;
const DESACTIVAR = gql`
mutation DesactivePuntosClientes($id:ID!) {
    desactivePuntosClientes(obj: { id: $id }) {
        success
        error
    }
}`;
export default {
  name: "EstadoList",
  components: {MazSelect, MazInput},
  data() {
    return {
      puntosClientes: [],
      clientesByName: [],
      search: "",
      Nuevo_Modal: false,
      Actualizar_Modal: false,
      cliente: 0,
      puntos: 0,
      id: 0,
      phone: "",
      error_cliente: true,
      error_vacios: false,
      error_puntos: false,
      options: []
    }
  },
  watch: {
    clientesByName(value) {
      if (value !== []) {
        value.forEach(item => {
          this.options.push({"value": item.id, "label": item.nombre + " " + item.apellidos})
        })
      }
    },
    cliente(value) {
      if (value !== 0) {
        this.error_cliente = false
      }
    },
    puntos(value) {
      if (value >= 0) {
        this.error_puntos = false
      }
    }
  },
  apollo: {
    puntosClientes: {
      query: BUSCAR,
      variables() {
        return {
          name: this.search
        };
      },
      fetchPolicy: 'cache-and-network'
    },
    clientesByName: {
      query: CLIENTES,
      fetchPolicy: 'cache-and-network'
    },
  },
  methods: {
    Nuevo() {
      this.Nuevo_Modal = true
    },
    Actualizar(item) {
      this.cliente = item.cliente.id;
      this.puntos = item.puntos;
      this.id = item.id;
      this.Actualizar_Modal = true
    },
    Close_nuevo() {
      this.name = ''
      this.abreviatura = ''
      this.Nuevo_Modal = false
    },
    Close_update() {
      this.name = ''
      this.abreviatura = ''
      this.Actualizar_Modal = false
    },
    Crear() {
      if (this.cliente === 0) {
        this.error_cliente = true
        this.error_vacios = true
        return false
      }
      if (this.puntos < 0) {
        this.error_puntos = true
        this.error_vacios = true
        return false
      }
      this.error_vacios = false
      this.$apollo.mutate({
        mutation: NUEVO,
        variables: {
          cliente: this.cliente,
          puntos: this.puntos
        },
        refetchQueries: [{query: BUSCAR, variables: {name: this.search}}]
      }).then(response => {
        if (response.data.createPuntosClientes.success) {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            customClass: {
              popup: 'bg-white',
              title: 'text-dark'
            },
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "success",
            title: "Operación realizada con éxito"
          });
          this.Close_nuevo()
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            customClass: {
              popup: 'bg-white',
              title: 'text-dark'
            },
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "error",
            title: response.data.createPuntosClientes.error
          });
          this.Close_nuevo()
        }
      }).catch(error => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          customClass: {
            popup: 'bg-white',
            title: 'text-dark'
          },
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "error",
          title: error
        });
        this.Close_nuevo()
      })
    },
    Editar() {
      if (this.cliente === 0) {
        this.error_vacios = true
        this.error_cliente = true
        return false
      }
      if (this.puntos < 0) {
        this.error_puntos = true
        this.error_vacios = true
        return false
      }
      this.error_vacios = false
      this.$apollo.mutate({
        mutation: ACTUALIZAR,
        variables: {
          id: this.id,
          cliente: this.cliente,
          puntos: this.puntos
        },
        refetchQueries: [{query: BUSCAR, variables: {name: this.search}}]
      }).then(response => {
        if (response.data.updatePuntosClientes.success) {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            customClass: {
              popup: 'bg-white',
              title: 'text-dark'
            },
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "success",
            title: "Operación realizada con éxito"
          });
          this.Close_update()
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            customClass: {
              popup: 'bg-white',
              title: 'text-dark'
            },
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "error",
            title: response.data.updatePuntosClientes.error
          });
          this.Close_update()
        }
      }).catch(error => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          customClass: {
            popup: 'bg-white',
            title: 'text-dark'
          },
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "error",
          title: error
        });
        this.Close_update()
      })
    },
    Activar(id) {
      this.$apollo.mutate({
        mutation: ACTIVAR,
        variables: {
          id: id
        },
        refetchQueries: [{query: BUSCAR, variables: {name: this.search}}]
      }).then(response => {
        if (response.data.activePuntosClientes.success) {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            customClass: {
              popup: 'bg-white',
              title: 'text-dark'
            },
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "success",
            title: "Operación realizada con éxito"
          });
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            customClass: {
              popup: 'bg-white',
              title: 'text-dark'
            },
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "error",
            title: "Error al activar los puntos del cliente"
          });
        }
      }).catch(error => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          customClass: {
            popup: 'bg-white',
            title: 'text-dark'
          },
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "error",
          title: error
        });
      })
    },
    Desactivar(id) {
      this.$apollo.mutate({
        mutation: DESACTIVAR,
        variables: {
          id: id
        },
        refetchQueries: [{query: BUSCAR, variables: {name: this.search}}]
      }).then(response => {
        if (response.data.desactivePuntosClientes.success) {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            customClass: {
              popup: 'bg-white',
              title: 'text-dark'
            },
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "success",
            title: "Operación realizada con éxito"
          });
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            customClass: {
              popup: 'bg-white',
              title: 'text-dark'
            },
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "error",
            title: "Error al desactivar los puntos del cliente"
          });
        }
      }).catch(error => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          customClass: {
            popup: 'bg-white',
            title: 'text-dark'
          },
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "error",
          title: error
        });
      })
    },
  },
  computed: {
    loading() {
      return this.$apollo.loading;
    }
  }
};
</script>
<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.modal-content {
  padding: 20px;
  width: 50%;
}

@media (max-width: 767px) {
  .modal-content {
    border-radius: 15px;
    padding: 10px;
    width: 90%;
  }
}

.modal {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.modal.show {
  opacity: 1;
}

</style>
