<template>
  <BreadCrumb PageTitle="Listado de sucursales" Subtitle="Administración"></BreadCrumb>
  <SucursalList></SucursalList>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb.vue";
import SucursalList from "@/components/Nomencladores/SucursalList.vue";

export default {
  name: "Estados",
  components: {BreadCrumb, SucursalList}
}
</script>

<style scoped>

</style>
