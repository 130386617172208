<template>
  <div class="Cargando">
    <div v-show="loading" :class="['modal_cargando', { 'show': loading }]">
      <div class="spinner"></div>
    </div>
  </div>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div
        class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25"
    >
      <div class="d-sm-flex align-items-center">
        <form class="search-box position-relative" @submit.prevent="loadData">
          <input
              type="text" v-model="search"
              @change="loadData"
              @keyup.enter="loadData"
              class="form-control shadow-none text-black rounded-0 border-0"
              placeholder="Buscar"
          />
          <button class="bg-transparent text-primary transition p-0 border-0">
            <i class="flaticon-search-interface-symbol"></i>
          </button>
        </form>
      </div>
      <div class="d-sm-flex align-items-center mt-10 mt-lg-0">
        <button
            @click="()=>dialogCreate=true"
            class="bg-primary position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block d-inline-block text-decoration-none"
        >
          Nueva configuraci&oacute;n
          <i class="flaticon-plus position-relative ms-5 fs-12"></i>
        </button>
      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
          <tr>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Nombre
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              RFC
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Contrato
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
            >
              Acciones
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in configs" :key="item.id">
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.name }}
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.rfc }}
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.contractNo }}
            </td>
            <td class="shadow-none lh-1 fw-bold text-body-tertiary">
              <div class="btn-group" role="group">
                <button v-if="item.isActive" data-bs-toggle="tooltip"
                        style="padding: 0.8rem"
                        data-bs-placement="top" title="Desactivar configuración" data-container="body"
                        data-animation="true" class="btn text-success" @click="changeState(item.id)">
                  <i class="flaticon-ban d-flex justify-content-center align-items-center"></i></button>
                <button v-else data-bs-toggle="tooltip" data-bs-placement="top"
                        style="padding: 0.8rem"
                        title="Activar configuración" data-container="body" data-animation="true"
                        class="btn text-warning" @click="changeState(item.id)">
                  <i class="flaticon-check-mark-1 d-flex justify-content-center align-items-center"></i>
                </button>
                <button data-bs-toggle="tooltip" @click="update(item)"
                        data-bs-placement="top"
                        style="padding: 0.8rem"
                        title="Editar configuración" data-container="body" data-animation="true"
                        class="btn text-info">
                  <i class="flaticon-pen d-flex justify-content-center align-items-center "></i>
                </button>
                <button data-bs-toggle="tooltip" @click="issuerAssign(item)"
                        data-bs-placement="top"
                        style="padding: 0.8rem"
                        title="Asignar emisor" data-container="body" data-animation="true"
                        class="btn text-secondary">
                  <i class="flaticon-users-group  d-flex justify-content-center align-items-center "></i>
                </button>
                <button data-bs-toggle="tooltip" @click="openDialogPass(item)"
                        data-bs-placement="top"
                        style="padding: 0.8rem"
                        title="Cambiar contraseña" data-container="body" data-animation="true"
                        class="btn text-secondary">
                  <i class="flaticon-lock d-flex justify-content-center align-items-center "></i>
                </button>
                <button data-bs-toggle="tooltip" @click="deleteConfig(item)"
                        data-bs-placement="top"
                        style="padding: 0.8rem"
                        title="Eliminar configuración" data-container="body" data-animation="true"
                        class="btn text-secondary">
                  <i class="flaticon-delete d-flex justify-content-center align-items-center "></i>
                </button>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="Nuevo text-dark">
    <div v-if="dialogCreate" :class="['modal', { 'show': dialogCreate }]">
      <div class="modal-content bg-white">
        <div class="row mb-3 d-flex align-items-center">
          <h4 class="text-start fw-bold">
            Nueva configuraci&oacute;n
            <i @click="resetData" class="flaticon-close opacity-10 modal-icon fs-15"></i>
          </h4>
        </div>
        <div class="row mb-3 text-start">
          <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
            <label for="name" class="form-label ms-1">Nombre</label>
            <input :class="objCreate.name===''?'is-invalid':''" id="name" autofocus v-model="objCreate.name"
                   class="form-control shadow-none rounded-0 text-black" :disabled="saveChanges">
          </div>
          <div class="col-lg-6 col-md-12 mb-3">
            <label for="rfc" class="form-label ms-1">RFC</label>
            <input :class="objCreate.rfc==='' || objCreate.rfc.length!==12?'is-invalid':''" id="rfc" autofocus v-model="objCreate.rfc"
                   class="form-control shadow-none rounded-0 text-black" :disabled="saveChanges">
          </div>
          <div class="col-lg-6 col-md-12 mb-3">
            <label for="email" class="form-label ms-1">Correo</label>
            <input :class="objCreate.email===''?'is-invalid':''" id="email" autofocus v-model="objCreate.email"
                   class="form-control shadow-none rounded-0 text-black" type="email" :disabled="saveChanges">
          </div>
          <div class="col-lg-6 col-md-12 mb-3">
            <label for="password" class="form-label ms-1">Contrase&ntilde;a</label>
            <input :class="objCreate.password===''?'is-invalid':''" id="password" autofocus v-model="objCreate.password"
                   class="form-control shadow-none rounded-0 text-black" type="password" :disabled="saveChanges">
          </div>
          <div class="col-lg-6 col-md-12 mb-3">
            <label for="contractNo" class="form-label ms-1">N&uacute;mero de contrato</label>
            <input :class="objCreate.contractNo===''?'is-invalid':''" id="contractNo" autofocus v-model="objCreate.contractNo"
                   class="form-control shadow-none rounded-0 text-black" :disabled="saveChanges">
          </div>
          <div class="col-lg-6 col-md-12 mb-3">
            <label for="url" class="form-label ms-1">URL</label>
            <input :class="objCreate.url===''?'is-invalid':''" id="url" autofocus v-model="objCreate.url"
                   class="form-control shadow-none rounded-0 text-black" type="url" :disabled="saveChanges">
          </div>
          <div class="col-lg-6 col-md-12 mb-3">
            <label for="noCert" class="form-label ms-1">N&uacute;mero de certificado</label>
            <input :class="objCreate.noCert===''?'is-invalid':''" id="noCert" autofocus v-model="objCreate.noCert"
                   class="form-control shadow-none rounded-0 text-black" :disabled="saveChanges">
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
            <label for="cert" class="form-label ms-1">Certificado</label>
            <textarea :class="objCreate.cert===''?'is-invalid':''" id="cert" autofocus v-model="objCreate.cert"
                      class="form-control shadow-none rounded-0 text-black" rows="3" :disabled="saveChanges"></textarea>
          </div>
          <div class="col-lg-6 col-md-12 mb-3">
            <label for="emailSend" class="form-label ms-1">Correo a enviar factura</label>
            <input :class="objCreate.emailSend===''?'is-invalid':''" id="emailSend" autofocus v-model="objCreate.emailSend"
                   class="form-control shadow-none rounded-0 text-black" type="email" :disabled="saveChanges">
          </div>
          <div class="col-lg-6 col-md-12" style="display:flex;">
            <div style="margin-top:auto; margin-bottom:auto;">
              <MazSwitch
                  v-model="objCreate.isTest"
                  color="primary"
                  name="isTest"
              />
              <label class="switch-label" @click="()=>{
                objCreate.isTest = !objCreate.isTest;
              }">Prueba</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-lg-6 d-flex justify-content-start mb-3">
            <button @click="resetData" class="btn btn-secondary d-block w-100" type="button" :disabled="saveChanges">Cancelar</button>
          </div>
          <div class="col-md-12 col-lg-6 d-flex justify-content-end mb-3">
            <button @click="createAction" class="btn btn-danger d-block w-100" type="button"
                    :disabled="saveChanges || isValidCreate">Guardar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="Actualizar text-dark">
    <div v-if="dialogUpdate" :class="['modal', { 'show': dialogUpdate }]">
      <div class="modal-content bg-white">
        <div class="row mb-3 d-flex align-items-center">
          <h4 class="text-start fw-bold">
            Actualizar configuraci&oacute;n
            <i @click="resetData" class="flaticon-close opacity-10 modal-icon fs-15"></i>
          </h4>
        </div>
        <div class="row mb-3 text-start">
          <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
            <label for="name" class="form-label ms-1">Nombre</label>
            <input :class="objUpdate.name===''?'is-invalid':''" id="name" autofocus v-model="objUpdate.name"
                   class="form-control shadow-none rounded-0 text-black" :disabled="saveChanges">
          </div>
          <div class="col-lg-6 col-md-12 mb-3">
            <label for="rfc" class="form-label ms-1">RFC</label>
            <input :class="objUpdate.rfc==='' || objUpdate.rfc.length!==12?'is-invalid':''" id="rfc" autofocus v-model="objUpdate.rfc"
                   class="form-control shadow-none rounded-0 text-black" :disabled="saveChanges">
          </div>
          <div class="col-lg-6 col-md-12 mb-3">
            <label for="email" class="form-label ms-1">Correo</label>
            <input :class="objUpdate.email===''?'is-invalid':''" id="email" autofocus v-model="objUpdate.email"
                   class="form-control shadow-none rounded-0 text-black" type="email" :disabled="saveChanges">
          </div>
          <div class="col-lg-6 col-md-12 mb-3">
            <label for="contractNo" class="form-label ms-1">N&uacute;mero de contrato</label>
            <input :class="objUpdate.contractNo===''?'is-invalid':''" id="contractNo" autofocus v-model="objUpdate.contractNo"
                   class="form-control shadow-none rounded-0 text-black" :disabled="saveChanges">
          </div>
          <div class="col-lg-6 col-md-12 mb-3">
            <label for="url" class="form-label ms-1">URL</label>
            <input :class="objUpdate.url===''?'is-invalid':''" id="url" autofocus v-model="objUpdate.url"
                   class="form-control shadow-none rounded-0 text-black" type="url" :disabled="saveChanges">
          </div>
          <div class="col-lg-6 col-md-12 mb-3">
            <label for="noCert" class="form-label ms-1">N&uacute;mero de certificado</label>
            <input :class="objUpdate.noCert===''?'is-invalid':''" id="noCert" autofocus v-model="objUpdate.noCert"
                   class="form-control shadow-none rounded-0 text-black" :disabled="saveChanges">
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
            <label for="cert" class="form-label ms-1">Certificado</label>
            <textarea :class="objUpdate.cert===''?'is-invalid':''" id="cert" autofocus v-model="objUpdate.cert"
                      class="form-control shadow-none rounded-0 text-black" rows="3" :disabled="saveChanges"></textarea>
          </div>
          <div class="col-lg-6 col-md-12 mb-3">
            <label for="emailSend" class="form-label ms-1">Correo a enviar factura</label>
            <input :class="objUpdate.emailSend===''?'is-invalid':''" id="emailSend" autofocus v-model="objUpdate.emailSend"
                   class="form-control shadow-none rounded-0 text-black" type="email" :disabled="saveChanges">
          </div>
          <div class="col-lg-6 col-md-12" style="display:flex;">
            <div style="margin-top:auto; margin-bottom:auto;">
              <MazSwitch
                  v-model="objUpdate.isTest"
                  color="primary"
                  name="isTest"
              />
              <label class="switch-label" @click="()=>{
                objUpdate.isTest = !objUpdate.isTest;
              }">Prueba</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-lg-6 d-flex justify-content-start mb-3">
            <button @click="resetData" class="btn btn-secondary d-block w-100" type="button" :disabled="saveChanges">Cancelar</button>
          </div>
          <div class="col-md-12 col-lg-6 d-flex justify-content-end mb-3">
            <button @click="updateAction" class="btn btn-danger d-block w-100" type="button"
                    :disabled="saveChanges || isValidUpdate">Actualizar</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="text-dark">
    <div v-if="dialogPass" :class="['modal', { 'show': dialogPass }]">
      <div class="modal-content bg-white">
        <div class="row mb-3 d-flex align-items-center">
          <h4 class="text-start fw-bold">
            Cambiar contrase&ntilde;a
            <i @click="resetDataPass" class="flaticon-close opacity-10 modal-icon fs-15"></i>
          </h4>
        </div>
        <div class="row mb-3 text-start">
          <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
            <label for="password" class="form-label ms-1">Nueva contrase&ntilde;a</label>
            <input :class="objPass.password===''?'is-invalid':'is-valid'" id="password" autofocus
                   v-model="objPass.password" class="form-control shadow-none rounded-0 text-black"
                   type="password" :disabled="saveChanges">
          </div>
          <div class="col-lg-12 col-md-12 mb-3">
            <label for="repeatPassword" class="form-label ms-1">Repetir contrase&ntilde;a</label>
            <input :class="objPass.repeat!==objPass.password?'is-invalid':'is-valid'" id="repeatPassword" autofocus
                   v-model="objPass.repeat" class="form-control shadow-none rounded-0 text-black"
                   type="password" :disabled="saveChanges">
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-lg-6 d-flex justify-content-start mb-3">
            <button @click="resetDataPass" class="btn btn-secondary d-block w-100" type="button"
                    :disabled="saveChanges">Cancelar
            </button>
          </div>
          <div class="col-md-12 col-lg-6 d-flex justify-content-end mb-3">
            <button @click="changePassAction" class="btn btn-danger d-block w-100" type="button"
                    :disabled="saveChanges || isValidPass">Cambiar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import {
  createConfig,
  updateConfig,
  changeStateConfig,
  deleteConfig,
  searchConfigs, changePasswordConfigProdigia
} from "@/actions";
import MazSwitch from "maz-ui/components/MazSwitch";

const auxCreate = {
  name: "",
  rfc: "",
  email: "",
  password: "",
  contractNo: "",
  url: "https://timbrado.pade.mx/servicio/rest/timbrado40/timbrarCfdi",
  noCert: "",
  cert: "",
  emailSend: "",
  isTest: true
}

const auxUpdate = {
  id: "",
  name: "",
  rfc: "",
  email: "",
  contractNo: "",
  url: "https://timbrado.pade.mx/servicio/rest/timbrado40/timbrarCfdi",
  noCert: "",
  cert: "",
  emailSend: "",
  isTest: true
}

const auxPass = {
  id: "",
  password: "",
  repeat: ""
}

export default {
  name: "ProdigiaConfig",
  components: {
    MazSwitch
  },
  data(){
    return {
      id: "",
      configs: [],
      search: "",
      loading: true,
      saveChanges: false,
      dialogCreate: false,
      dialogUpdate: false,
      dialogPass: false,
      objCreate: Object.assign({}, auxCreate),
      objUpdate: Object.assign({}, auxUpdate),
      objPass: Object.assign({}, auxPass),
      toast: Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        customClass: {
          popup: 'bg-white',
          title: 'text-dark'
        },
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        }
      }),
    }
  },
  mounted() {
    this.loadData();
  },
  beforeCreate() {
    if(!this.$store.state.config){
      this.$router.push({
        name: "Dashboard"
      })
    }
  },
  methods: {
    openDialogPass(item){
      this.objPass.id = item.id;
      this.dialogPass = true;
    },
    loadData(){
      this.loading = true;
      searchConfigs(this.search).then(resp=>{
        this.configs = resp.data.data.searchConfigsProdigia.edges.map(e=>e.node);
      }).catch(err=>console.log(err)).finally(()=>{
        this.loading = false;
      });
    },
    resetDataPass(){
      this.objPass = Object.assign({}, auxPass);
      this.dialogPass = false;
    },
    changePassAction(){
      this.saveChanges = true;
      changePasswordConfigProdigia({
        id: this.objPass.id,
        password: this.objPass.password
      }).then(resp=>{
        const {error} = resp.data.data.changePasswordConfigProdigia;
        if(error){
          this.toast.fire({
            icon: "error",
            title: error
          });
        }
        else{
          this.resetDataPass();
        }
      }).catch(err=>{
        this.toast.fire({
          icon: "error",
          title: error.message
        });
      })
    },
    changeState(id){
      changeStateConfig(id).then(resp=>{
        const {error, info} = resp.data.data.changeStateConfigProdigia;
        if (info){
          this.loadData();
        }
        else{
          this.toast.fire({
            icon: "error",
            title: error
          });
        }
      }).catch(err=>{
        this.toast.fire({
          icon: "error",
          title: err.message
        });
      });
    },
    update(item){
      this.objUpdate = {
        id: item.id,
        name: item.name,
        rfc: item.rfc,
        email: item.emailUser,
        contractNo: item.contractNo,
        url: item.url,
        noCert: item.noCert,
        cert: item.cert,
        emailSend: item.emailSend,
        isTest: item.isTest,
      }
      this.dialogUpdate = true;
    },
    issuerAssign(item){
      this.$router.push({
        name: "IssuerView",
        params:{
          id: item.id
        }
      });
    },
    resetData(){
      this.dialogCreate = false;
      this.dialogUpdate = false;
      this.objCreate = Object.assign({}, auxCreate);
      this.objUpdate = Object.assign({}, auxUpdate);
    },
    createAction(){
      this.saveChanges = true;
      createConfig(this.objCreate).then(resp=>{
        const {info, error} = resp.data.data.createConfigProdigia;
        if(info){
          this.configs = [
            info,
            ...this.configs
          ];
          this.dialogCreate = false;
          this.resetData();
          this.toast.fire({
            icon: "success",
            title: "Configuración creada con éxito"
          });
        }
        else{
          this.toast.fire({
            icon: "error",
            title: error
          });
        }
      }).catch(err=>{
        this.toast.fire({
          icon: "error",
          title: err.message
        });
      }).finally(()=>{
        this.saveChanges = false;
      });
    },
    updateAction(){
      this.saveChanges = true;
      updateConfig(this.objUpdate).then(resp=>{
        this.dialogUpdate = false;
        this.resetData();
        this.toast.fire({
          icon: "success",
          title: "Configuración actualizada con éxito"
        });
        this.loadData();
      }).catch(err=>{
        this.toast.fire({
          icon: "error",
          title: err.message
        });
      }).finally(()=>{
        this.saveChanges = false;
      })
    },
    deleteConfig(item){
      deleteConfig(item.id).then(resp=>{
        const {success, error} = resp.data.data.deleteConfigProdigia;
        if (success){
          this.configs = this.configs.filter(e=>e.id!==item.id);
          this.toast.fire({
            icon: "success",
            title: "Configuración eliminada"
          });
        }else{
          this.toast.fire({
            icon: "error",
            title: error
          });
        }
      }).catch(err=>{
        this.toast.fire({
          icon: "error",
          title: err.message
        });
      })
    }
  },
  computed:{
    isValidCreate(){
      return this.objCreate.name === '' || this.objCreate.rfc === '' || this.objCreate.rfc.length !== 12 ||
          this.objCreate.email==='' || this.objCreate.password === '' || this.objCreate.contractNo === '' ||
          this.objCreate.url==='' || this.objCreate.noCert === '' || this.objCreate.cert === '' ||
          this.objCreate.emailSend === '';
    },
    isValidUpdate(){
      return this.objUpdate.name === '' || this.objUpdate.rfc === '' || this.objUpdate.rfc.length !== 12 ||
          this.objUpdate.email==='' || this.objUpdate.password === '' || this.objUpdate.contractNo === '' ||
          this.objUpdate.url==='' || this.objUpdate.noCert === '' || this.objUpdate.cert === '' ||
          this.objUpdate.emailSend === '';
    },
    isValidPass(){
      return this.objPass.password === '' || this.objPass.repeat !== this.objPass.password ;
    }
  }
}
</script>

<style scoped>
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }

  .modal-content {
    padding: 20px;
    width: 70%;
  }

  .w-321 {
    width: 322px
  }

  @media (max-width: 767px) {
    .modal-content {
      border-radius: 15px;
      padding: 10px;
      width: 90%;
    }
  }

  .modal {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  .modal.show {
    opacity: 1;
  }

  .steps-container {
    display: flex;
    align-items: center;
  }

  .step {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    text-align: center;
    line-height: 30px;
    margin: 10px 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 1px rgba(10, 10, 10, 0.60);
    box-shadow: 2px 2px 2px 2px rgba(10, 10, 10, 0.44);
  }

  .active {
    background-color: #1f1f1f;
    color: #fff;
  }

  .dp__theme_dark {
    --dp-background-color: #2b2a3f;
  }

  .selected-items {
    display: flex;
    margin-top: 10px;
  }

  .selected-item {
    background-color: lightgray;
    border: 1px solid gray;
    margin: 5px;
    padding: 5px 5px;
    border-radius: 5px;
    display: flex;
    align-items: center;
  }

  .selected-item span {
    margin-right: 5px;
    color: #0d0c1d;
  }

  .remove-item {
    cursor: pointer;
    margin-left: 5px;
    color: #0d0c1d;
  }

  .switch-label{
    margin-left: 0.3rem;
    font-size: 13pt;
    cursor: pointer;
  }
</style>