<template>
  <BreadCrumb PageTitle="Reporte de métodos de pago" Subtitle="Administración"></BreadCrumb>
  <ReportComponent :headers="headers" :url="url" :action="getReportPaymentMethods" typeReport="reportPaymentMethods"
                   :auto="false" :pagination="false"/>
</template>
<script>
import BreadCrumb from "@/components/BreadCrumb.vue";
import ReportComponent from "@/components/Reports/ReportComponent.vue";
import {getReportPaymentMethods} from "@/actions";

export default {
  name: "ReportMethods",
  components: {
    BreadCrumb,
    ReportComponent
  },
  data() {
    return {
      headers : [
        {
          title: "Método de pago",
          value: "paymentMethod",
          isMoney: false
        },
        {
          title: "Generado",
          value: "money",
          isMoney: true
        }
      ],
      url: `${process.env.VUE_APP_URL_SERVICES}report/payment_methods`
    }
  },
  methods: {
    getReportPaymentMethods
  }
}
</script>

<style scoped>

</style>