<script>
export default {
  name: "EmptyNotifications",
  props: ['size']
}
</script>

<template>
  <svg :width="size??250" :height="size??250" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M415.14 449.16C413.33 449.164 411.54 448.774 409.896 448.017C408.251 447.259 406.792 446.152 405.619 444.773C404.446 443.395 403.587 441.776 403.103 440.032C402.619 438.287 402.521 436.458 402.815 434.672C403.11 432.886 403.79 431.185 404.809 429.689C405.828 428.192 407.161 426.935 408.714 426.006C410.268 425.077 412.006 424.498 413.806 424.308C415.607 424.119 417.427 424.324 419.14 424.91C421.929 425.864 424.29 427.775 425.803 430.305C427.316 432.835 427.883 435.819 427.403 438.728C426.923 441.636 425.428 444.281 423.183 446.191C420.937 448.101 418.088 449.153 415.14 449.16ZM415.14 430.24C413.952 430.237 412.786 430.562 411.77 431.179C410.754 431.796 409.929 432.681 409.384 433.737C408.839 434.793 408.595 435.979 408.681 437.164C408.766 438.349 409.177 439.488 409.867 440.455C410.558 441.422 411.502 442.179 412.595 442.645C413.689 443.11 414.89 443.264 416.065 443.091C417.241 442.918 418.346 442.424 419.259 441.663C420.172 440.903 420.857 439.905 421.24 438.78C421.567 437.811 421.66 436.779 421.511 435.768C421.362 434.756 420.976 433.795 420.384 432.961C419.791 432.128 419.01 431.447 418.104 430.974C417.198 430.501 416.192 430.249 415.17 430.24H415.14Z" fill="#5E6175"/>
    <path d="M353 468.69C352.304 467.278 351.967 465.716 352.019 464.142C352.07 462.568 352.51 461.032 353.298 459.669C354.086 458.306 355.198 457.158 356.535 456.327C357.873 455.497 359.395 455.009 360.966 454.908C362.537 454.806 364.109 455.094 365.543 455.745C366.976 456.397 368.227 457.392 369.184 458.642C370.14 459.892 370.774 461.36 371.028 462.913C371.282 464.467 371.149 466.06 370.64 467.55C370.025 469.336 368.896 470.901 367.394 472.047C365.892 473.193 364.085 473.87 362.199 473.992C360.314 474.114 358.435 473.675 356.798 472.732C355.161 471.789 353.84 470.382 353 468.69ZM361.59 460.9C360.933 460.897 360.288 461.077 359.726 461.417C359.164 461.758 358.707 462.248 358.405 462.832C358.103 463.416 357.969 464.072 358.016 464.727C358.064 465.383 358.291 466.013 358.674 466.547C359.056 467.082 359.579 467.5 360.184 467.757C360.789 468.013 361.453 468.098 362.103 468.001C362.753 467.904 363.364 467.629 363.868 467.207C364.372 466.786 364.75 466.233 364.96 465.61C365.141 465.074 365.191 464.503 365.108 463.943C365.024 463.384 364.809 462.852 364.48 462.393C364.151 461.933 363.718 461.557 363.215 461.298C362.713 461.038 362.156 460.902 361.59 460.9Z" fill="#5E6175"/>
    <path d="M55.78 411.16H49.78V405.16H55.78V411.16ZM55.78 395.16H49.78V389.16H55.78V395.16ZM55.78 379.16H49.78V373.16H55.78V379.16ZM55.78 363.16H49.78V357.16H55.78V363.16ZM55.78 347.16H49.78V341.16H55.78V347.16ZM55.78 331.16H49.78V325.16H55.78V331.16ZM55.78 315.16H49.78V309.16H55.78V315.16ZM55.78 299.16H49.78V293.16H55.78V299.16ZM55.78 283.16H49.78V277.16H55.78V283.16ZM55.78 267.16H49.78V261.16H55.78V267.16ZM55.78 251.16H49.78V245.16H55.78V251.16ZM55.78 235.16H49.78V229.16H55.78V235.16Z" fill="#A9AFC4"/>
    <path d="M404.25 361.81H100.64C100.64 361.81 131.92 346.38 131.92 309.4V183.75C131.92 151.783 144.619 121.126 167.223 98.5224C189.826 75.9186 220.484 63.22 252.45 63.22C284.417 63.22 315.074 75.9186 337.678 98.5224C360.281 121.126 372.98 151.783 372.98 183.75V309.4C373 346.38 404.25 361.81 404.25 361.81Z" fill="#F5F5F5"/>
    <path style="mix-blend-mode:multiply" d="M212.27 91.22C194.239 99.0503 178.891 111.981 168.113 128.421C157.336 144.86 151.6 164.092 151.61 183.75V309.4C151.689 320.69 149.3 331.86 144.61 342.13H212.24L212.27 91.22Z" fill="url(#paint0_linear_1190_3154)"/>
    <path style="mix-blend-mode:multiply" d="M373 183.75V215.06C323.09 195.7 302.72 136.44 329.2 90.8C342.912 102.097 353.952 116.291 361.525 132.363C369.098 148.434 373.017 165.984 373 183.75Z" fill="url(#paint1_linear_1190_3154)"/>
    <path d="M423.68 381.24C423.677 386.391 421.629 391.331 417.986 394.973C414.342 398.614 409.401 400.66 404.25 400.66H100.64C95.4895 400.66 90.5499 398.614 86.908 394.972C83.266 391.33 81.22 386.391 81.22 381.24C81.22 376.09 83.266 371.15 86.908 367.508C90.5499 363.866 95.4895 361.82 100.64 361.82H404.25C406.801 361.819 409.327 362.32 411.685 363.295C414.042 364.271 416.184 365.701 417.988 367.505C419.793 369.308 421.224 371.449 422.201 373.806C423.177 376.163 423.68 378.689 423.68 381.24Z" fill="#F5F5F5"/>
    <path style="mix-blend-mode:multiply" d="M423.68 381.24C423.677 386.391 421.629 391.331 417.986 394.973C414.342 398.614 409.401 400.66 404.25 400.66H100.64C95.4895 400.66 90.5499 398.614 86.908 394.972C83.266 391.33 81.22 386.39 81.22 381.24H423.68Z" fill="url(#paint2_linear_1190_3154)"/>
    <path d="M307.47 400.66V409.9C307.465 418.587 304.012 426.916 297.869 433.059C291.726 439.201 283.397 442.655 274.71 442.66H230.19C221.503 442.655 213.174 439.201 207.031 433.059C200.889 426.916 197.435 418.587 197.43 409.9V400.66H307.47Z" fill="#5E6175"/>
    <path d="M272.74 56.29V64.94C259.309 62.66 245.591 62.66 232.16 64.94V56.29C232.16 50.9088 234.298 45.7479 238.103 41.9428C241.908 38.1377 247.069 36 252.45 36C257.831 36 262.992 38.1377 266.797 41.9428C270.602 45.7479 272.74 50.9088 272.74 56.29Z" fill="#F5F5F5"/>
    <path d="M87.78 364.82L99.32 359.13C100.52 358.53 128.92 344 128.92 309.41V183.75C128.92 150.988 141.935 119.567 165.101 96.4011C188.268 73.2347 219.688 60.22 252.45 60.22C285.212 60.22 316.633 73.2347 339.799 96.4011C362.965 119.567 375.98 150.988 375.98 183.75V309.41C375.98 344 404.38 358.53 405.59 359.13L417 364.82H87.78ZM110.66 358.82H394.24C384.46 351.15 370 335.4 370 309.41V183.75C370 152.579 357.617 122.685 335.576 100.644C313.535 78.6026 283.641 66.22 252.47 66.22C221.299 66.22 191.405 78.6026 169.364 100.644C147.323 122.685 134.94 152.579 134.94 183.75V309.41C134.92 335.4 120.43 351.15 110.66 358.82Z" fill="#5E6175"/>
    <path d="M275.74 68.49L272.23 67.9C259.133 65.6799 245.757 65.6799 232.66 67.9L229.16 68.49V56.29C229.16 50.1131 231.614 44.1892 235.982 39.8215C240.349 35.4538 246.273 33 252.45 33C258.627 33 264.551 35.4538 268.919 39.8215C273.286 44.1892 275.74 50.1131 275.74 56.29V68.49ZM269.74 61.49V56.29C269.74 51.7044 267.918 47.3066 264.676 44.0641C261.433 40.8216 257.036 39 252.45 39C247.864 39 243.467 40.8216 240.224 44.0641C236.982 47.3066 235.16 51.7044 235.16 56.29V61.44C246.63 59.8249 258.27 59.8249 269.74 61.44V61.49Z" fill="#5E6175"/>
    <path d="M404.25 403.66H100.64C94.6938 403.66 88.9912 401.298 84.7866 397.093C80.5821 392.889 78.22 387.186 78.22 381.24C78.22 375.294 80.5821 369.591 84.7866 365.387C88.9912 361.182 94.6938 358.82 100.64 358.82H404.25C410.196 358.82 415.899 361.182 420.103 365.387C424.308 369.591 426.67 375.294 426.67 381.24C426.67 387.186 424.308 392.889 420.103 397.093C415.899 401.298 410.196 403.66 404.25 403.66ZM100.64 364.82C98.4837 364.82 96.3485 365.245 94.3563 366.07C92.3641 366.895 90.554 368.105 89.0293 369.629C87.5045 371.154 86.295 372.964 85.4699 374.956C84.6447 376.949 84.22 379.084 84.22 381.24C84.22 383.396 84.6447 385.532 85.4699 387.524C86.295 389.516 87.5045 391.326 89.0293 392.851C90.554 394.376 92.3641 395.585 94.3563 396.41C96.3485 397.235 98.4837 397.66 100.64 397.66H404.25C408.605 397.66 412.781 395.93 415.861 392.851C418.94 389.771 420.67 385.595 420.67 381.24C420.67 376.885 418.94 372.709 415.861 369.629C412.781 366.55 408.605 364.82 404.25 364.82H100.64Z" fill="#5E6175"/>
    <path style="mix-blend-mode:multiply" d="M307.47 400.66V409.9C307.476 413.922 306.733 417.91 305.28 421.66H199.62C196.97 414.86 197.43 410.16 197.43 400.66H307.47Z" fill="url(#paint3_linear_1190_3154)"/>
    <path d="M404.25 203.4C442.485 203.4 473.48 172.405 473.48 134.17C473.48 95.9353 442.485 64.9399 404.25 64.9399C366.015 64.9399 335.02 95.9353 335.02 134.17C335.02 172.405 366.015 203.4 404.25 203.4Z" fill="#F5F5F5"/>
    <path d="M404.25 206.39C389.964 206.39 375.999 202.154 364.12 194.216C352.242 186.279 342.984 174.998 337.517 161.799C332.051 148.6 330.621 134.076 333.409 120.065C336.196 106.053 343.077 93.1832 353.179 83.0821C363.282 72.981 376.153 66.1025 390.165 63.3166C404.177 60.5307 418.7 61.9626 431.898 67.431C445.096 72.8995 456.376 82.1589 464.312 94.0384C472.248 105.918 476.482 119.884 476.48 134.17C476.459 153.319 468.842 171.678 455.3 185.217C441.759 198.757 423.399 206.371 404.25 206.39ZM404.25 67.94C391.151 67.94 378.346 71.8243 367.455 79.1017C356.563 86.3792 348.074 96.7229 343.062 108.825C338.049 120.927 336.737 134.243 339.293 147.091C341.848 159.938 348.156 171.739 357.418 181.002C366.681 190.264 378.482 196.572 391.329 199.127C404.177 201.683 417.493 200.371 429.595 195.359C441.697 190.346 452.041 181.857 459.318 170.965C466.596 160.074 470.48 147.269 470.48 134.17C470.462 116.61 463.478 99.7753 451.061 87.3587C438.645 74.9422 421.81 67.9585 404.25 67.94Z" fill="#5E6175"/>
    <path d="M404.25 170.07C408.331 170.07 411.64 166.761 411.64 162.68C411.64 158.599 408.331 155.29 404.25 155.29C400.169 155.29 396.86 158.599 396.86 162.68C396.86 166.761 400.169 170.07 404.25 170.07Z" fill="#1D65C1"/>
    <path d="M356.17 121L362.72 113.66C370.72 120.81 380.17 120.96 388.36 113.66L394.91 121C383.1 131.5 368.05 131.56 356.17 121Z" fill="#1D65C1"/>
    <path d="M411.77 121L418.32 113.66C426.32 120.81 435.78 120.96 443.96 113.66L450.51 121C438.7 131.5 423.65 131.56 411.77 121Z" fill="#1D65C1"/>
    <path d="M82.35 434.84L52.13 465.06H81.71V476H38.85V462.88L68.5 433.21H38.52V422.28H82.35V434.84Z" fill="#1D65C1"/>
    <path d="M94 475.57V466.23L110.06 450.16H93.8V441.48H122.02V450.49L105.62 466.89H121.64V475.57H94Z" fill="#1D65C1"/>
    <path d="M134.71 475.57V466.23L150.77 450.16H134.52V441.48H162.73V450.49L146.33 466.89H162.35V475.57H134.71Z" fill="#1D65C1"/>
    <path d="M180.16 475.57C182.877 475.57 185.08 473.367 185.08 470.65C185.08 467.933 182.877 465.73 180.16 465.73C177.443 465.73 175.24 467.933 175.24 470.65C175.24 473.367 177.443 475.57 180.16 475.57Z" fill="#1D65C1"/>
    <path d="M201.43 475.57C204.147 475.57 206.35 473.367 206.35 470.65C206.35 467.933 204.147 465.73 201.43 465.73C198.713 465.73 196.51 467.933 196.51 470.65C196.51 473.367 198.713 475.57 201.43 475.57Z" fill="#1D65C1"/>
    <path d="M111.699 81.5249L107.457 85.7676L121.288 99.5986L125.53 95.3559L111.699 81.5249Z" fill="#5E6175"/>
    <path d="M113.15 110.62H93.5901V116.62H113.15V110.62Z" fill="#5E6175"/>
    <path d="M141.35 67.1399H135.35V86.6999H141.35V67.1399Z" fill="#5E6175"/>
    <path d="M121 235.44H115V229.44H121V235.44ZM105 235.44H99V229.44H105V235.44ZM89 235.44H83V229.44H89V235.44ZM73 235.44H67V229.44H73V235.44Z" fill="#A9AFC4"/>
    <path d="M245.82 235.44H239.82V229.44H245.82V235.44ZM229.82 235.44H223.82V229.44H229.82V235.44ZM213.82 235.44H207.82V229.44H213.82V235.44ZM197.82 235.44H191.82V229.44H197.82V235.44ZM181.82 235.44H175.82V229.44H181.82V235.44ZM165.82 235.44H159.82V229.44H165.82V235.44ZM149.82 235.44H143.82V229.44H149.82V235.44Z" fill="#A9AFC4"/>
    <defs>
      <linearGradient id="paint0_linear_1190_3154" x1="144.64" y1="216.67" x2="247.62" y2="216.67" gradientUnits="userSpaceOnUse">
        <stop stop-color="#CACFDD"/>
        <stop offset="1" stop-color="#EEF0F1"/>
      </linearGradient>
      <linearGradient id="paint1_linear_1190_3154" x1="317.53" y1="152.93" x2="372.98" y2="152.93" gradientUnits="userSpaceOnUse">
        <stop stop-color="#CACFDD"/>
        <stop offset="1" stop-color="#EEF0F1"/>
      </linearGradient>
      <linearGradient id="paint2_linear_1190_3154" x1="81.22" y1="390.95" x2="423.68" y2="390.95" gradientUnits="userSpaceOnUse">
        <stop stop-color="#CACFDD"/>
        <stop offset="1" stop-color="#EEF0F1"/>
      </linearGradient>
      <linearGradient id="paint3_linear_1190_3154" x1="197.4" y1="411.16" x2="307.47" y2="411.16" gradientUnits="userSpaceOnUse">
        <stop stop-color="#CACFDD"/>
        <stop offset="1" stop-color="#EEF0F1"/>
      </linearGradient>
    </defs>
  </svg>

</template>

<style scoped>

</style>