<template>
  <BreadCrumb PageTitle="Reporte de contratos" Subtitle="Administración"></BreadCrumb>
  <ContractComponent/>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb.vue";
import ContractComponent from "@/components/Reports/ContractComponent.vue";

export default {
  name: "ReportContractView",
  components: {ContractComponent, BreadCrumb}
}
</script>

<style scoped>

</style>