<template>
  <BreadCrumb PageTitle="Listado de contratos vencidos" Subtitle="Contratos"></BreadCrumb>
  <ContratoVencidosList></ContratoVencidosList>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb.vue";
import MarcaList from "@/components/Nomencladores/MarcaList.vue";
import ReservaList from '@/components/Reserva/ReservaList.vue'
import ContratoList from "@/components/Contratos/ContratoList.vue";
import ContratosAbiertosList from "@/components/Contratos/ContratosAbiertosList.vue";
import ContratoVencidosList from "@/components/Contratos/ContratoVencidosList.vue";

export default {
  name: "Clientes",
  components: {BreadCrumb, MarcaList, ContratoVencidosList}
}
</script>

<style scoped>

</style>
