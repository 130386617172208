<template>
  <BreadCrumb PageTitle="Listado de talleres" Subtitle="Administración"/>
  <CarWorkshopComponent/>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb.vue";
import CarWorkshopComponent from "@/components/Nomencladores/CarWorkshop.vue";

export default {
  name: "CarWorkshopView",
  components: {
    CarWorkshopComponent,
    BreadCrumb
  }
}
</script>

<style scoped>

</style>