<template>
  <div class="Cargando">
    <div v-show="loading" :class="['modal_cargando', { 'show': loading }]">
      <div class="spinner"></div>
    </div>
  </div>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div
        class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25"
    >
      <div class="d-sm-flex align-items-center">
        <form class="search-box position-relative" @submit.prevent="loadDatas">
          <input
              type="text"
              v-model="marca"
              class="form-control shadow-none text-black rounded-0 border-0"
              placeholder="Buscar motivo"
              @change="loadDatas"
          />
          <button class="bg-transparent text-primary transition p-0 border-0">
            <i class="flaticon-search-interface-symbol"></i>
          </button>
        </form>
        <button class="btn btn-secondary position-relative"
                style="margin-left: 0.5rem !important;"
                @click="searchModal=true"
        >
          <i class="flaticon-dashboard"></i>
        </button>
      </div>
      <div class="d-sm-flex align-items-center mt-10 mt-lg-0">
        <button
            @click="newAction"
            class="bg-primary position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block d-inline-block text-decoration-none"
        >
          Nuevo mantenimiento
          <i class="flaticon-plus position-relative ms-5 fs-12"></i>
        </button>
      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
          <tr>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Auto
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Motivo
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Kilometraje
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Entrada
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Salida
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
            >
              Acciones
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in maintenances" :key="item.id">
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.auto.modelo.name }} {{ item.auto.modelo.marca.name }}({{ item.auto.color }})
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.maintenanceReason.reason }}
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.kmIn }}
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.dateIn }}
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.dateOut }}
            </td>
            <td class="shadow-none lh-1 fw-bold text-body-tertiary">
              <div class="btn-group" role="group">
                <button v-if="item.finishMaintenance"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Reabrir mantenimiento"
                        data-container="body"
                        style="padding: 0.8rem"
                        data-animation="true"
                        class="btn text-warning border-0"
                        @click="changeMaintenanceAction(item)"
                >
                  <i class="flaticon-ban d-flex justify-content-center align-items-center"></i>
                </button>
                <button v-else
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Finalizar mantenimiento"
                        data-container="body"
                        style="padding: 0.8rem"
                        data-animation="true"
                        class="btn text-success border-0"
                        @click="changeMaintenanceAction(item)"
                >
                  <i class="flaticon-check-mark-1 d-flex justify-content-center align-items-center"></i>
                </button>
                <button data-bs-toggle="tooltip"
                        @click="update(item)"
                        data-bs-placement="top"
                        title="Editar mantenimiento"
                        data-container="body"
                        style="padding: 0.8rem"
                        data-animation="true"
                        class="btn text-info border-0"
                        :disabled="item.finishMaintenance"
                >
                  <i class="flaticon-pen d-flex justify-content-center align-items-center "></i>
                </button>
                <button data-bs-toggle="tooltip"
                        @click="viewDetailsAutoAction(item)"
                        data-bs-placement="top"
                        title="Ver detalles del mantenimiento"
                        data-container="body"
                        style="padding: 0.8rem"
                        data-animation="true"
                        class="btn text-info border-0">
                  <i class="flaticon-eye d-flex justify-content-center align-items-center "></i>
                </button>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing pt-2 pb-2">
    <div class="row">
      <div class="col-lg-6 d-flex align-items-center justify-content-center text-dark">
        <p class="me-3" style="margin-bottom: 0px">Elementos por p&aacute;gina</p>
        <select class="form-control" style="width: 50px; height: 40px; padding: 5px" v-model="itemsPerPage"
                @change="searchItems">
          <option selected :value=10>10</option>
          <option :value=50>50</option>
          <option :value=100>100</option>
        </select>
      </div>
      <div class="col-lg-3 d-flex align-items-center justify-content-center text-dark">
        <button class="btn btn-dark me-3" style="height: 40px" @click="prevPage" :disabled="!hasPreviousPage"><i
            class="flaticon-chevron-1 "></i></button>
        <button class="btn btn-dark" style="height: 40px" @click="nextPage" :disabled="!hasNextPage"><i
            class="flaticon-chevron"></i></button>
      </div>
    </div>
  </div>


  <div class="Nuevo text-dark">
    <div v-if="newModal" :class="['modal', { 'show': newModal }]">
      <div class="modal-content bg-white">
        <div class="row d-flex align-items-center">
          <h4 class="text-start fw-bold">
            Nuevo mantenimiento
            <i @click="resetNew" class="flaticon-close opacity-10 modal-icon fs-15"></i>
          </h4>
        </div>
        <div class="row text-start">
          <div class="col-lg-6 col-md-6 col-sm-6">
            <label class="form-label ms-1" for="autosID">Auto</label>
            <select :disabled="saveChanges" :class="obj.auto===''?'is-invalid':''" id="autosID" v-model="obj.auto"
                    class="form-select" aria-label="size 3 select example">
              <input class="form" v-model="marcaSelect"/>
              <option v-for="auto in autos.filter(e=>e.modelo.marca.name.indexOf(marcaSelect)>=0)" :value="auto.id"
                      :key="auto.id">
                {{ auto.modelo.name }} {{ auto.modelo.marca.name }}({{ auto.color }})
              </option>
            </select>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6">
            <label class="form-label ms-1" for="reasonCreate">Motivo</label>
            <select :disabled="saveChanges" :class="obj.maintenanceReason===''?'is-invalid':''" id="reasonCreate"
                    v-model="obj.maintenanceReason" class="form-select">
              <option v-for="reason in reasons" :value="reason.id">{{ reason.reason }}</option>
            </select>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6">
            <label class="form-label ms-1" for="carWorkshop">Tipo de Taller</label>
            <select :disabled="saveChanges" :class="obj.carWorkshop===''?'is-invalid':''" id="carWorkshop"
                    v-model="obj.carWorkshop" class="form-select">
              <option value="A">Agencia</option>
              <option value="I">Interno</option>
            </select>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6">
            <label class="form-label ms-1" for="dateIn">Fecha de Entrada</label>
            <input :disabled="saveChanges"
                   :class="obj.dateIn==='' || new Date(obj.dateIn)>new Date(obj.dateOut) || new Date(obj.dateIn)>new Date(obj.dateOutPlan)?'is-invalid':''"
                   type="date" id="dateIn" autofocus v-model="obj.dateIn"
                   class="form-control shadow-none rounded-0 text-black">
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6">
            <label class="form-label ms-1" for="dateOut">Fecha de Salida</label>
            <input :disabled="saveChanges"
                   :class="obj.dateOut==='' || new Date(obj.dateIn)>new Date(obj.dateOut) || new Date(obj.dateIn)>new Date(obj.dateOutPlan)?'is-invalid':''"
                   type="date" id="dateOut" autofocus v-model="obj.dateOut"
                   class="form-control shadow-none rounded-0 text-black">
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6">
            <label class="form-label ms-1" for="dateOutPlan">Fecha de Salida Plan</label>
            <input :disabled="saveChanges"
                   :class="obj.dateOutPlan==='' || new Date(obj.dateIn)>new Date(obj.dateOut) || new Date(obj.dateIn)>new Date(obj.dateOutPlan)?'is-invalid':''"
                   type="date" id="dateOutPlan" autofocus v-model="obj.dateOutPlan"
                   class="form-control shadow-none rounded-0 text-black">
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6">
            <label class="form-label ms-1" for="kmIn">Kilometraje de Entrada</label>
            <input :class="obj.kmIn<0 || obj.kmOut<obj.kmIn?'is-invalid':''" type="number" id="kmIn" autofocus
                   v-model="obj.kmIn"
                   class="form-control shadow-none rounded-0 text-black" :disabled="saveChanges">
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6">
            <label class="form-label ms-1" for="kmOut">Kilometraje de Salida</label>
            <input :disabled="saveChanges" :class="obj.kmOut<0 || obj.kmOut<obj.kmIn?'is-invalid':''" type="number"
                   id="kmOut" autofocus v-model="obj.kmOut"
                   class="form-control shadow-none rounded-0 text-black">
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6">
            <label class="form-label ms-1" for="gasolineIn">Gasolina de Entrada</label>
            <input :disabled="saveChanges" :class="obj.gasolineIn<0?'is-invalid':''" type="number" id="gasolineIn"
                   autofocus v-model="obj.gasolineIn"
                   class="form-control shadow-none rounded-0 text-black">
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6">
            <label class="form-label ms-1" for="gasolineOut">Gasolina de Salida</label>
            <input :disabled="saveChanges" :class="obj.gasolineOut<0?'is-invalid':''" type="number" id="gasolineOut"
                   autofocus v-model="obj.gasolineOut"
                   class="form-control shadow-none rounded-0 text-black">
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12">
            <label class="form-label ms-1" for="details">Detalles</label>
            <textarea :disabled="saveChanges" id="details" autofocus v-model="obj.details"
                      class="form-control shadow-none rounded-0 text-black"></textarea>
          </div>
        </div>
        <div class="row">
          <div v-show="errorEmpty" class="col-12 text-center p-2"
               style="background-color: rgba(255,19,28,0.08)">
              <span class="d-flex align-items-center justify-content-center">
                <i class="flaticon-warning me-2 fs-15"></i>
                No pueden haber campos vac&iacute;os
              </span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-lg-6 mt-1 d-flex justify-content-start">
            <button :disabled="saveChanges" @click="resetNew" class="btn btn-secondary d-block w-100" type="button">
              Cancelar
            </button>
          </div>
          <div class="col-md-12 col-lg-6 mt-1 d-flex justify-content-end">
            <button @click="createMaintenanceAction" :disabled="errorEmpty || saveChanges || isNotValid"
                    class="btn btn-danger d-block w-100" type="button">Guardar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="Actualizar text-dark">
    <div v-if="updateModal" :class="['modal', { 'show': updateModal }]">
      <div class="modal-content bg-white">
        <div class="row d-flex align-items-center">
          <h4 class="text-start fw-bold">
            Actualizar mantenimiento
            <i @click="resetUpdate" class="flaticon-close opacity-10 modal-icon fs-15"></i>
          </h4>
        </div>
        <div class="row text-start">
          <div class="col-lg-6 col-md-6 col-sm-6">
            <label class="form-label ms-1" for="autosID">Auto</label>
            <select :disabled="saveChanges" :class="obj.auto===''?'is-invalid':''" id="autosID" v-model="obj.auto"
                    class="form-select" aria-label="size 3 select example">
              <input class="form" v-model="marcaSelect"/>
              <option v-for="auto in autos.filter(e=>e.modelo.marca.name.indexOf(marcaSelect)>=0)" :value="auto.id"
                      :key="auto.id">
                {{ auto.modelo.name }} {{ auto.modelo.marca.name }}({{ auto.color }})
              </option>
            </select>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6">
            <label class="form-label ms-1" for="reasonCreate">Motivo</label>
            <select :disabled="saveChanges" :class="obj.maintenanceReason===''?'is-invalid':''" id="reasonCreate"
                    v-model="obj.maintenanceReason" class="form-select">
              <option v-for="reason in reasons" :value="reason.id">{{ reason.reason }}</option>
            </select>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6">
            <label class="form-label ms-1" for="carWorkshop">Tipo de Taller</label>
            <select :disabled="saveChanges" :class="obj.carWorkshop===''?'is-invalid':''" id="carWorkshop"
                    v-model="obj.carWorkshop" class="form-select">
              <option value="A">Agencia</option>
              <option value="I">Interno</option>
            </select>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6">
            <label class="form-label ms-1" for="dateIn">Fecha de Entrada</label>
            <input :disabled="saveChanges"
                   :class="obj.dateIn==='' || new Date(obj.dateIn)>new Date(obj.dateOut) || new Date(obj.dateIn)>new Date(obj.dateOutPlan)?'is-invalid':''"
                   type="date" id="dateIn" autofocus v-model="obj.dateIn"
                   class="form-control shadow-none rounded-0 text-black">
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6">
            <label class="form-label ms-1" for="dateOut">Fecha de Salida</label>
            <input :disabled="saveChanges"
                   :class="obj.dateOut==='' || new Date(obj.dateIn)>new Date(obj.dateOut) || new Date(obj.dateIn)>new Date(obj.dateOutPlan)?'is-invalid':''"
                   type="date" id="dateOut" autofocus v-model="obj.dateOut"
                   class="form-control shadow-none rounded-0 text-black">
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6">
            <label class="form-label ms-1" for="dateOutPlan">Fecha de Salida Plan</label>
            <input :disabled="saveChanges"
                   :class="obj.dateOutPlan==='' || new Date(obj.dateIn)>new Date(obj.dateOut) || new Date(obj.dateIn)>new Date(obj.dateOutPlan)?'is-invalid':''"
                   type="date" id="dateOutPlan" autofocus v-model="obj.dateOutPlan"
                   class="form-control shadow-none rounded-0 text-black">
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6">
            <label class="form-label ms-1" for="kmIn">Kilometraje de Entrada</label>
            <input :class="obj.kmIn<0 || obj.kmOut<obj.kmIn?'is-invalid':''" type="number" id="kmIn" autofocus
                   v-model="obj.kmIn"
                   class="form-control shadow-none rounded-0 text-black" :disabled="saveChanges">
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6">
            <label class="form-label ms-1" for="kmOut">Kilometraje de Salida</label>
            <input :disabled="saveChanges" :class="obj.kmOut<0 || obj.kmOut<obj.kmIn?'is-invalid':''" type="number"
                   id="kmOut" autofocus v-model="obj.kmOut"
                   class="form-control shadow-none rounded-0 text-black">
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6">
            <label class="form-label ms-1" for="gasolineIn">Gasolina de Entrada</label>
            <input :disabled="saveChanges" :class="obj.gasolineIn<0?'is-invalid':''" type="number" id="gasolineIn"
                   autofocus v-model="obj.gasolineIn"
                   class="form-control shadow-none rounded-0 text-black">
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6">
            <label class="form-label ms-1" for="gasolineOut">Gasolina de Salida</label>
            <input :disabled="saveChanges" :class="obj.gasolineOut<0?'is-invalid':''" type="number" id="gasolineOut"
                   autofocus v-model="obj.gasolineOut"
                   class="form-control shadow-none rounded-0 text-black">
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12">
            <label class="form-label ms-1" for="details">Detalles</label>
            <textarea :disabled="saveChanges" id="details" autofocus v-model="obj.details"
                      class="form-control shadow-none rounded-0 text-black"></textarea>
          </div>
        </div>
        <div class="row">
          <div v-show="errorEmpty" class="col-12 text-center p-2"
               style="background-color: rgba(255,19,28,0.08)">
              <span class="d-flex align-items-center justify-content-center">
                <i class="flaticon-warning me-2 fs-15"></i>
                No pueden haber campos vac&iacute;os
              </span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-lg-6 d-flex justify-content-start mt-1">
            <button :disabled="saveChanges" @click="resetUpdate" class="btn btn-secondary d-block w-100" type="button">
              Cancelar
            </button>
          </div>
          <div class="col-md-12 col-lg-6 d-flex justify-content-end mt-1">
            <button @click="updateReason" :disabled="errorEmpty || saveChanges || isNotValid"
                    class="btn btn-danger d-block w-100" type="button">Actualizar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="Actualizar text-dark">
    <div v-if="searchModal" :class="['modal', { 'show': searchModal }]">
      <div class="modal-content bg-white">
        <div class="row d-flex align-items-center">
          <h4 class="text-start fw-bold">
            Buscar
            <i @click="searchModal=false" class="flaticon-close opacity-10 modal-icon fs-15"></i>
          </h4>
        </div>
        <div class="row text-start">
          <div class="col-lg-6 col-md-6 col-sm-6">
            <label class="form-label ms-1" for="model">Modelo</label>
            <input :disabled="loading" id="model" autofocus v-model="model"
                   class="form-control shadow-none rounded-0 text-black">
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6">
            <label class="form-label ms-1" for="marca">Marca</label>
            <input :disabled="loading" id="marca" v-model="marca"
                   class="form-control shadow-none rounded-0 text-black">
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6">
            <label class="form-label ms-1" for="kmIn">Color</label>
            <input :disabled="loading" id="kmIn" v-model="color"
                   class="form-control shadow-none rounded-0 text-black">
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6">
            <label class="form-label ms-1" for="reason">Motivo</label>
            <input :disabled="loading" id="reason" v-model="reason"
                   class="form-control shadow-none rounded-0 text-black">
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-lg-6 d-flex justify-content-start mt-1">
            <button :disabled="loading" @click="searchModal=false" class="btn btn-secondary d-block w-100"
                    type="button">Cancelar
            </button>
          </div>
          <div class="col-md-12 col-lg-6 d-flex justify-content-end mt-1">
            <button @click="searchItems" :disabled="loading" class="btn btn-danger d-block w-100" type="button">Buscar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="Detalles text-dark">
    <div v-if="infoModal" :class="['modal', { 'show': infoModal }]">
      <div class="modal-content bg-white">
        <div class="row mb-3 d-flex align-items-center">
          <h4 class="text-start fw-bold">
            Detalles del veh&iacute;culo en mantenimiento
            <i @click="infoModal=false" class="flaticon-close opacity-10 modal-icon fs-15"></i>
          </h4>
        </div>
        <div class="row mb-3 text-start">
          <div class="col-lg-4 col-md-6 col-sm-12 mb-3 No_econ">
            <label class="form-label ms-1"><strong>N&uacute;mero econ&oacute;mico:</strong> {{ info.auto.noEconomico }}</label>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 mb-3 Marca">
            <label class="form-label ms-1"><strong>Marca:</strong> {{ info.auto.modelo.marca.name }}</label>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 mb-3 Modelo">
            <label class="form-label ms-1"><strong>Modelo:</strong> {{ info.auto.modelo.name }}</label>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 mb-3 No_serie">
            <label class="form-label ms-1"><strong>N&uacute;mero de serie:</strong> {{ info.auto.noSerie }}</label>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 mb-3 Placa">
            <label class="form-label ms-1"><strong>Placa:</strong> {{ info.auto.noPlaca }}</label>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 mb-3 Procedencia">
            <label class="form-label ms-1"><strong>Procedencia:</strong> {{ info.auto.procedencia }}</label>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 mb-3 Seguro">
            <label class="form-label ms-1"><strong>Motivo mantenimiento:</strong> {{
                info.maintenanceReason.reason
              }}</label>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 mb-3 Valor">
            <label class="form-label ms-1"><strong>Taller:</strong>
              {{ info.typeCarWorkshop == 'A' ? 'Agencia' : 'Interno' }}</label>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 mb-3 Seguro">
            <label class="form-label ms-1"><strong>Seguro:</strong> {{ info.auto.seguro }}</label>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 mb-3 Valor">
            <label class="form-label ms-1"><strong>Valor:</strong> $ {{ info.auto.valor }} MXN</label>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 mb-3 Color">
            <label class="form-label ms-1"><strong>Color:</strong> {{ info.auto.color }}</label>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 mb-3 Valor">
            <label class="form-label ms-1"><strong>Kilometraje de entrada:</strong> {{ info.kmIn }} KM</label>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 mb-3 Color">
            <label class="form-label ms-1"><strong>Kilometraje de salida:</strong> {{ info.kmOut }} KM</label>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 mb-3 Valor">
            <label class="form-label ms-1"><strong>Gasolina de entrada:</strong> {{ info.gasolineIn }}L</label>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 mb-3 Color">
            <label class="form-label ms-1"><strong>Gasolina de salida:</strong> {{ info.gasolineOut }}L</label>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 mb-3 Fecha_circulacion">
            <label class="form-label ms-1"><strong>Fecha de circulaci&oacute;n:</strong> {{
                info.auto.fechaCirculacion
              }}</label>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 mb-3 Fecha_gps">
            <label class="form-label ms-1"><strong>Fecha de GPS:</strong> {{ info.auto.fechaGps }}</label>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 mb-3 fecha_seguro">
            <label class="form-label ms-1"><strong>Fecha de seguro:</strong> {{ info.auto.fechaSeguro }}</label>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 mb-3 Fecha_circulacion">
            <label class="form-label ms-1"><strong>Fecha de entrada:</strong> {{ info.dateIn }}</label>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 mb-3 Fecha_gps">
            <label class="form-label ms-1"><strong>Fecha de salida:</strong> {{ info.dateOut }}</label>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 mb-3 fecha_seguro">
            <label class="form-label ms-1"><strong>Fecha de salida plan:</strong> {{ info.dateOutPlan }}</label>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="Actualizar text-dark">
    <div v-if="outModal" :class="['modal', { 'show': outModal }]">
      <div class="modal-content bg-white">
        <div class="row d-flex align-items-center">
          <h4 class="text-start fw-bold">
            Sacar de mantenimiento
            <i @click="resetOut" class="flaticon-close opacity-10 modal-icon fs-15"></i>
          </h4>
        </div>
        <div class="row text-start">
          <div class="col-lg-6 col-md-6 col-sm-6">
            <label class="form-label ms-1" for="dateOut">Fecha de Salida</label>
            <input :disabled="saveChanges"
                   :class="obj.dateOut==='' || new Date(obj.dateIn)>new Date(obj.dateOut) || new Date(obj.dateIn)>new Date(obj.dateOutPlan)?'is-invalid':''"
                   type="date" id="dateOut" autofocus v-model="obj.dateOut"
                   class="form-control shadow-none rounded-0 text-black">
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6">
            <label class="form-label ms-1" for="kmOut">Kilometraje de Salida</label>
            <input :disabled="saveChanges" :class="obj.kmOut<0 || obj.kmOut<obj.kmIn?'is-invalid':''" type="number"
                   id="kmOut" autofocus v-model="obj.kmOut"
                   class="form-control shadow-none rounded-0 text-black">
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6">
            <label class="form-label ms-1" for="gasolineOut">Gasolina de Salida</label>
            <input :disabled="saveChanges" :class="obj.gasolineOut<0?'is-invalid':''" type="number" id="gasolineOut"
                   autofocus v-model="obj.gasolineOut"
                   class="form-control shadow-none rounded-0 text-black">
          </div>
        </div>
        <div class="row">
          <div v-show="errorEmpty" class="col-12 text-center p-2"
               style="background-color: rgba(255,19,28,0.08)">
              <span class="d-flex align-items-center justify-content-center">
                <i class="flaticon-warning me-2 fs-15"></i>
                No pueden haber campos vac&iacute;os
              </span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-lg-6 d-flex justify-content-start mt-1">
            <button :disabled="saveChanges" @click="resetOut" class="btn btn-secondary d-block w-100" type="button">
              Cancelar
            </button>
          </div>
          <div class="col-md-12 col-lg-6 d-flex justify-content-end mt-1">
            <button @click="executeActionMaintenance" :disabled="errorEmpty || saveChanges || isNotValid"
                    class="btn btn-danger d-block w-100" type="button">Sacar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import {
  createMaintenance,
  getMaintenance,
  getPrevMaintenance,
  getNextMaintenance,
  updateMaintenance,
  getMaintenanceReason,
  getAutos,
  changeMaintenance
} from "@/actions";

export default {
  name: "Maintenance",
  data() {
    return {
      hasNextPage: false,
      hasPreviousPage: false,
      startCursor: "",
      lastStartCursor: "",
      endCursor: "",
      lastEndCursor: "",

      marcaSelect: "",

      itemsPerPage: 10,

      model: "",
      marca: "",
      color: "",
      reason: "",

      reasons: [],
      autos: [],

      infoModal: false,
      info: {},

      auxBbj: {
        auto: "",
        carWorkshop: "",
        dateIn: "",
        dateOut: "",
        dateOutPlan: "",
        details: "",
        gasolineIn: 0,
        gasolineOut: 0,
        kmIn: 0,
        kmOut: 0,
        maintenanceReason: ""
      },
      obj: {
        auto: "",
        carWorkshop: "",
        dateIn: "",
        dateOut: "",
        dateOutPlan: "",
        details: "",
        gasolineIn: 0,
        gasolineOut: 0,
        kmIn: 0,
        kmOut: 0,
        maintenanceReason: ""
      },

      hasNextAutos: false,
      nextAutos: "",

      maintenances: [],
      search: "",
      newModal: false,
      updateModal: false,
      searchModal: false,
      saveChanges: false,
      errorEmpty: false,
      loading: true,
      outModal: false,
      id: '',
      toast: Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        customClass: {
          popup: 'bg-white',
          title: 'text-dark'
        },
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        }
      }),
    }
  },
  mounted() {
    if (this.$store.state.car_id !== 0) {
      this.obj.auto = this.$store.state.car_id
      this.$store.state.car_id = 0
      this.newAction()
    }
    this.loadDatas(true);
    getMaintenanceReason(this.search).then(resp => {
      this.reasons = resp.data.data.searchMaintenanceReason.edges.map(e => {
        return e.node;
      });
    }).catch(err => {
      this.toast.fire({
        icon: "error",
        title: err.message
      });
    });
    this.getAutosSelect();
  },
  computed: {
    urlImage() {
      return process.env.VUE_APP_URL_IMAGE
    },
    isNotValid() {
      return this.obj.auto === '' || this.obj.maintenanceReason === '' || this.obj.carWorkshop === '' ||
          this.obj.dateIn === '' || new Date(this.obj.dateIn) > new Date(this.obj.dateOut) ||
          new Date(this.obj.dateIn) > new Date(this.obj.dateOutPlan) ||
          this.obj.kmOut < 0 || this.obj.kmOut < this.obj.kmIn ||
          this.obj.gasolineIn < 0 || this.obj.gasolineOut < 0;
    }
  },
  methods: {
    resetOut() {
      this.outModal = false;
      Object.assign(this.obj, this.auxBbj);
    },
    viewDetailsAutoAction(item) {
      this.info = item;
      this.infoModal = true;
    },
    executeActionMaintenance() {
      this.saveChanges = true;
      const item = this.obj;
      changeMaintenance({
        id: item.id,
        dateOut: item.dateOut,
        kmOut: item.kmOut,
        gasolineOut: item.gasolineOut
      }).then(resp => {
        if (resp.data.data.changeMaintenance.ok) {
          this.maintenances.forEach(e => {
            if (e.id === item.id) {
              e.finishMaintenance = !e.finishMaintenance;
            }
            this.toast.fire({
              icon: "success",
              title: item.finishMaintenance ? "Mantenimiento finalizado con éxito" : "Mantenimiento reabierto con éxito",
            });
            this.getAutosSelect();
          });
        } else {
          this.toast.fire({
            icon: "error",
            title: "No se puede reabrir este mantenimiento pues el vehículo ya está en mantemiento"
          });
        }
        this.resetOut();
      }).catch(err => {
        this.toast.fire({
          icon: "error",
          title: err.message
        });
      }).finally(() => {
        this.saveChanges = false;
      });
    },
    changeMaintenanceAction(item) {
      this.obj.id = item.id;
      this.obj.auto = item.auto.id;
      this.obj.carWorkshop = item.typeCarWorkshop;
      this.obj.dateIn = item.dateIn;
      this.obj.dateOut = item.dateOut;
      this.obj.dateOutPlan = item.dateOutPlan;
      this.obj.details = item.details;
      this.obj.gasolineIn = item.gasolineIn;
      this.obj.gasolineOut = item.gasolineOut;
      this.obj.kmIn = item.kmIn;
      this.obj.kmOut = item.kmOut;
      this.obj.maintenanceReason = item.maintenanceReason.id;
      if (item.finishMaintenance) {
        this.executeActionMaintenance();
      } else {
        this.outModal = true;
      }
    },
    getAutosSelect() {
      getAutos(this.nextAutos).then(resp => {
        this.hasNextAutos = resp.data.data.searchAutosAvailableMaintenances.pageInfo.hasNextPage;
        this.nextAutos = resp.data.data.searchAutosAvailableMaintenances.pageInfo.endCursor;
        this.autos = resp.data.data.searchAutosAvailableMaintenances.edges.map(e => {
          return e.node;
        })
      }).catch(err => {
        this.toast.fire({
          icon: "error",
          title: err.message
        });
      });
    },
    searchItems() {
      this.hasNextPage = false;
      this.hasPreviousPage = false;
      this.startCursor = "";
      this.lastStartCursor = "";
      this.endCursor = "";
      this.lastEndCursor = "";
      this.loadDatas(true);
      this.searchModal = false;
    },
    loadDatas(init = false) {
      this.loading = true;
      let next = '';
      let before = '';
      if (init === false) {
        before = this.lastEndCursor;
        next = this.lastStartCursor;
      }
      getMaintenance(before, next, this.model, this.marca, this.color, this.reason, this.itemsPerPage).then((resp) => {
        if (init === true) {
          this.hasNextPage = resp.data.data.searchMaintenances.pageInfo.hasNextPage;
          this.hasPreviousPage = resp.data.data.searchMaintenances.pageInfo.hasPreviousPage;
        }
        this.startCursor = resp.data.data.searchMaintenances.pageInfo.startCursor;
        this.endCursor = resp.data.data.searchMaintenances.pageInfo.endCursor;
        this.maintenances = resp.data.data.searchMaintenances.edges.map(value => {
          return value.node;
        });
      }).catch((err) => {
        this.toast.fire({
          icon: "error",
          title: err.message
        });
      }).finally(() => {
        this.loading = false;
      });
    },
    nextPage() {
      this.loading = true;

      getNextMaintenance(this.endCursor, this.model, this.marca, this.color, this.reason, this.itemsPerPage).then((resp) => {
        this.lastEndCursor = "";
        this.lastStartCursor = this.endCursor;
        this.hasNextPage = resp.data.data.searchMaintenances.pageInfo.hasNextPage;
        this.hasPreviousPage = true;
        this.startCursor = resp.data.data.searchMaintenances.pageInfo.startCursor;
        this.endCursor = resp.data.data.searchMaintenances.pageInfo.endCursor;
        this.maintenances = resp.data.data.searchMaintenances.edges.map(value => {
          return value.node;
        });
      }).catch((err) => {
        this.toast.fire({
          icon: "error",
          title: err.message
        });
      }).finally(() => {
        this.loading = false;
      });
    },
    prevPage() {
      this.loading = true;
      getPrevMaintenance(this.startCursor, this.model, this.marca, this.color, this.reason, this.itemsPerPage).then((resp) => {
        this.lastEndCursor = this.startCursor;
        this.lastStartCursor = "";
        this.hasNextPage = true;
        this.hasPreviousPage = resp.data.data.searchMaintenances.pageInfo.hasPreviousPage;
        this.startCursor = resp.data.data.searchMaintenances.pageInfo.startCursor;
        this.endCursor = resp.data.data.searchMaintenances.pageInfo.endCursor;
        this.maintenances = resp.data.data.searchMaintenances.edges.map(value => {
          return value.node;
        });
      }).catch((err) => {
        this.toast.fire({
          icon: "error",
          title: err.message
        });
      }).finally(() => {
        this.loading = false;
      });
    },
    newAction() {
      this.newModal = true
    },
    update(item) {
      this.obj.id = item.id;
      this.obj.auto = item.auto.id;
      this.obj.carWorkshop = item.typeCarWorkshop;
      this.obj.dateIn = item.dateIn;
      this.obj.dateOut = item.dateOut;
      this.obj.dateOutPlan = item.dateOutPlan;
      this.obj.details = item.details;
      this.obj.gasolineIn = item.gasolineIn;
      this.obj.gasolineOut = item.gasolineOut;
      this.obj.kmIn = item.kmIn;
      this.obj.kmOut = item.kmOut;
      this.obj.maintenanceReason = item.maintenanceReason.id;
      this.updateModal = true
    },
    resetNew() {
      this.newModal = false;
      this.marcaSelect = "";
      Object.assign(this.obj, this.auxBbj);
    },
    resetUpdate() {
      this.marcaSelect = "";
      Object.assign(this.obj, this.auxBbj);
      this.updateModal = false
    },
    createMaintenanceAction() {
      this.saveChanges = true;
      createMaintenance(this.obj).then(resp => {
        this.maintenances = [
          ...this.maintenances,
          resp.data.data.createMaintenance.info
        ];
        this.autos = this.autos.filter(e => e.id !== this.obj.auto);
        this.toast.fire({
          icon: "success",
          title: "Operación realizada con éxito"
        });
        this.resetNew();
      }).catch(err => {
        this.toast.fire({
          icon: "error",
          title: err.message
        });
      }).finally(() => {
        this.saveChanges = false;
      });
    },
    updateReason() {
      this.saveChanges = true;
      updateMaintenance(this.obj).then(resp => {
        this.maintenances.forEach(e => {
          if (e.id === this.obj.id) {
            const info = {...resp.data.data.updateMaintenance.info}
            e.id = info.id;
            e.auto = info.auto;
            e.typeCarWorkshop = info.typeCarWorkshop;
            e.dateIn = info.dateIn;
            e.dateOut = info.dateOut;
            e.details = info.details;
            e.dateOutPlan = info.dateOutPlan;
            e.gasolineIn = info.gasolineIn;
            e.gasolineOut = info.gasolineOut;
            e.kmIn = info.kmIn;
            e.kmOut = info.kmOut;
            e.maintenanceReason = info.maintenanceReason;
            e.finishMaintenance = info.finishMaintenance;
          }
          this.autos = this.autos.filter(e => e.id !== this.obj.auto);
          this.getAutosSelect();
          this.toast.fire({
            icon: "success",
            title: "Operación realizada con éxito"
          });
        });
        this.resetUpdate();
      }).catch(err => {
        this.toast.fire({
          icon: "error",
          title: err.message
        });
      }).finally(() => {
        this.saveChanges = false;
      });
    },
  }
}
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.modal-content {
  padding: 20px;
  width: 50%;
}

@media (max-width: 767px) {
  .modal-content {
    border-radius: 15px;
    padding: 10px;
    width: 90%;
  }
}

.modal {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.modal.show {
  opacity: 1;
}
</style>