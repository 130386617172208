<template>
  <div class="Cargando">
    <div v-show="loading" :class="['modal_cargando', { 'show': loading }]">
      <div class="spinner"></div>
    </div>
  </div>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div
        class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25"
    >
      <div class="d-sm-flex align-items-center">
        <form class="search-box position-relative" @submit.prevent="searchItem">
          <input
              type="text"
              v-model="search"
              class="form-control shadow-none text-black rounded-0 border-0"
              placeholder="Buscar taller"
              @change="searchItem"
          />
          <button class="bg-transparent text-primary transition p-0 border-0">
            <i class="flaticon-search-interface-symbol"></i>
          </button>
        </form>
      </div>
      <div class="d-sm-flex align-items-center mt-10 mt-lg-0">
        <button
            @click="newAction"
            class="bg-primary position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block d-inline-block text-decoration-none"
        >
          Nuevo taller
          <i class="flaticon-plus position-relative ms-5 fs-12"></i>
        </button>
      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
          <tr>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Nombre
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Tipo
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
            >
              Acciones
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in items" :key="item.id">
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.name }}
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ dictState[item.typeCarWorkshop] }}
            </td>
            <td class="shadow-none lh-1 fw-bold text-body-tertiary">
              <div class="btn-group" role="group">
                <button v-if="item.active" data-bs-toggle="tooltip"
                        style="padding: 0.8rem"
                        data-bs-placement="top" title="Desactivar taller" data-container="body"
                        data-animation="true" class="btn text-success" @click="changeStateAction(item.id)">
                  <i class="flaticon-ban d-flex justify-content-center align-items-center"></i>
                </button>
                <button v-else data-bs-toggle="tooltip" data-bs-placement="top"
                        style="padding: 0.8rem"
                        title="Activar taller" data-container="body" data-animation="true"
                        class="btn text-warning" @click="changeStateAction(item.id)">
                  <i class="flaticon-check-mark-1 d-flex justify-content-center align-items-center"></i>
                </button>
                <button data-bs-toggle="tooltip" @click="update(item)"
                        style="padding: 0.8rem"
                        data-bs-placement="top"
                        title="Editar taller" data-container="body" data-animation="true"
                        class="btn text-info">
                  <i class="flaticon-pen d-flex justify-content-center align-items-center "></i>
                </button>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="Nuevo text-dark">
    <div v-if="newModal" :class="['modal', { 'show': newModal }]">
      <div class="modal-content bg-white">
        <div class="row mb-3 d-flex align-items-center">
          <h4 class="text-start fw-bold">
            Nuevo taller
            <i @click="resetNew" class="flaticon-close opacity-10 modal-icon fs-15"></i>
          </h4>
        </div>
        <div class="row mb-3 text-start">
          <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1" for="name">Nombre</label>
            <input :class="createItem.name===''?'is-invalid':''" id="name" autofocus v-model="createItem.name"
                   class="form-control shadow-none rounded-0 text-black">
          </div>
        </div>
        <div class="row mb-3">
          <div v-show="errorEmpty" class="col-12 text-center p-2"
               style="background-color: rgba(255,19,28,0.08)">
              <span class="d-flex align-items-center justify-content-center">
                <i class="flaticon-warning me-2 fs-15"></i>
                No pueden haber campos vac&iacute;os
              </span>
          </div>
        </div>
        <div class="row mb-3 text-start">
          <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1" for="type">Tipo</label>
            <select class="form-select" aria-label="Default select example" id="type" v-model="createItem.typeCarWorkshop">
              <option value="A" selected>Agencia</option>
              <option value="I">Interno</option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-lg-6 d-flex justify-content-start mb-3">
            <button @click="resetNew" class="btn btn-secondary d-block w-100" type="button">Cancelar</button>
          </div>
          <div class="col-md-12 col-lg-6 d-flex justify-content-end mb-3">
            <button @click="createCarWorkshop" :disabled="errorEmpty" class="btn btn-danger d-block w-100" type="button">Guardar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="Actualizar text-dark">
    <div v-if="updateModal" :class="['modal', { 'show': updateModal }]">
      <div class="modal-content bg-white">
        <div class="row mb-3 d-flex align-items-center">
          <h4 class="text-start fw-bold">
            Actualizar taller
            <i @click="" class="flaticon-close opacity-10 modal-icon fs-15"></i>
          </h4>
        </div>
        <div class="row mb-3 text-start">
          <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1" for="nameEdit">Nombre</label>
            <input :class="editItem.name===''?'is-invalid':''" id="nameEdit" autofocus v-model="editItem.name"
                   class="form-control shadow-none rounded-0 text-black">
          </div>
        </div>
        <div class="row mb-3">
          <div v-show="errorEmpty" class="col-12 text-center p-2"
               style="background-color: rgba(255,19,28,0.08)">
              <span class="d-flex align-items-center justify-content-center">
                <i class="flaticon-warning me-2 fs-15"></i>
                No pueden haber campos vac&iacute;os
              </span>
          </div>
        </div>
        <div class="row mb-3 text-start">
          <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1" for="typeEdit">Tipo</label>
            <select class="form-select" aria-label="Default select example" id="typeEdit" v-model="editItem.typeCarWorkshop">
              <option value="A">Agencia</option>
              <option value="I">Interno</option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-lg-6 d-flex justify-content-start mb-3">
            <button @click="resetUpdate" class="btn btn-secondary d-block w-100" type="button">Cancelar</button>
          </div>
          <div class="col-md-12 col-lg-6 d-flex justify-content-end mb-3">
            <button @click="updateCarWorkshop" :disabled="errorEmpty" class="btn btn-danger d-block w-100" type="button">Actualizar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import {
  createCarWorkshop,
  updateCarWorkshop,
  deleteCarWorkshop,
  changeStateCarWorkshop,
  getCarWorkshops,
  getNextCarWorkshops,
  getPrevCarWorkshops
} from "@/actions";

const auxCreate = {
  name: "",
  typeCarWorkshop: "",
};

const auxEdit = {
  id: "",
  name: "",
  typeCarWorkshop: "",
};

export default {
  name: "CarWorkshopComponent",
  data() {
    return {
      hasNextPage: false,
      hasPreviousPage: false,
      startCursor: "",
      lastStartCursor: "",
      endCursor: "",
      lastEndCursor: "",
      cant: 10,

      dictState:{
        "A": "Agencia",
        "I": "Interno"
      },
      items: [],
      search: "",
      newModal: false,
      updateModal: false,
      saveChanges: false,
      errorEmpty: false,
      loading: true,
      createItem:{
        name: "",
        typeCarWorkshop: "",
      },
      editItem: {
        id: "",
        name: "",
        typeCarWorkshop: ""
      },
      toast: Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        customClass: {
          popup: 'bg-white',
          title: 'text-dark'
        },
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        }
      }),
    }
  },
  mounted() {
    this.loadDatas(true);
  },
  methods: {
    loadDatas(init = false) {
      this.loading = true;
      let next = '';
      let before = '';
      if (init === false) {
        before = this.lastEndCursor;
        next = this.lastStartCursor;
      }
      getCarWorkshops(before, next, this.search, this.cant).then((resp) => {
        if (init === true) {
          this.hasNextPage = resp.data.data.searchCarWorkshops.pageInfo.hasNextPage;
          this.hasPreviousPage = resp.data.data.searchCarWorkshops.pageInfo.hasPreviousPage;
        }
        this.startCursor = resp.data.data.searchCarWorkshops.pageInfo.startCursor;
        this.endCursor = resp.data.data.searchCarWorkshops.pageInfo.endCursor;
        this.items = resp.data.data.searchCarWorkshops.edges.map(value => {
          return value.node;
        });
      }).catch((err) => {
        this.toast.fire({
          icon: "error",
          title: err.message
        });
      }).finally(() => {
        this.loading = false;
      });
    },
    nextPage() {
      this.loading = true;
      getNextCarWorkshops(this.endCursor, this.search, this.cant).then((resp) => {
        this.lastEndCursor = "";
        this.lastStartCursor = this.endCursor;
        this.hasNextPage = resp.data.data.searchCarWorkshops.pageInfo.hasNextPage;
        this.hasPreviousPage = true;
        this.startCursor = resp.data.data.searchCarWorkshops.pageInfo.startCursor;
        this.endCursor = resp.data.data.searchCarWorkshops.pageInfo.endCursor;
        this.items = resp.data.data.searchCarWorkshops.edges.map(value => {
          return value.node;
        });
      }).catch((err) => {
        this.toast.fire({
          icon: "error",
          title: err.message
        });
      }).finally(() => {
        this.loading = false;
      });
    },
    prevPage() {
      this.loading = true;
      getPrevCarWorkshops(this.startCursor, this.search, this.cant).then((resp) => {
        this.lastEndCursor = this.startCursor;
        this.lastStartCursor = "";
        this.hasNextPage = true;
        this.hasPreviousPage = resp.data.data.searchCarWorkshops.pageInfo.hasPreviousPage;
        this.startCursor = resp.data.data.searchCarWorkshops.pageInfo.startCursor;
        this.endCursor = resp.data.data.searchCarWorkshops.pageInfo.endCursor;
        this.items = resp.data.data.searchCarWorkshops.edges.map(value => {
          return value.node;
        });
      }).catch((err) => {
        this.toast.fire({
          icon: "error",
          title: err.message
        });
      }).finally(() => {
        this.loading = false;
      });
    },
    searchItem() {
      this.hasNextPage = false;
      this.hasPreviousPage = false;
      this.startCursor = "";
      this.lastStartCursor = "";
      this.endCursor = "";
      this.lastEndCursor = "";
      this.loadDatas(true);
    },
    changeStateAction(id){
      this.saveChanges = true;
      changeStateCarWorkshop({id: id}).then(()=>{
        this.items.forEach(item=>{
          if(id===item.id){
            item.active = !item.active;
          }
        });
      }).catch(err=>{
        this.toast.fire({
          icon: "error",
          title: err.message
        }).finally(()=>{
          this.saveChanges = false;
        });
      })
    },
    newAction() {
      this.newModal = true;
    },
    update(item) {
      this.editItem = item;
      this.updateModal = true;
    },
    resetNew() {
      this.createItem = {...auxCreate};
      this.newModal = false;
    },
    resetUpdate() {
      this.editItem = {...auxEdit};
      this.updateModal = false;
    },
    createCarWorkshop(){
      this.saveChanges = true;
      createCarWorkshop(this.createItem).then(resp=>{
        this.items = [
          ...this.items,
          resp.data.data.createCarWorkshop.info
        ];
        this.toast.fire({
          icon: "success",
          title: "Operación realizada con éxito"
        });
        this.resetNew();
      }).catch(err=>{
        this.toast.fire({
          icon: "error",
          title: err.message
        });
      }).finally(()=>{
        this.saveChanges = false;
      });
    },
    updateCarWorkshop(){
      this.saveChanges = true;
      updateCarWorkshop(this.editItem).then(resp=>{
        this.items.forEach(e=>{
          if(e.id === this.id){
            e = {...this.editItem};
          }
        });
        this.toast.fire({
          icon: "success",
          title: "Operación realizada con éxito"
        });
        this.resetUpdate();
      }).catch(err=>{
        this.toast.fire({
          icon: "error",
          title: err.message
        });
      }).finally(()=>{
        this.saveChanges = false;
      });
    },
    deleteCarWorkshop(item){
      this.saveChanges = true;
      deleteCarWorkshop(item).then(resp=>{
        this.items = this.items.filter(e=>e.id !== this.id);
        this.toast.fire({
          icon: "success",
          title: "Operación realizada con éxito"
        });
      }).catch(err=>{
        this.toast.fire({
          icon: "error",
          title: err.message
        });
      }).finally(()=>{
        this.saveChanges = false;
      });
    }
  }
}
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.modal-content {
  padding: 20px;
  width: 50%;
}

@media (max-width: 767px) {
  .modal-content {
    border-radius: 15px;
    padding: 10px;
    width: 90%;
  }
}

.modal {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.modal.show {
  opacity: 1;
}
</style>