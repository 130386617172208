<template>
  <BreadCrumb PageTitle="Listado de contratos cancelados" Subtitle="Contratos"></BreadCrumb>
  <ContratosCanceladosList></ContratosCanceladosList>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb.vue";
import MarcaList from "@/components/Nomencladores/MarcaList.vue";
import ReservaList from '@/components/Reserva/ReservaList.vue'
import ContratoList from "@/components/Contratos/ContratoList.vue";
import ContratosCanceladosList from "@/components/Contratos/ContratosCanceladosList.vue";

export default {
  name: "Clientes",
  components: {BreadCrumb, MarcaList, ContratosCanceladosList}
}
</script>

<style scoped>

</style>
