<template>
  <BreadCrumb PageTitle="Listado de promociones" Subtitle="Administración"></BreadCrumb>
  <PromocionesList></PromocionesList>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb.vue";
import PromocionesList from "@/components/Nomencladores/PromocionesList.vue";

export default {
  name: "Marcas",
  components: {BreadCrumb, PromocionesList}
}
</script>

<style scoped>

</style>
