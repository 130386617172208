<template>
  <BreadCrumb PageTitle="Reporte de veh&iacute;culos con daños" Subtitle="Administración"></BreadCrumb>
  <ContractDamageComponent/>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb.vue";
import ContractDamageComponent from "@/components/Reports/ContractDamageComponent.vue";

export default {
  name: "ReportContractView",
  components: {ContractDamageComponent, BreadCrumb}
}
</script>

<style scoped>

</style>