<template>
  <div class="row">
    <div class="col-xxl-12 col-xxxl-12">

      <div class="row">
        <div class="col-lg-6 col-xxxl-6 col-md-6 mb-3">
          <WhatHappening/>
        </div>
        <div class="col-lg-6 col-xxxl-6 col-md-6 mb-3">
          <StatsBoxes/>
        </div>

        <div class="col-lg-6 col-xxxl-6 col-md-6 mb-3">
          <CategoriesByDays></CategoriesByDays>
        </div>
        <div class="col-lg-6 col-xxxl-6 col-md-6 mb-3">
          <CategoriesByMoney></CategoriesByMoney>
        </div>
        <div class="col-lg-12 col-xxxl-12 col-md-12 mb-3">
          <MoneyByCar></MoneyByCar>
        </div>
        <div class="col-lg-6 col-xxxl-6 col-md-6 mb-3">
          <SucursalesByMoney></SucursalesByMoney>
        </div>
        <div class="col-lg-6 col-xxxl-6 col-md-6 mb-3">
          <VendedorByMoney></VendedorByMoney>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WhatHappening from "@/components/Dashboard/WhatHappening.vue";
import CategoriesByDays from "@/components/Dashboard/CategoriesByDays.vue";
import CategoriesByMoney from "@/components/Dashboard/CategoriesByMoney.vue";
import SucursalesByMoney from "@/components/Dashboard/SucursalesByMoney.vue";
import VendedorByMoney from "@/components/Dashboard/VendedorByMoney.vue";
import MoneyByCar from "@/components/Dashboard/MoneyByCar.vue";
import Semanales from "@/components/Dashboard/Semanales.vue";
import StatsBoxes from "@/components/Dashboard/StatsBoxes/index.vue";

export default {
  name: "Dashboard",
  components: {
    StatsBoxes,
    CategoriesByDays,
    CategoriesByMoney,
    SucursalesByMoney,
    WhatHappening,
    MoneyByCar,
    Semanales,
    VendedorByMoney
  },
}
</script>

<style scoped>

</style>
