<template>
  <BreadCrumb PageTitle="Listado de configuraciones" Subtitle="Administración"></BreadCrumb>
  <ProdigiaConfig/>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb.vue";
import ProdigiaConfig from "@/components/Nomencladores/ProdigiaConfig.vue";

export default {
  name: "ProdigiaView",
  components: {BreadCrumb, ProdigiaConfig}
}
</script>

<style scoped>

</style>