export const decodeBase64 = (str) => {
    if (/[a-zA-Z]/.test(str)) {
        return parseInt(decodeURIComponent(escape(window.atob(str))).split(":")[1]);
    } else {
        return parseInt(str);
    }
};

export const transfMount = (cant) =>{
    const s = `${cant}`.split('.');
    let mount = '';
    const t = s[0].split('');
    let aux = 0;
    if(t.length > 3){
        for (let i = t.length-1; i >= 0 ; i--) {
            aux += 1;
            if(aux%3 === 0 && i > 0){
                mount = `,${t[i]}${mount}`;
            }
            else{
                mount = `${t[i]}${mount}`;
            }
        }
        if(mount.startsWith(',')){
            mount = mount.substring(1);
        }
        if(mount.startsWith('-,')){
            mount = `-${mount.substring(2)}`;
        }
        return s.length>1?`$${mount}.${s[1]} MXN`:`$${mount}.00 MXN`;
    }
    else{
        return `$${cant} MXN`;
    }
};