<template>
  <BreadCrumb PageTitle="Listado de comisionistas" Subtitle="Administración"></BreadCrumb>
  <ComisionistaList></ComisionistaList>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb.vue";
import ComisionistaList from "@/components/Nomencladores/ComisionistaList.vue";
export default {
  name: "Dropoff",
  components: {BreadCrumb, ComisionistaList}
}
</script>

<style scoped>

</style>
