<template>
  <BreadCrumb PageTitle="Listado de motivos de mantenimiento" Subtitle="Administración"/>
  <MaintenanceReasonComponent/>
</template>

<script>
import MaintenanceReasonComponent from "@/components/Nomencladores/MaintenanceReasonComponent.vue";
import BreadCrumb from "@/components/BreadCrumb.vue";

export default {
  name: 'MaintenanceReasonView',
  components: {
    MaintenanceReasonComponent,
    BreadCrumb
  }
}
</script>

<style scoped>

</style>