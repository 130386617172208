<template>
  <div class="card mb-25 border-0 rounded-0 bg-white monthly-revenue-box grafico">
    <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
      <span
          class="d-block mb-6 fs-13 text-uppercase fw-medium text-dark-emphasis"
      >
        Ventas por veh&iacute;culos
      </span>
      <div v-if="!loading" class="mb-1" v-for="item in vehiculosByMonto.slice(0,end)" :key="item.noEconomico">
        <div class="mt-15 mt-md-25 mb-8 mb-md-12 d-flex justify-content-between align-items-center">
        <span class="fw-medium text-secondary">
          {{ item.modelo.marca.name }} - {{ item.modelo.name }} - {{ item.noEconomico }}
          <span class="fw-bold text-black ms-2">Valor: $ {{ item.valor }} MXN</span>
        </span>
          <span class="fw-bold text-black">Recaudado: $ {{ item.monto }} MXN
        <span class="text-primary fw-semibold ms-2">{{ Calcular_Porciento(item.monto, item.valor) }}%</span>
          </span>
        </div>
        <div
            class="progress"
            role="progressbar"
            :aria-valuenow="Calcular_Porciento(item.monto, item.valor)"
            aria-valuemin="0"
            aria-valuemax="100"
        >
          <div class="progress-bar" :style="`width:`+ Calcular_Porciento(item.monto, item.valor) +`%`"></div>
        </div>
      </div>
      <LoadingDatas v-else/>
      <p v-if="!loading" class="d-flex justify-content-end">
        <span v-if="!mostrar_mas" @click="Mostrar_all" class="btn btn-dark mt-3">Mostrar todos</span>
        <span v-else @click="Ocultar_all" class="btn btn-dark mt-3">Ocultar</span>
      </p>

    </div>
  </div>
</template>

<script>
import {getMountByAutos} from "@/actions";
import LoadingDatas from "@/components/LoadingDatas.vue";

export default {
  name: "RevenueThisMonth",
  components: {LoadingDatas},
  data() {
    return {
      vehiculosByMonto: [],
      end: 5,
      mostrar_mas: false,
      loading: false,
    }
  },
  mounted(){
    this.loading = true;
    getMountByAutos().then(resp=>{
      this.vehiculosByMonto = resp.data.data.vehiculosByMonto;
    }).catch(err=>console.log(err)).finally(()=>{
      this.loading = false;
    })
  },
  methods: {
    Mostrar_all() {
      this.end = this.vehiculosByMonto.length
      this.mostrar_mas = true
    },
    Ocultar_all() {
      this.end = 5
      this.mostrar_mas = false
    },
    Calcular_Porciento(parte, total) {
      return parseFloat(parte) / parseFloat(total) * 100 > 100 ? 100 : (parseFloat(parte) / parseFloat(total) * 100).toFixed(2)
    }
  }
};
</script>
