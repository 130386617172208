<template>
  <header
      class='header-area bg-white text-center text-md-start pt-15 pb-15 ps-15 pe-15 ps-md-20 pe-md-20 pe-lg-30 transition mb-25 position-fixed'
      id="header" :class="Barra_menu?'sticky':''">
    <div class="row align-items-center">
      <div class="col-xl-4 col-lg-5 col-md-6">
        <div
            class="header-left-side justify-content-center justify-content-md-start d-flex align-items-center"
        >
          <button
              class="header-burger-menu transition position-relative lh-1 bg-transparent p-0 border-0"
              id="header-burger-menu" @click="onChange"
          >
            <i class="flaticon-menu-3"></i>
          </button>

        </div>
      </div>
      <div class="col-xl-8 col-lg-7 col-md-6">
        <div
            class="header-right-side d-flex align-items-center justify-content-center justify-content-md-end"
        >
          <LightDarkSwtichBtn/>
          <div class="dropdown notification-dropdown" v-show="tienePermiso('access_to_notifications')">
            <button
                class="dropdown-toggle p-0 position-relative bg-transparent border-0 transition lh-1"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                @click="()=>{
                  viewNotifications = true
                }"
            >
              <i class="flaticon-bell-2"></i>
              <span v-if="notifications.length>0"
                  class="dot-badge position-absolute fw-bold rounded-circle text-white bg-primary d-inline-block"
              >
                {{notifications.length}}
              </span>
            </button>
            <div
                class="dropdown-menu rounded-0 bg-white border-0 start-auto end-0"
                :class="viewNotifications?'show':''"
                @mouseleave="()=>{
                  viewNotifications = false
                }"
            >
              <div
                  class="title d-flex align-items-center justify-content-between"
              >
                <span class="text-black fw-bold">
                  Notificaciones <span class="text-muted">({{notifications.length}})</span>
                </span>
              </div>
              <NotificationsNotRead/>
              <div class="text-center dropdown-footer">
                <router-link
                    to="/auth/notifications"
                    class="link-btn text-decoration-none text-primary position-relative d-inline-block transition fw-medium fw-medium"
                >
                 Ver m&aacute;s
                </router-link>
              </div>
            </div>
          </div>
          <div class="dropdown profile-dropdown">
            <button
                class="dropdown-toggle text-start fs-14 text-black-emphasis d-flex align-items-center p-0 position-relative bg-transparent border-0 transition lh-1"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                @click="()=>{
                  menuUser=true;
                }"
            >
              <span class="title d-none d-lg-block ms-10 ms-lg-15">
                <span class="d-block fw-bold mb-5 mb-md-8">{{ username }}</span>
              </span>
            </button>
            <div id="menu_usuario"
                 class="dropdown-menu rounded-0 bg-white border-0 start-auto end-0"
                 :class="menuUser?'show':''"
                 @mouseleave="()=>{
                   menuUser= false;
                 }"
            >
              <ul class="ps-0 mb-0 list-unstyled dropdown-body">
                <li
                    class="text-body-secondary fw-semibold transition position-relative"
                >
                  <i class="flaticon-user-2"></i>
                  Mi perfil
                  <router-link
                      to="/auth/profile"
                      class="d-block position-absolute start-0 top-0 end-0 bottom-0 text-decoration-none"
                  ></router-link>
                </li>
                <li
                    class="text-body-secondary fw-semibold transition position-relative"
                >
                  <i class="flaticon-logout"></i>
                  Cerrar sesi&oacute;n
                  <a
                      @click="Logout"
                      class="d-block position-absolute start-0 top-0 end-0 bottom-0 text-decoration-none"
                  ></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import LightDarkSwtichBtn from "@/components/NavBar/LightDarkSwtichBtn.vue";
import {mapGetters, mapState} from "vuex";
import NotificationsNotRead from "@/components/Notifications/NotificationsNotRead.vue";
import {getNotificationsNotRead} from "@/actions";

export default {
  name: "MainHeader",
  components: {
    NotificationsNotRead,
    LightDarkSwtichBtn,
  },
  data() {
    return {
      Barra_menu: false,
      menuUser: false,
      viewNotifications: false,
      socket: new WebSocket(`${process.env.VUE_APP_URL_WS_NOTIFICATIONS}?user=${this.$store.state.userid}`),
    }
  },
  computed: {
    ...mapGetters(["tienePermiso"]),
    ...mapState([
      "username",
    ]),
    notifications(){
      return this.$store.state.notifications;
    }
  },
  mounted() {
    window.addEventListener("scroll", () => {
      let scrollPos = window.scrollY;
      this.Barra_menu = scrollPos >= 100;
    });
    this.socket.onmessage = (data, event)=>{
      const newNotification = JSON.parse(data.data.replaceAll("'", '"'));
      if(!this.$store.state.notifications.find(e=>e.id === newNotification.id)) {
        this.$store.state.notifications = [
          {...newNotification},
          ...this.$store.state.notifications
        ]
        this.createNotification(newNotification.title, newNotification.message)
      }
    };

    this.socket.onopen = (evt) =>{
      console.log(evt);
    }

    getNotificationsNotRead(this.$store.state.userid).then(resp=>{
      this.$store.state.notifications = resp.data.data.searchFirebaseNotifications.edges.map(e=>e.node);
    }).catch(err=>console.log(err));
  },
  methods: {
    createNotification(title, message){
      Notification.requestPermission().then((result) => {
        if(result=="granted"){
          const notification = new Notification(title, {
            body: message,
            icon: `${process.env.VUE_APP_URL_IMAGE}images/logo.png`,
          });
        }
      });
    },
    onChange() {
      this.$store.state.SidenavOpen = !this.$store.state.SidenavOpen;
    },
    Logout() {
      $cookies.set("token", null, 15);
      sessionStorage.clear();
      localStorage.clear();
      this.$store.state.isAuth = false;
      this.$store.state.refresh_token = null;
      this.$store.state.username = null;
      this.$store.state.email = null;
      this.$store.state.userid = null;
      this.$store.state.firstName = null;
      this.$store.state.lastName = null;
      this.$router.push({
        name: "Login"
      });
    }
  }
};
</script>
