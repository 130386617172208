<template>
  <div class="Cargando">
    <div v-show="loading" :class="['modal_cargando', { 'show': loading }]">
      <div class="spinner"></div>
    </div>
  </div>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div
        class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25"
    >
      <div class="d-sm-flex align-items-center">
        <form class="search-box position-relative" @submit.prevent="loadData">
          <input
              type="text" v-model="search"
              class="form-control shadow-none text-black rounded-0 border-0"
              placeholder="Buscar"
              @change="loadData"
              @keyup.enter="loadData"
          />
          <button class="bg-transparent text-primary transition p-0 border-0">
            <i class="flaticon-search-interface-symbol"></i>
          </button>
        </form>
      </div>
      <div class="d-sm-flex align-items-center mt-10 mt-lg-0">
        <button
            @click="()=>dialogCreate=true"
            class="bg-primary position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block d-inline-block text-decoration-none"
        >
          Nuevo emisor
          <i class="flaticon-plus position-relative ms-5 fs-12"></i>
        </button>
      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
          <tr>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Nombre
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              RFC
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
            >
              Acciones
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in issuers" :key="item.id">
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.name }}
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.rfc }}
            </td>
            <td class="shadow-none lh-1 fw-bold text-body-tertiary">
              <div class="btn-group" role="group">
                <button data-bs-toggle="tooltip" @click="update(item)"
                        data-bs-placement="top"
                        style="padding: 0.8rem"
                        title="Editar emisor" data-container="body" data-animation="true"
                        class="btn text-info">
                  <i class="flaticon-pen d-flex justify-content-center align-items-center "></i>
                </button>
                <button data-bs-toggle="tooltip" @click="deleteAction(item)"
                        data-bs-placement="top"
                        style="padding: 0.8rem"
                        title="Eliminar emisor" data-container="body" data-animation="true"
                        class="btn text-secondary">
                  <i class="flaticon-delete d-flex justify-content-center align-items-center "></i>
                </button>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="Nuevo text-dark">
    <div v-if="dialogCreate" :class="['modal', { 'show': dialogCreate }]">
      <div class="modal-content bg-white">
        <div class="row mb-3 d-flex align-items-center">
          <h4 class="text-start fw-bold">
            Nuevo emisor
            <i @click="resetData" class="flaticon-close opacity-10 modal-icon fs-15"></i>
          </h4>
        </div>
        <div class="row mb-3 text-start">
          <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">Nombre</label>
            <input :class="objCreate.name===''?'is-invalid':''" id="firstName" autofocus v-model="objCreate.name"
                   class="form-control shadow-none rounded-0 text-black" :disabled="saveChanges">
          </div>
        </div>
        <div class="row mb-3 text-start">
          <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">RFC</label>
            <input :class="objCreate.rfc==='' || objCreate.rfc.length!==12 ||
            !(/[A-Z&Ñ]{3,4}[0-9]{2}(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])[A-Z0-9]{2}[0-9A]/.test(objCreate.rfc))?'is-invalid':''" id="firstName" autofocus v-model="objCreate.rfc"
                   class="form-control shadow-none rounded-0 text-black" :disabled="saveChanges">
          </div>
        </div>
        <div class="row mb-3 text-start">
          <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">R&eacute;gimen fiscal</label>
            <input :class="objCreate.taxRegime===''?'is-invalid':''" id="firstName" autofocus v-model="objCreate.taxRegime"
                   class="form-control shadow-none rounded-0 text-black" :disabled="saveChanges">
          </div>
        </div>
        <div class="row mb-3 text-start">
          <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">C&oacute;digo postal</label>
            <input :class="objCreate.postalCode===null || objCreate.postalCode <= 9999 || objCreate.postalCode>99999?'is-invalid':''"
                   type="number" min="10000" max="99999" autofocus
                   v-model="objCreate.postalCode" class="form-control shadow-none rounded-0 text-black"
                   :disabled="saveChanges">
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-lg-6 d-flex justify-content-start mb-3">
            <button @click="resetData" class="btn btn-secondary d-block w-100" type="button" :disabled="saveChanges">
              Cancelar
            </button>
          </div>
          <div class="col-md-12 col-lg-6 d-flex justify-content-end mb-3">
            <button @click="createAction" class="btn btn-danger d-block w-100" type="button"
                    :disabled="saveChanges || isValidCreate">Guardar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="Actualizar text-dark">
    <div v-if="dialogUpdate" :class="['modal', { 'show': dialogUpdate }]">
      <div class="modal-content bg-white">
        <div class="row mb-3 d-flex align-items-center">
          <h4 class="text-start fw-bold">
            Actualizar emisor
            <i @click="resetData" class="flaticon-close opacity-10 modal-icon fs-15"></i>
          </h4>
        </div>
        <div class="row mb-3 text-start">
          <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">Nombre</label>
            <input :class="objUpdate.name===''?'is-invalid':''" id="firstName" autofocus v-model="objUpdate.name"
                   class="form-control shadow-none rounded-0 text-black" :disabled="saveChanges">
          </div>
        </div>
        <div class="row mb-3 text-start">
          <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">RFC</label>
            <input :class="objUpdate.rfc==='' || objUpdate.rfc.length!==12 ||
            !(/[A-Z&Ñ]{3,4}[0-9]{2}(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])[A-Z0-9]{2}[0-9A]/.test(objUpdate.rfc))?'is-invalid':''" id="firstName" autofocus v-model="objUpdate.rfc"
                   class="form-control shadow-none rounded-0 text-black" :disabled="saveChanges">
          </div>
        </div>
        <div class="row mb-3 text-start">
          <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">R&eacute;gimen fiscal</label>
            <input :class="objUpdate.taxRegime===''?'is-invalid':''" id="firstName" autofocus v-model="objUpdate.taxRegime"
                   class="form-control shadow-none rounded-0 text-black" :disabled="saveChanges">
          </div>
        </div>
        <div class="row mb-3 text-start">
          <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">C&oacute;digo postal</label>
            <input :class="objUpdate.postalCode===null || objUpdate.postalCode <= 9999 || objUpdate.postalCode>99999?'is-invalid':''"
                   type="number" min="10000" max="99999"
                   autofocus v-model="objUpdate.postalCode" class="form-control shadow-none rounded-0 text-black"
                   :disabled="saveChanges">
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-lg-6 d-flex justify-content-start mb-3">
            <button @click="resetData" class="btn btn-secondary d-block w-100" type="button" :disabled="saveChanges">Cancelar</button>
          </div>
          <div class="col-md-12 col-lg-6 d-flex justify-content-end mb-3">
            <button @click="updateAction" class="btn btn-danger d-block w-100"
                    type="button" :disabled="saveChanges || isValidUpdate">Actualizar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  createIssuer,
  updateIssuer,
  deleteIssuer,
  getIssuersConfig
} from "@/actions";
import {decodeBase64} from "@/util";
import Swal from "sweetalert2";

const auxCreate = {
  name: "",
  rfc: "",
  taxRegime: "",
  config: "",
  postalCode: null,
}

const auxUpdate = {
  id: "",
  name: "",
  rfc: "",
  taxRegime: "",
  postalCode: null,
}

export default {
  name: "ProdigiaIssuer",
  props: ["id"],
  data(){
    return {
      search: "",
      loading: true,
      saveChanges: false,
      issuers: [],
      objCreate: Object.assign({}, auxCreate),
      objUpdate: Object.assign({}, auxUpdate),
      dialogCreate: false,
      dialogUpdate: false,
      toast: Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        customClass: {
          popup: 'bg-white',
          title: 'text-dark'
        },
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        }
      }),
    }
  },
  mounted() {
    this.loadData();
  },
  beforeCreate() {
    if(!this.$store.state.config){
      this.$router.push({
        name: "Dashboard"
      })
    }
  },
  methods: {
    loadData(){
      this.loading = true;
      getIssuersConfig(decodeBase64(this.id), this.search).then(resp=>{
        this.issuers = resp.data.data.searchIssuers.edges.map(e=>e.node);
      }).catch(err=>console.log(err)).finally(()=>{
        this.loading = false;
      })
    },
    resetData(){
      this.dialogCreate = false;
      this.dialogUpdate = false;
      this.objCreate = Object.assign({}, auxCreate);
      this.objUpdate = Object.assign({}, auxUpdate);
    },
    update(item){
      this.objUpdate = Object.assign({}, item);
      this.objUpdate.postalCode = item.postal;
      this.dialogUpdate = true;
    },
    createAction(){
      this.saveChanges = true;
      this.objCreate.config = this.id;
      createIssuer(this.objCreate).then(resp=>{
        const {info, error} = resp.data.data.createIssuer;
        if(info){
          this.issuers = [
            info,
            ...this.issuers
          ];
          this.dialogCreate = false;
          this.resetData();
          this.toast.fire({
            icon: "success",
            title: "Emisor creado con éxito"
          });
        }
        else{
          this.toast.fire({
            icon: "error",
            title: error
          });
        }
      }).catch(err=>{
        this.toast({
          icon: "error",
          title: err.message
        })
      }).finally(()=>{
        this.saveChanges = false;
      })
    },
    updateAction(){
      this.saveChanges = true;
      updateIssuer({
        id: this.objUpdate.id,
        name: this.objUpdate.name,
        rfc: this.objUpdate.rfc,
        taxRegime: this.objUpdate.taxRegime,
        postalCode: this.objUpdate.postalCode,
      }).then(resp=>{
        this.dialogUpdate = false;
        this.resetData();
        this.toast.fire({
          icon: "success",
          title: "Emisor actualizado con éxito"
        });
        this.loadData();
      }).catch(err=>{
        this.toast.fire({
          icon: "error",
          title: err.message
        })
      }).finally(()=>{
        this.saveChanges = false;
      })
    },
    deleteAction(item){
      deleteIssuer(item.id).then(resp=>{
        this.toast.fire({
          icon: "success",
          title: "Emisor eliminado con éxito"
        });
        this.issuers = this.issuers.filter(e=>e.id!==item.id);
      }).catch(err=>{
        this.toast({
          icon: "error",
          title: err.message
        })
      });
    },
  },
  computed: {
    isValidCreate(){
      return this.objCreate.name==='' || this.objCreate.rfc==='' || this.objCreate.rfc.length!==12 ||
          !(/[A-Z&Ñ]{3,4}[0-9]{2}(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])[A-Z0-9]{2}[0-9A]/.test(this.objCreate.rfc)) ||
          this.objCreate.taxRegime==='' || this.objCreate.postalCode===null || this.objCreate.postalCode <= 9999 ||
          this.objCreate.postalCode>99999;
    },
    isValidUpdate(){
      return this.objUpdate.name==='' || this.objUpdate.rfc==='' || this.objUpdate.rfc.length!==12 ||
          !(/[A-Z&Ñ]{3,4}[0-9]{2}(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])[A-Z0-9]{2}[0-9A]/.test(this.objUpdate.rfc)) ||
          this.objUpdate.taxRegime==='' || this.objUpdate.postalCode===null || this.objUpdate.postalCode <= 9999 ||
          this.objUpdate.postalCode>99999;
    }
  }
}
</script>

<style scoped>
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }

  .modal-content {
    padding: 20px;
    width: 70%;
  }

  .w-321 {
    width: 322px
  }

  @media (max-width: 767px) {
    .modal-content {
      border-radius: 15px;
      padding: 10px;
      width: 90%;
    }
  }

  .modal {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  .modal.show {
    opacity: 1;
  }

  .steps-container {
    display: flex;
    align-items: center;
  }

  .step {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    text-align: center;
    line-height: 30px;
    margin: 10px 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 1px rgba(10, 10, 10, 0.60);
    box-shadow: 2px 2px 2px 2px rgba(10, 10, 10, 0.44);
  }

  .active {
    background-color: #1f1f1f;
    color: #fff;
  }

  .dp__theme_dark {
    --dp-background-color: #2b2a3f;
  }

  .selected-items {
    display: flex;
    margin-top: 10px;
  }

  .selected-item {
    background-color: lightgray;
    border: 1px solid gray;
    margin: 5px;
    padding: 5px 5px;
    border-radius: 5px;
    display: flex;
    align-items: center;
  }

  .selected-item span {
    margin-right: 5px;
    color: #0d0c1d;
  }

  .remove-item {
    cursor: pointer;
    margin-left: 5px;
    color: #0d0c1d;
  }
</style>