<template>
  <div class="card grafico bg-white">
    <div class="card-header text-start">Vendedores con m&aacute;s dinero generado</div>
    <div class="card-body">
      <table class="table table-responsive">
        <thead>
        <tr>
          <th>Vendedor</th>
          <th>Sucursal</th>
          <th>Monto</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="item in categories">
          <td>{{ item.email }}</td>
          <td>{{ item.sucursal ? item.sucursal : "" }}</td>
          <td>$ {{ formatearNumero(montos[item.id]) }} MXN</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {moneyByVendedor, vendedorByMoney} from "@/actions";

export default {
  name: "Ventas",
  data() {
    return {
      categories: [],
      montos: []
    }
  },
  watch: {
    categories(value) {
      if (value.length > 0) {
        value.forEach(item => {
          moneyByVendedor({
            id: item.id
          }).then(resp => {
            this.montos[item.id] = resp.data.data.moneyByVendedor
          }).catch(err => {

          }).finally(() => {

          });
        })
      }
    }
  },
  methods: {
    loadData() {
      vendedorByMoney().then(resp => {
        this.categories = resp.data.data.vendedorByMoney
      }).catch(err => {

      }).finally(() => {
      });
    },
    formatearNumero(numero) {
      const num = parseFloat(numero).toFixed(2);
      return parseFloat(num).toLocaleString("en-US", {minimumFractionDigits: 2});
    },
  },
  mounted() {
    this.loadData()
  }
}
</script>

<style scoped>
.img-categoria {
  height: 50px;
  width: 50px;
  object-fit: cover;
}
</style>
