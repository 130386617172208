import {createRouter, createWebHashHistory} from 'vue-router'
import Login from "@/views/Seguridad/Login.vue";
import Dashboard from "@/views/Dashboard.vue";
import Usuarios from "@/views/Seguridad/Usuarios.vue";
import Permisos from "@/views/Seguridad/Permisos.vue";
import Roles from "@/views/Seguridad/Roles.vue";
import Marcas from "@/views/Nomencladores/Marcas.vue";
import Estados from "@/views/Nomencladores/Estados.vue";
import Modelos from "@/views/Nomencladores/Modelos.vue";
import Ciudades from "@/views/Nomencladores/Ciudades.vue";
import Categoria from "@/views/Autos/Categoria.vue";
import Sucursal from "@/views/Nomencladores/Sucursal.vue";
import Paises from "@/views/Nomencladores/Paises.vue";
import DropOff from "@/views/Nomencladores/DropOff.vue";
import Autos from "@/views/Autos/Autos.vue";
import Servicios from "@/views/Nomencladores/Servicios.vue";
import Clientes from "@/views/Clientes/Clientes.vue";
import Proteccion from "@/views/Nomencladores/Proteccion.vue";
import Procedencia from "@/views/Nomencladores/Procedencia.vue";
import Empresa from "@/views/Nomencladores/Empresa.vue";
import Comisionista from "@/views/Nomencladores/Comisionista.vue";
import Reserva from "@/views/Reserva/Reserva.vue";
import Promociones from "@/views/Nomencladores/Promociones.vue";
import Puntos_Cliente from "@/views/Clientes/Puntos_Cliente.vue";
import Contrato from "@/views/Contratos/Contrato.vue";
import ContratosCancelados from "@/views/Contratos/ContratosCancelados.vue";
import ContratosCerrados from "@/views/Contratos/ContratosCerrados.vue";
import ContratosAbiertos from "@/views/Contratos/ContratosAbiertos.vue";
import ContratosVencidos from "@/views/Contratos/ContratosVencidos.vue";
import MaintenanceReason from "@/views/Nomencladores/MaintenanceReason.vue";
import CarWorkshopView from "@/views/Nomencladores/CarWorkshop.vue";
import HighSeason from "@/views/Nomencladores/HighSeason.vue";
import MaintenanceView from "@/views/Maintenance/MaintenanceView.vue";
import BaseView from "@/views/BaseView.vue";
import NotificationsView from "@/views/Notifications/NotificationsView.vue";
import ProdigiaView from "@/views/Nomencladores/ProdigiaView.vue";
import IssuerView from "@/views/Nomencladores/IssuerView.vue";
import InvoiceView from "@/views/Invoice/InvoiceView.vue";
import ReportMethods from "@/views/Reports/ReportMethods.vue";
import ReportBase from "@/views/Reports/ReportBase.vue";
import ReportSellAuto from "@/views/Reports/ReportSellAuto.vue";
import ReportUnits from "@/views/Reports/ReportUnits.vue";
import ReportReserveView from "@/views/Reports/ReportReserveView.vue";
import ReportContractView from "@/views/Reports/ReportContractView.vue";
import ReportStateUnitView from "@/views/Reports/ReportStateUnitView.vue";
import ProfileView from "@/views/Profile/ProfileView.vue";
import UsePromocionesList from "@/views/Nomencladores/UsePromocionesList.vue";
import PermisosGroup from "@/views/Seguridad/PermisosGroup.vue";
import CarServiceView from "@/views/Reports/CarServiceView.vue";
import ReportContractDamageView from "@/views/Reports/ReportContractDamageView.vue";

const routes = [
    {
        path: '/',
        name: 'Login',
        component: Login
    },
    {
        path: '/auth',
        name: 'BaseView',
        component: BaseView,
        children: [
            {
                path: 'dashboard',
                name: 'Dashboard',
                component: Dashboard
            },
            {
                path: 'profile',
                name: 'ProfileView',
                component: ProfileView
            },
            {
                path: 'seguridad/usuarios',
                name: 'Usuarios',
                component: Usuarios
            },
            {
                path: 'seguridad/permisos',
                name: 'Permisos',
                component: Permisos
            },
            {
                path: 'seguridad/permisosByRol',
                name: 'PermisosByRol',
                component: PermisosGroup
            },
            {
                path: 'seguridad/roles',
                name: 'Roles',
                component: Roles
            },
            {
                path: 'nomencladores/marcas',
                name: 'Marcas',
                component: Marcas
            },
            {
                path: 'nomencladores/estados',
                name: 'Estados',
                component: Estados
            },
            {
                path: 'nomencladores/paises',
                name: 'Paises',
                component: Paises
            },
            {
                path: 'nomencladores/ciudades',
                name: 'Ciudades',
                component: Ciudades
            },
            {
                path: 'nomencladores/modelos',
                name: 'Modelos',
                component: Modelos
            },
            {
                path: 'nomencladores/dropoff',
                name: 'DropOff',
                component: DropOff
            },
            {
                path: 'nomencladores/categorias',
                name: 'Categorias',
                component: Categoria
            },
            {
                path: 'nomencladores/protecciones',
                name: 'Protecciones',
                component: Proteccion
            },
            {
                path: 'nomencladores/procedencias',
                name: 'Procedencias',
                component: Procedencia
            },
            {
                path: 'nomencladores/servicios_extras',
                name: 'Servicios Extras',
                component: Servicios
            },
            {
                path: 'nomencladores/autos',
                name: 'Autos',
                component: Autos
            },
            {
                path: 'nomencladores/sucursales',
                name: 'Sucursales',
                component: Sucursal
            },
            {
                path: 'config-prodigia',
                name: 'ProdigiaView',
                component: ProdigiaView
            },
            {
                path: 'config-prodigia/:id',
                name: 'IssuerView',
                component: IssuerView
            },
            {
                path: 'clientes',
                name: 'Clientes',
                component: Clientes
            },
            {
                path: 'nomencladores/empresas',
                name: 'Empresa',
                component: Empresa
            },
            {
                path: 'nomencladores/promociones',
                name: 'Promociones',
                component: Promociones
            },
            {
                path: 'nomencladores/uso_promociones',
                name: 'UsoPromociones',
                component: UsePromocionesList
            },
            {
                path: 'nomencladores/maintenance-reason',
                name: 'MaintenanceReason',
                component: MaintenanceReason
            },
            {
                path: 'nomencladores/car-workshop',
                name: 'CarWorkshop',
                component: CarWorkshopView
            },
            {
                path: 'clientes/puntos',
                name: 'Puntos de clientes',
                component: Puntos_Cliente
            },
            {
                path: 'nomencladores/comisionista',
                name: 'Comisionista',
                component: Comisionista
            },
            {
                path: 'notifications',
                name: 'NotificationsView',
                component: NotificationsView
            },
            {
                path: 'reservas',
                name: 'Reservas',
                component: Reserva
            },
            {
                path: 'contratos',
                name: 'Contratos',
                component: Contrato
            },
            {
                path: 'contratos/cancelados',
                name: 'Contratos cancelados',
                component: ContratosCancelados
            },
            {
                path: 'contratos/cerrados',
                name: 'Contratos cerrados',
                component: ContratosCerrados
            },
            {
                path: 'contratos/abiertos',
                name: 'Contratos abiertos',
                component: ContratosAbiertos
            },
            {
                path: 'contratos/vencidos',
                name: 'Contratos vencidos',
                component: ContratosVencidos
            },
            {
                path: 'nomencladores/temporadas',
                name: 'Temporadas Altas',
                component: HighSeason
            },
            {
                path: 'maintenance',
                name: 'Maintenance',
                component: MaintenanceView
            },
            {
                path: 'invoices',
                name: 'InvoiceView',
                component: InvoiceView
            },
            {
                path: 'reports/carService',
                name: 'CarService',
                component: CarServiceView
            },
            {
                path: 'reports',
                name: 'ReportBase',
                component: ReportBase,
                children: [
                    {
                        path: 'payment-methods',
                        name: 'ReportMethods',
                        component: ReportMethods
                    },
                    {
                        path: 'sell',
                        name: 'ReportSellAuto',
                        component: ReportSellAuto
                    },
                    {
                        path: 'units',
                        name: 'ReportUnits',
                        component: ReportUnits
                    },
                    {
                        path: 'reserves',
                        name: 'ReportReserveView',
                        component: ReportReserveView
                    },
                    {
                        path: 'contracts',
                        name: 'ReportContractView',
                        component: ReportContractView
                    },
                    {
                        path: 'units-state',
                        name: 'ReportStateUnitView',
                        component: ReportStateUnitView
                    },
                    {
                        path: 'contractDamages',
                        name: 'ContractDamages',
                        component: ReportContractDamageView
                    }
                ]
            }
        ]
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes,
    linkActiveClass: "active"
});

router.beforeEach(async (to, from) => {
    const token = sessionStorage.getItem('token');
    const group = sessionStorage.getItem('group');
    const isAuthenticated = token !== null && token.length > 10;

    if (!isAuthenticated && to.name !== 'Login') {
        return {name: 'Login'}
    } else if (isAuthenticated && to.name === 'Login') {
        return {
            name: 'Dashboard'
        }
    }
});

export default router;
