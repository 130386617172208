<template>
  <BreadCrumb PageTitle="Listado de categorías" Subtitle="Administración"></BreadCrumb>
  <CategoriaList></CategoriaList>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb.vue";
import MarcaList from "@/components/Nomencladores/MarcaList.vue";
import CategoriaList from "@/components/Autos/CategoriaList.vue";

export default {
  name: "Marcas",
  components: {BreadCrumb, MarcaList, CategoriaList}
}
</script>

<style scoped>

</style>
