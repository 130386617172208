<template>
  <div class="card grafico bg-white">
    <div class="card-header text-start d-flex justify-content-between align-items-center">
      <p>Sucursales con m&aacute;s dinero generado</p>
      <select class="form-select form-select-sm w-25" v-model="filter_text">
        <option value="all">Todos</option>
        <option value="today">&Uacute;ltimo d&iacute;a</option>
        <option value="last_seven_days">&Uacute;ltimos 7 d&iacute;as</option>
        <option value="last_month">&Uacute;ltimo mes</option>
      </select>
    </div>
    <div class="card-body">
      <table class="table table-responsive">
        <thead>
        <tr>
          <th>Sucursal</th>
          <th>Monto</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="item in categories">
          <td>{{ item.name }}</td>
          <td>$ {{ formatearNumero(montos[item.id]) }} MXN</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {categoriasByDinero, moneyBySucursal, montoByCategoria, searchCategories, sucursalesByMoney} from "@/actions";

export default {
  name: "Ventas",
  data() {
    return {
      categories: [],
      filter_text: "all",
      montos: []
    }
  },
  watch: {
    categories(value) {
      if (value.length > 0) {
        value.forEach(item => {
          moneyBySucursal({
            id: item.id,
            filter: this.filter_text
          }).then(resp => {
            this.montos[item.id] = resp.data.data.moneyBySucursal
          }).catch(err => {

          }).finally(() => {

          });
        })
      }
    },
    filter_text() {
      this.loadData()
    }
  },
  methods: {
    loadData() {
      sucursalesByMoney({
        filter: this.filter_text
      }).then(resp => {
        this.categories = resp.data.data.sucursalesByMoney
      }).catch(err => {

      }).finally(() => {
      });
    },
    formatearNumero(numero) {
      const num = parseFloat(numero).toFixed(2);
      return parseFloat(num).toLocaleString("en-US", {minimumFractionDigits: 2});
    },
  },
  mounted() {
    this.loadData()
  }
}
</script>

<style scoped>
.img-categoria {
  height: 50px;
  width: 50px;
  object-fit: cover;
}
</style>
