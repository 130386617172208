<template>
  <ul class="ps-0 mb-0 list-unstyled dropdown-body">
    <li v-for="notification in notifications.slice(0,5)" class="text-muted position-relative">
      <div
          class="icon rounded-circle position-absolute text-center"
      >
        <i class="ph-bold ph-chat-centered-dots"></i>
      </div>
      <span class="d-block text-black-emphasis">{{notification.message.length<60?notification.message:`${notification.message.substr(0,57)}...`}}</span>
      <span>{{transfDate(notification.createdAt)}}</span>
      <router-link
          to="/auth/notifications"
          class="d-block position-absolute start-0 top-0 end-0 bottom-0 text-decoration-none"
      ></router-link>
      <span
          class="unread d-inline-block rounded-circle bg-primary position-absolute w-10 h-10"
      ></span>
    </li>
  </ul>
</template>

<script>
export default {
  name: "NotificationsNotRead",
  data(){
    return{
      options: { year: 'numeric', month: 'long', day: 'numeric', timeZone: 'UTC' },
    }
  },
  methods: {
    transfDate(date){
      return (new Date(date)).toLocaleDateString('es-ES', this.options);
    },
  },
  computed: {
    notifications(){
      return this.$store.state.notifications;
    }
  }
}
</script>

<style scoped>

</style>