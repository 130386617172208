<template>
  <BreadCrumb PageTitle="Listado de modelos" Subtitle="Administración"></BreadCrumb>
  <ModeloList></ModeloList>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb.vue";
import ModeloList from "@/components/Nomencladores/ModeloList.vue";

export default {
  name: "Ciudades",
  components: {BreadCrumb, ModeloList}
}
</script>

<style scoped>

</style>
