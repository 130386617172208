<template>
  <div
      class="mb-20 d-sm-flex align-items-center justify-content-between letter-spacing bg-white"
  >
    <h4 class="mb-5 ms-3 mb-sm-0 fw-bold text-dark">Listado de ciudades</h4>
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb mb-0 list-unstyled ps-0">
        <li class="breadcrumb-item">
          <router-link to="/auth/nomencladores/paises" class="text-decoration-none text-dark">
            Países
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link to="/auth/nomencladores/estados" class="text-decoration-none text-dark">
            Estados
          </router-link>
        </li>
        <li class="breadcrumb-item text-dark" aria-current="page">
          Ciudades
        </li>
      </ol>
    </nav>
  </div>
  <CiudadList></CiudadList>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb.vue";
import CiudadList from "@/components/Nomencladores/CiudadList.vue";

export default {
  name: "Ciudades",
  components: {BreadCrumb, CiudadList}
}
</script>

<style scoped>

</style>
