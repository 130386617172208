<template>
  <BreadCrumb PageTitle="Reporte de reservas" Subtitle="Administración"></BreadCrumb>
  <ReserveComponent/>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb.vue";
import ReserveComponent from "@/components/Reports/ReserveComponent.vue";

export default {
  name: "ReportReserveView",
  components: {
    BreadCrumb,
    ReserveComponent
  }
}
</script>

<style scoped>

</style>