<template>
  <div class="accordion-item bg-white text-dark" style="margin-bottom: 0 !important;" :key="notification.id">
    <h6 class="accordion-header" :id="`headingOne${notification.id}`">
      <button class="accordion-button" type="button" data-bs-toggle="collapse"
        :data-bs-target="`#collapseOne${notification.id}`" aria-expanded="true"
        :aria-controls="`collapseOne${notification.id}`" @click="readNotificationAction(notification)">
        <i class="flaticon-bell-1 text-secondary" v-if="!notification.read">
        </i>
        <i class="flaticon-bell-2 text-dark" v-else>
        </i>
        <strong class="text-dark" style="margin-left: 0.5rem;">{{ notification.title }}</strong>
      </button>
    </h6>
    <div :id="`collapseOne${notification.id}`" class="accordion-collapse collapse" :class="show?'show':''"
      :aria-labelledby="`headingOne${notification.id}`" data-bs-parent="#accordionExample"
      style="padding-bottom: 0.5rem;">
      <div class="accordion-body border border-1 border-opacity-10 border-radius-md"
        style="padding-left: 0.4rem; padding-right: 0.4rem;">
        <div class="action-item-notification">
          <button lass="action-btn-notification bg-primary d-block w-100" @click="editNotificationBtn" type="button">
            <i class="flaticon-edit"></i>
          </button>

          <button lass="action-btn-notification d-block w-100" @click="deleteNotificationAction" type="button">
            <i class="flaticon-delete"></i>
          </button>
        </div>
        <p>
          {{ notification.message }}
        </p>
      </div>
    </div>

    <div class="Nuevo text-dark">
      <div v-if="editDialog" :class="['modal', { 'show': editDialog }]">
        <div class="modal-content bg-white">
          <div class="row d-flex align-items-center">
            <h4 class="text-start fw-bold">
              Editar notificaci&oacute;n
              <i @click="() => editDialog = false" class="flaticon-close opacity-10 modal-icon fs-15"></i>
            </h4>
          </div>
          <div class="row text-start">
            <div class="col-lg-12 col-md-12 col-sm-12">
              <label class="form-label ms-1" for="title">T&iacute;tulo</label>
              <input :disabled="saveChanges" :class="obj.title === '' ? 'is-invalid' : ''" id="title" autofocus
                v-model="obj.title" class="form-control shadow-none rounded-0 text-black">
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12">
              <label class="form-label ms-1" for="message">Descripci&oacute;n</label>
              <textarea rows="3" :disabled="saveChanges" :class="obj.message === '' ? 'is-invalid' : ''" id="message"
                v-model="obj.message" class="form-control shadow-none rounded-0 text-black"></textarea>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-lg-6 mt-1 d-flex justify-content-start">
              <button :disabled="saveChanges" @click="() => editDialog = false" class="btn btn-secondary d-block w-100"
                type="button">Cancelar</button>
            </div>
            <div class="col-md-12 col-lg-6 mt-1 d-flex justify-content-end">
              <button :disabled="saveChanges || isNotValid" class="btn btn-danger d-block w-100" type="button"
                @click="editNotificationAction">Cambiar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  readMessage,
  editNotification,
  deleteNotification
} from "@/actions";

export default {
  name: "ItemNotification",
  props: ["notification"],
  data() {
    return {
      show: false,
      obj: {
        id: this.notification.idBase64,
        title: "",
        message: ""
      },
      editDialog: false,
      saveChanges: false,
    }
  },
  methods: {
    readNotificationAction() {
      this.show = !this.show;
      if (this.notification.read === false) {
        readMessage(this.notification.readId).then((resp) => {
          if (resp.data.data.readAdmin.ok) {
            this.notification.read = true;
            this.$store.state.notifications = this.$store.state.notifications.filter(e => e.id !== this.notification.idBase64);
          }
        }).catch(err => console.log(err));
      }
    },

    editNotificationBtn(){
      const aux = Object.assign({}, this.notification);
      this.obj = {
        id: aux.idBase64,
        title: aux.title,
        message: aux.message,
      };
      this.editDialog = true;
    },

    editNotificationAction(){
      this.saveChanges = true;
      editNotification(this.obj).then(resp=>{
        const { error } = resp.data.data.editNotificationCustom;
        if(!error) {
          this.editDialog = false;
          this.$emit('changeNotification');
        }
      }).catch(err=>console.log(err)).finally(()=>{
        this.saveChanges = false;
      });
    },

    deleteNotificationAction() {
      deleteNotification({ id: this.notification.idBase64 }).then(resp => {
        const { error } = resp.data.data.deleteNotification;
        if (!error) {
          this.editDialog = false;
          this.$emit('deleteNotification');
        }
      }).catch(err => console.log(err));
    }
  },
  computed: {
    isNotValid() {
      return this.obj.title === '' || this.obj.message === '';
    }
  },
}
</script>

<style scoped>
    .action-item-notification {
      display: flex !important;
      margin-left: auto;
      margin-right: 0.5rem;
      margin-bottom: 0.3rem;
      width: min-content;
    }

    .action-btn-notification {
      background-color: #dc3545 !important;
      color: #fff !important;
      border-radius: 3px;
    }

    .modal {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 9999;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.7);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    .modal-icon {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
    }
  
    .modal-content {
      padding: 20px;
      width: 50%;
    }
  
    @media (max-width: 767px) {
      .modal-content {
        border-radius: 15px;
        padding: 10px;
        width: 90%;
      }
    }
  
    .modal {
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
    }
  
    .modal.show {
      opacity: 1;
    }
</style>