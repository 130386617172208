<template>
  <BreadCrumb PageTitle="Listado de contratos" Subtitle="Contratos"></BreadCrumb>
  <ContratoList></ContratoList>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb.vue";
import ContratoList from "@/components/Contratos/ContratoList.vue";

export default {
  name: "Contrato",
  components: {BreadCrumb, ContratoList}
}
</script>

<style scoped>

</style>
