<template>
  <div
      class="mb-20 d-sm-flex align-items-center justify-content-between letter-spacing bg-white"
  >
    <h4 class="mb-5 ms-3 mb-sm-0 fw-bold text-dark">Listado de estados</h4>
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb mb-0 list-unstyled ps-0">
        <li class="breadcrumb-item">
          <router-link to="/auth/nomencladores/paises" class="text-decoration-none text-dark">
            Países
          </router-link>
        </li>
        <li class="breadcrumb-item active text-dark" aria-current="page">
          Estados
        </li>
      </ol>
    </nav>
  </div>
  <EstadoList></EstadoList>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb.vue";
import EstadoList from "@/components/Nomencladores/EstadoList.vue";

export default {
  name: "Estados",
  components: {BreadCrumb, EstadoList}
}
</script>

<style scoped>

</style>
