<template>
  <BreadCrumb PageTitle="Listado de vehículos" Subtitle="Administración"></BreadCrumb>
  <AutoList></AutoList>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb.vue";
import MarcaList from "@/components/Nomencladores/MarcaList.vue";
import AutoList from "@/components/Autos/AutoList";

export default {
  name: "Marcas",
  components: {BreadCrumb, MarcaList, AutoList}
}
</script>

<style scoped>

</style>
