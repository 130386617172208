<template>
  <BreadCrumb PageTitle="Listado de uso de promociones" Subtitle="Administración"></BreadCrumb>
  <UsoPromociones></UsoPromociones>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb.vue";
import UsoPromociones from "@/components/Nomencladores/UsoPromociones.vue";

export default {
  name: "Contrato",
  components: {BreadCrumb, UsoPromociones}
}
</script>

<style scoped>

</style>
