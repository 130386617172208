import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

const store = new Vuex.Store({
    state: {
        isAuth: false,
        SidenavOpen: false,
        username: "",
        email: "",
        userid: 0,
        firstName: "",
        lastName: "",
        estado: null,
        pais: null,
        marca: null,
        car_id: 0,
        config: false,
        categoria: null,
        back_url: process.env.VUE_APP_URL_SERVICES,
        refresh_token: '',
        Cargando: false,
        srac_image_url: process.env.VUE_APP_URL_IMAGE,
        reserva: null,
        origen_contrato: "",
        notifications: [],
        isDarkMode: true,
        rol: "",
        permisos: []
    },
    getters: {
        tienePermiso: (state) => (permiso) => {
            let check = false;
            if (state.rol === "Administrador") {
                check = true;
            } else if (state.permisos !== null) {
                state.permisos.forEach(item => {
                    if (item.name === permiso) {
                        check = true;
                    }
                });
            }
            return check;
        }
    },
    mutations: {
        SetPermisos(state, permisos) {
            state.permisos = permisos;
        }
    },
    actions: {
        guardarPermisos({commit}, permisos) {
            commit("SetPermisos", permisos);
        }
    },
    modules: {},
    plugins: [
        //     createPersistedState({
        //     key: 'srac-store', // Nombre del estado persistente
        //     paths: ['isAuth', 'SidenavOpen', 'username', 'email', 'userid', 'firstName', 'lastName', 'config', 'refresh_token', 'isDarkMode'],
        // }),
    ],
})

export default store
