<template>
  <div class="Cargando">
    <div v-show="loading" :class="['modal_cargando', { 'show': loading }]">
      <div class="spinner"></div>
    </div>
  </div>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div
        class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25"
    >
      <div class="d-sm-flex align-items-center">
        <form class="search-box position-relative">
          <input
              type="text" v-model="search"
              class="form-control shadow-none text-black rounded-0 border-0"
              placeholder="Buscar drop off"
          />
          <button class="bg-transparent text-primary transition p-0 border-0">
            <i class="flaticon-search-interface-symbol"></i>
          </button>
        </form>
      </div>
      <div class="d-sm-flex align-items-center mt-10 mt-lg-0">
        <button
            @click="Nuevo"
            class="bg-primary position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block d-inline-block text-decoration-none"
        >
          Nuevo drop off
          <i class="flaticon-plus position-relative ms-5 fs-12"></i>
        </button>
      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
          <tr>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Sucursal de recogida
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Sucursal de entrega
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Tarifa
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
            >
              Acciones
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in dropoffByName" :key="item.id">
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.dropoffSucursalRecogida ? item.dropoffSucursalRecogida.name : 'Sin sucursal' }}
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.dropoffSucursalEntrega ? item.dropoffSucursalEntrega.name : 'Sin sucursal' }}
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.tarifa }}
            </td>
            <td class="shadow-none lh-1 fw-bold text-body-tertiary">
              <div class="btn-group" role="group">
                <button v-if="item.isActive" data-bs-toggle="tooltip"
                        style="padding: 0.8rem"
                        data-bs-placement="top" title="Desactivar dropoff" data-container="body"
                        data-animation="true" class="btn text-success" @click="Desactivar(item.id)">
                  <i class="flaticon-ban d-flex justify-content-center align-items-center"></i></button>
                <button v-else data-bs-toggle="tooltip" data-bs-placement="top"
                        style="padding: 0.8rem"
                        title="Activar dropoff" data-container="body" data-animation="true"
                        class="btn text-warning" @click="Activar(item.id)">
                  <i class="flaticon-check-mark-1 d-flex justify-content-center align-items-center"></i></button>
                <button data-bs-toggle="tooltip" @click="Actualizar(item)"
                        data-bs-placement="top"
                        style="padding: 0.8rem"
                        title="Editar dropoff" data-container="body" data-animation="true"
                        class="btn text-info">
                  <i class="flaticon-pen d-flex justify-content-center align-items-center "></i></button>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="Nuevo text-dark">
    <div v-if="Nuevo_Modal" :class="['modal', { 'show': Nuevo_Modal }]">
      <div class="modal-content bg-white">
        <div class="row mb-3 d-flex align-items-center">
          <h4 class="text-start fw-bold">
            Nuevo drop off
            <i @click="Close_nuevo" class="flaticon-close opacity-10 modal-icon fs-15"></i>
          </h4>
        </div>
        <div class="row mb-3 text-start">
          <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">Sucursal de recogida</label>
            <select id="suc_recogida" autofocus v-model="sucursal_recogida"
                    class="form-select shadow-none rounded-0 text-black">
              <option value="0">Seleccione la sucursal de recogida</option>
              <option v-for="item in sucursalesByName" :value="item.id">{{ item.name }}</option>
            </select>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">Sucursal de entrega</label>
            <select id="suc_recogida" autofocus v-model="sucursal_entrega"
                    class="form-select shadow-none rounded-0 text-black">
              <option value="0">Seleccione la sucursal de entrega</option>
              <option v-for="item in sucursalesByName" :value="item.id">{{ item.name }}</option>
            </select>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">Tarifa</label>
            <input id="tarifa" v-model="tarifa"
                   class="form-control shadow-none rounded-0 text-black">
          </div>
        </div>
        <div class="row mb-3">
          <div v-show="error_vacios" class="col-12 text-center p-2"
               style="background-color: rgba(255,19,28,0.08)">
              <span class="d-flex align-items-center justify-content-center">
                <i class="flaticon-warning me-2 fs-15"></i>
                No pueden haber campos vacíos
              </span>
          </div>
        </div>
        <div class="row mb-3">
          <div v-show="error_sucursales" class="col-12 text-center p-2"
               style="background-color: rgba(255,19,28,0.08)">
              <span class="d-flex align-items-center justify-content-center">
                <i class="flaticon-warning me-2 fs-15"></i>
                Las sucursales no pueden ser iguales
              </span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-lg-6 d-flex justify-content-start mb-3">
            <button @click="Close_nuevo" class="btn btn-secondary d-block w-100" type="button">Cancelar</button>
          </div>
          <div class="col-md-12 col-lg-6 d-flex justify-content-end mb-3">
            <button @click="Crear" class="btn btn-danger d-block w-100" type="button">Guardar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="Actualizar text-dark">
    <div v-if="Actualizar_Modal" :class="['modal', { 'show': Actualizar_Modal }]">
      <div class="modal-content bg-white">
        <div class="row mb-3 d-flex align-items-center">
          <h4 class="text-start fw-bold">
            Actualizar drop off
            <i @click="Close_update" class="flaticon-close opacity-10 modal-icon fs-15"></i>
          </h4>
        </div>
        <div class="row mb-3 text-start">
          <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">Sucursal de recogida</label>
            <select id="suc_recogida" autofocus v-model="sucursal_recogida"
                    :class="sucursal_entrega===sucursal_recogida?'is-invalid':''"
                    class="form-select shadow-none rounded-0 text-black">
              <option value="0">Seleccione la sucursal de recogida</option>
              <option v-for="item in sucursalesByName" :value="item.id">{{ item.name }}</option>
            </select>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">Sucursal de entrega</label>
            <select id="suc_recogida" autofocus v-model="sucursal_entrega"
                    :class="sucursal_entrega===sucursal_recogida?'is-invalid':''"
                    class="form-select shadow-none rounded-0 text-black">
              <option value="0">Seleccione la sucursal de entrega</option>
              <option v-for="item in sucursalesByName" :value="item.id">{{ item.name }}</option>
            </select>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">Tarifa</label>
            <input id="tarifa" v-model="tarifa"
                   class="form-control shadow-none rounded-0 text-black">
          </div>
        </div>
        <div class="row mb-3">
          <div v-show="error_vacios" class="col-12 text-center p-2"
               style="background-color: rgba(255,19,28,0.08)">
              <span class="d-flex align-items-center justify-content-center">
                <i class="flaticon-warning me-2 fs-15"></i>
                No pueden haber campos vacíos
              </span>
          </div>
        </div>
        <div class="row mb-3">
          <div v-show="error_sucursales" class="col-12 text-center p-2"
               style="background-color: rgba(255,19,28,0.08)">
              <span class="d-flex align-items-center justify-content-center">
                <i class="flaticon-warning me-2 fs-15"></i>
                Las sucursales no pueden ser iguales
              </span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-lg-6 d-flex justify-content-start mb-3">
            <button @click="Close_update" class="btn btn-secondary d-block w-100" type="button">Cancelar</button>
          </div>
          <div class="col-md-12 col-lg-6 d-flex justify-content-end mb-3">
            <button @click="Editar" class="btn btn-danger d-block w-100" type="button">Actualizar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";
import Swal from "sweetalert2";
import {decodeBase64} from "@/util";

const BUSCAR = gql`
  query DropoffByName($name:String!) {
    dropoffByName(name: $name) {
        id
        tarifa
        isActive
        dropoffSucursalRecogida {
            id
            name
        }
        dropoffSucursalEntrega {
            id
            name
        }
    }
}
`;
const NUEVO = gql`
mutation CreateDropoff($sucursalRecogida:ID!, $sucursalEntrega:ID!, $tarifa:Decimal!) {
    createDropoff(
        obj: { sucursalRecogida: $sucursalRecogida, sucursalEntrega: $sucursalEntrega, tarifa: $tarifa }
    ) {
        success
        error
    }
}`;
const ACTIVAR = gql`
mutation ActiveDropoff($id:ID!) {
    activeDropoff(obj: { id: $id }) {
        success
        error
    }
}`;
const DESACTIVAR = gql`
mutation DesactiveDropoff($id:ID!) {
    desactiveDropoff(obj: { id: $id }) {
        success
        error
    }
}`;
const ACTUALIZAR = gql`
mutation UpdateDropoff($id:ID!, $sucursalRecogida:ID!, $sucursalEntrega:ID!, $tarifa:Decimal!) {
    updateDropoff(
        obj: { id: $id, sucursalRecogida: $sucursalRecogida, sucursalEntrega: $sucursalEntrega, tarifa: $tarifa }
    ) {
        success
        error
    }
}
`;
const SUCURSALES = gql`
query SucursalesByName {
    sucursalesByName(name: "") {
        id
        name
        direccion
        isActive
    }
}`

export default {
  name: "DropOffList ",
  data() {
    return {
      dropoffByName: [],
      sucursalesByName: [],
      search: "",
      Nuevo_Modal: false,
      Actualizar_Modal: false,
      sucursal_recogida: 0,
      sucursal_entrega: 0,
      tarifa: 0,
      id: 0,
      estado: this.$store.state.estado,
      error_vacios: false,
      error_sucursales: false,
    }
  },
  apollo: {
    dropoffByName: {
      query: BUSCAR,
      variables() {
        return {
          name: this.search
        };
      },
      fetchPolicy: 'cache-and-network'
    },
    sucursalesByName: {
      query: SUCURSALES,
      fetchPolicy: 'cache-and-network'
    }
  },
  methods: {
    Nuevo() {
      this.Nuevo_Modal = true
    },
    Actualizar(item) {
      this.sucursal_recogida = decodeBase64(item.dropoffSucursalRecogida.id)
      this.sucursal_entrega = decodeBase64(item.dropoffSucursalEntrega.id)
      this.tarifa = item.tarifa
      this.id = parseInt(item.id)
      this.Actualizar_Modal = true
    },
    Editar() {
      if (this.sucursal_recogida === 0 || this.sucursal_entrega === 0 || this.id === 0 || this.tarifa === 0) {
        this.error_vacios = true
        return false
      }
      this.error_vacios = false
      this.$apollo.mutate({
        mutation: ACTUALIZAR,
        variables: {
          sucursalRecogida: this.sucursal_recogida,
          sucursalEntrega: this.sucursal_entrega,
          tarifa: this.tarifa,
          id: parseInt(this.id)
        },
        refetchQueries: [{query: BUSCAR, variables: {name: this.search}}]
      }).then(response => {
        if (response.data.updateDropoff.success) {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            customClass: {
              popup: 'bg-white',
              title: 'text-dark'
            },
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "success",
            title: "Operación realizada con éxito"
          });
          this.Close_update()
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            customClass: {
              popup: 'bg-white',
              title: 'text-dark'
            },
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "error",
            title: response.data.updateDropoff.error
          });
          this.Close_update()
        }
      }).catch(error => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          customClass: {
            popup: 'bg-white',
            title: 'text-dark'
          },
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "error",
          title: error
        });
        this.Close_update()
      })
    },
    Close_nuevo() {
      this.sucursal_recogida = 0;
      this.sucursal_entrega = 0;
      this.tarifa = 0;
      this.id = 0;
      this.Nuevo_Modal = false
    },
    Close_update() {
      this.sucursal_recogida = 0;
      this.sucursal_entrega = 0;
      this.tarifa = 0;
      this.id = 0;
      this.Actualizar_Modal = false
    },
    Crear() {
      if (this.sucursal_entrega === this.sucursal_recogida) {
        this.error_sucursales = true
        return false
      }
      if (this.sucursal_recogida === 0 || this.sucursal_entrega === 0 || this.tarifa === 0) {
        this.error_vacios = true
        return false
      }
      this.error_vacios = false
      this.$apollo.mutate({
        mutation: NUEVO,
        variables: {
          sucursalRecogida: this.sucursal_recogida,
          sucursalEntrega: this.sucursal_entrega,
          tarifa: this.tarifa
        },
        refetchQueries: [{query: BUSCAR, variables: {name: this.search}}]
      }).then(response => {
        if (response.data.createDropoff.success) {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            customClass: {
              popup: 'bg-white',
              title: 'text-dark'
            },
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "success",
            title: "Operación realizada con éxito"
          });
          this.Close_nuevo()
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            customClass: {
              popup: 'bg-white',
              title: 'text-dark'
            },
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "error",
            title: response.data.createDropoff.error
          });
          this.Close_nuevo()
        }
      }).catch(error => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          customClass: {
            popup: 'bg-white',
            title: 'text-dark'
          },
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "error",
          title: error
        });
        this.Close_nuevo()
      })
    },
    Activar(id) {
      this.$apollo.mutate({
        mutation: ACTIVAR,
        variables: {
          id: parseInt(id)
        },
        refetchQueries: [{query: BUSCAR, variables: {name: this.search}}]
      }).then(response => {
        if (response.data.activeDropoff.success) {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            customClass: {
              popup: 'bg-white',
              title: 'text-dark'
            },
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "success",
            title: "Operación realizada con éxito"
          });
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            customClass: {
              popup: 'bg-white',
              title: 'text-dark'
            },
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "error",
            title: "Error al activar la ciudad"
          });
        }
      }).catch(error => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          customClass: {
            popup: 'bg-white',
            title: 'text-dark'
          },
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "error",
          title: error
        });
      })
    },
    Desactivar(id) {
      this.$apollo.mutate({
        mutation: DESACTIVAR,
        variables: {
          id: parseInt(id)
        },
        refetchQueries: [{query: BUSCAR, variables: {name: this.search}}]
      }).then(response => {
        if (response.data.desactiveDropoff.success) {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            customClass: {
              popup: 'bg-white',
              title: 'text-dark'
            },
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "success",
            title: "Operación realizada con éxito"
          });
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            customClass: {
              popup: 'bg-white',
              title: 'text-dark'
            },
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "error",
            title: "Error al desactivar la ciudad"
          });
        }
      }).catch(error => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          customClass: {
            popup: 'bg-white',
            title: 'text-dark'
          },
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "error",
          title: error
        });
      })
    }
  },
  computed: {
    loading() {
      return this.$apollo.loading;
    }
  }
};
</script>
<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.modal-content {
  padding: 20px;
  width: 50%;
}

@media (max-width: 767px) {
  .modal-content {
    border-radius: 15px;
    padding: 10px;
    width: 90%;
  }
}

.modal {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.modal.show {
  opacity: 1;
}

</style>
