<template>
  <div class="Cargando">
    <div v-show="loading" :class="['modal_cargando', { 'show': loading }]">
      <div class="spinner"></div>
    </div>
  </div>
  <div class="row justify-content-center mt-3 shadow-lg">
    <div class="col-lg-6 col-md-8 col-sm-10">
      <div class="card bg-white authentication-card">
        <div class="card-body letter-spacing">
          <img :src="urlImg" alt="logo"
               style="width: 100px; height: 100px" class="img-fluid mb-2">
          <h4 class="text-white fw-bold mb-0 text-center mb-md-25">
            Inicie sesi&oacute;n con su cuenta!
          </h4>
          <div class="form-group mb-15 mb-sm-20 mb-md-25">
            <label class="text-white d-block fw-semibold mb-10">
              Usuario
            </label>
            <input v-model="email" @keyup.enter="Iniciar_Sesion"
                   type="email"
                   class="form-control shadow-none rounded-0 text-black"
                   placeholder="e.g. adam127704@gmail.com"
            />
          </div>
          <div class="form-group mb-15 mb-sm-20 mb-md-25">
            <label class="text-white d-block fw-semibold mb-10">
              Contrase&ntilde;a
            </label>
            <input v-model="password" @keyup.enter="Iniciar_Sesion"
                   type="password"
                   class="form-control shadow-none rounded-0 text-black"
                   placeholder="**************"
            />
          </div>
          <div
              class="d-flex align-items-center justify-content-center mb-15 mb-md-20"
          >
            <router-link
                to="/auth/forgot-password"
                class="forgot-password-btn fs-md-15 fs-lg-16 text-decoration-none position-relative text-primary"
            >
              ¿Olvid&oacute; su contrase&ntilde;a?
            </router-link>
          </div>
          <button
              class="btn default-btn transition border-0 fw-medium text-white rounded-1 fs-md-15 fs-lg-16 bg-danger d-block w-100"
              @click="Iniciar_Sesion" style="height: 45px"
          >
            Iniciar sesi&oacute;n ahora
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Swal from "sweetalert2";
import axios from "axios";
import {login, reloadHeader} from "@/actions";
import {mapMutations} from "vuex";

export default {
  name: "LoginForm",
  data() {
    return {
      email: "",
      password: "",
      loading: false
    };
  },
  computed: {
    urlImg() {
      return `${process.env.VUE_APP_URL_IMAGE}Portal/Imagenes/logo3.png`;
    }
  },
  methods: {
    ...mapMutations(["SetPermisos"]),
    messageError() {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        customClass: {
          popup: 'bg-white',
          title: 'text-dark'
        },
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        }
      });
      Toast.fire({
        icon: "error",
        title: "Error en las credenciales"
      });
    },
    Iniciar_Sesion() {
      this.loading = true
      login({
        email: this.email,
        password: this.password
      }).then(response => {
        if (response.data.data.login.success) {
          sessionStorage.setItem('token', response.data.data.login.token ?? null);
          sessionStorage.setItem('group', response.data.data.login.info.groups ? response.data.data.login.info.groups.name : null);
          $cookies.set("token", response.data.data.login.token ? response.data.data.login.token : null, 60 * 60 * 8);
          $cookies.set("group", response.data.data.login.info.groups ? response.data.data.login.info.groups.name : null, 60 * 60 * 8);
          this.$store.state.isAuth = true;
          this.$store.state.refresh_token = response.data.data.login.refreshToken ? response.data.data.login.refreshToken : null;
          this.$store.state.rol = response.data.data.login.info.groups ? response.data.data.login.info.groups.name : null;
          this.$store.state.username = response.data.data.login.info ? response.data.data.login.info.username : null;
          this.$store.state.email = response.data.data.login.info ? response.data.data.login.info.email : null;
          this.$store.state.userid = response.data.data.login.info ? parseInt(response.data.data.login.info.id) : null;
          this.$store.state.firstName = response.data.data.login.infor ? response.data.data.login.info.first_name : null;
          this.$store.state.lastName = response.data.data.login.info ? response.data.data.login.info.last_name : null;
          const permisos = response.data.data.login.info.groups.permission.edges.map(value => {
            return value.node;
          })
          this.SetPermisos(permisos.length > 0 ? permisos : null);
          this.$router.push({
            name: 'Dashboard'
          });
          reloadHeader();
        } else {
          this.messageError();
        }
      })
          .catch((error) => {
            console.log(error)
            // this.messageError();
          }).finally(() => {
        this.loading = false
      });
    }
  },
};
</script>
