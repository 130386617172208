<template>
  <div class="card mb-25 border-0 rounded-0 bg-white authentication-card">
    <div class="card-body letter-spacing">
      <h4 class="text-black fw-bold mb-0 text-center">Nuevo usuario</h4>
      <div class="row">
        <div class="col-lg-6 col-md-12 col-sm-12">
          <label class="d-block text-black fw-semibold mb-10">Name</label>
          <input type="text" class="form-control shadow-none rounded-0 text-black" placeholder="e.g. Jacob Adam">
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12">
          <label class="d-block text-black fw-semibold mb-10"> Email Address </label>
          <input type="email" class="form-control shadow-none rounded-0 text-black"
                 placeholder="e.g. adam127704@gmail.com">
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12">
          <label class="d-block text-black fw-semibold mb-10"> Password </label>
          <input type="password" class="form-control shadow-none rounded-0 text-black" placeholder="**************">
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12">
          <label class="d-block text-black fw-semibold mb-10"> Confirm Password </label>
          <input type="password" class="form-control shadow-none rounded-0 text-black" placeholder="**************">
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12">
          <button
              class="default-btn transition border-0 fw-medium text-white rounded-1 fs-md-15 fs-lg-16 bg-success d-block w-100"
              type="submit">
            Cancelar
          </button>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12">
          <button
              class="default-btn with-border transition fw-medium rounded-1 fs-md-15 fs-lg-16 d-block w-100 text-decoration-none text-center">
            Guardar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RegistroForm"
}
</script>

<style scoped>

</style>
