<template>
  <BreadCrumb PageTitle="Reporte de ventas" Subtitle="Administración"></BreadCrumb>
  <ReportComponent :headers="headers" :url="url" :action="getReportSellAuto" typeReport="reportSellByAuto"
                   :auto="true" :pagination="true"/>
</template>

<script>
import ReportComponent from "@/components/Reports/ReportComponent.vue";
import BreadCrumb from "@/components/BreadCrumb.vue";
import {getReportSellAuto} from "@/actions";

export default {
  name: "ReportSellAuto",
  components: {BreadCrumb, ReportComponent},
  data() {
    return {
      headers : [
        {
          title: "Vehículo",
          value: "autoName",
          isMoney: false
        },
        {
          title: "Color",
          value: "auto.color",
          isMoney: false
        },
        {
          title: "Categoría",
          value: "auto.categoria.name",
          isMoney: false
        },
        {
          title: "Número Económico",
          value: "auto.noEconomico",
          isMoney: false
        },
        {
          title: "Placa",
          value: "auto.noPlaca",
          isMoney: false
        },
        {
          title: "Número de serie",
          value: "auto.noSerie",
          isMoney: false
        },
        {
          title: "Contratos",
          value: "contracts",
          isMoney: false
        },
        {
          title: "ROI",
          value: "roi",
          isMoney: true
        },
        {
          title: "Ganacia",
          value: "gan",
          color: ["#c3193f", "#569c1a"],
          isMoney: true
        }
      ],
      url: `${process.env.VUE_APP_URL_SERVICES}report/sell/auto`
    }
  },
  methods: {
    getReportSellAuto
  }
}
</script>

<style scoped>

</style>