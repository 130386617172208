<template>
  <div class="Cargando">
    <div v-show="loading" :class="['modal_cargando', { 'show': loading }]">
      <div class="spinner"></div>
    </div>
  </div>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div
        class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25"
    >
      <div class="d-sm-flex align-items-center">
        <form class="search-box position-relative">
          <input
              type="text" v-model="search"
              class="form-control shadow-none text-black rounded-0 border-0"
              placeholder="Buscar categoría"
          />
          <button class="bg-transparent text-primary transition p-0 border-0">
            <i class="flaticon-search-interface-symbol"></i>
          </button>
        </form>
      </div>
      <div class="d-sm-flex align-items-center mt-10 mt-lg-0">
        <button
            @click="Nuevo"
            class="bg-primary position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block d-inline-block text-decoration-none"
        >
          Nueva categor&iacute;a
          <i class="flaticon-plus position-relative ms-5 fs-12"></i>
        </button>
      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
          <tr>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Imagen
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Categor&iacute;a
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Transmisi&oacute;n
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Tarifa de<br> temporada alta
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Tarifa de<br> temporada baja
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Descripci&oacute;n
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
            >
              Acciones
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in items" :key="item.id">
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              <div class="ms-3" v-if="item.imagen!==''">
                <img class="rounded-circle img-categoria cursor-pointer"
                     :src="getImageUrl(item.imagen)">
              </div>
              <div class="ms-3" v-else>
                <img class="rounded-circle img-categoria cursor-pointer"
                     src="@/assets/images/autos/no-photo.jpg" alt="image">
              </div>
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.name }}
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.transmision }}
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.tarifaAlta }}
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.tarifaBaja }}
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.descripcion }}
            </td>
            <td class="shadow-none lh-1 fw-bold text-body-tertiary">
              <div class="btn-group" role="group">
                <button v-if="item.isActive" data-bs-toggle="tooltip"
                        style="padding: 0.8rem"
                        data-bs-placement="top" title="Desactivar categoría" data-container="body"
                        data-animation="true" class="btn text-success" @click="Desactivar(item.id)">
                  <i class="flaticon-ban d-flex justify-content-center align-items-center"></i></button>
                <button v-else data-bs-toggle="tooltip" data-bs-placement="top"
                        style="padding: 0.8rem"
                        title="Activar categoría" data-container="body" data-animation="true"
                        class="btn text-warning" @click="Activar(item.id)">
                  <i class="flaticon-check-mark-1 d-flex justify-content-center align-items-center"></i></button>
                <button data-bs-toggle="tooltip" @click="Actualizar(item)"
                        data-bs-placement="top"
                        style="padding: 0.8rem"
                        title="Editar categoría" data-container="body" data-animation="true"
                        class="btn text-info">
                  <i class="flaticon-pen d-flex justify-content-center align-items-center "></i></button>
                <button data-bs-toggle="tooltip" @click="Autos(item)"
                        data-bs-placement="top"
                        style="padding: 0.8rem"
                        title="Ver vehículos" data-container="body" data-animation="true"
                        class="btn text-secondary"><img style="width: 25px; height: 25px"
                                                        src="@/assets/images/icons/car.png">
                </button>
                <button data-bs-toggle="tooltip" @click="open_Imagenes(item)"
                        data-bs-placement="top"
                        style="padding: 0.8rem"
                        title="Subir imágenes de categoría" data-container="body" data-animation="true"
                        class="btn text-success">
                  <i class="flaticon-image  d-flex justify-content-center align-items-center "></i></button>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing pt-2 pb-2">
    <div class="row">
      <div class="col-lg-3 d-flex align-items-center justify-content-center text-dark">

      </div>
      <div class="col-lg-6 d-flex align-items-center justify-content-center text-dark">
        <p class="me-3" style="margin-bottom: 0px">Elementos por p&aacute;gina</p>
        <select class="form-control" style="width: 50px; height: 40px; padding: 5px" v-model="cant">
          <option :value=10>10</option>
          <option :value=50>50</option>
          <option :value=100>100</option>
        </select>
      </div>
      <div class="col-lg-3 d-flex align-items-center justify-content-center text-dark">
        <button class="btn btn-dark me-3" style="height: 40px" @click="prevPage" :disabled="hasPreviousPage===false"><i
            class="flaticon-chevron-1 "></i></button>
        <button class="btn btn-dark" style="height: 40px" @click="nextPage" :disabled="hasNextPage===false"><i
            class="flaticon-chevron"></i></button>
      </div>
    </div>
  </div>
  <div class="Nuevo text-dark">
    <div v-if="Nuevo_Modal" :class="['modal', { 'show': Nuevo_Modal }]">
      <div class="modal-content bg-white">
        <div class="row mb-3 d-flex align-items-center">
          <h4 class="text-start fw-bold">
            Nueva categor&iacute;a
            <i @click="Close_nuevo" class="flaticon-close opacity-10 modal-icon fs-15"></i>
          </h4>
        </div>
        <div v-if="primera_ventana" class="row mb-3 text-start">
          <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">Nombre</label>
            <input id="categoria_name" autofocus v-model="name" :disabled="saveChanges"
                   class="form-control shadow-none rounded-0 text-black">
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">Descripci&oacute;n</label>
            <input id="categoria_descripcion" v-model="descripcion" :disabled="saveChanges"
                   class="form-control shadow-none rounded-0 text-black">
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">Tarifa temporada alta</label>
            <input id="categoria_tarifa_alta" v-model="tarifa_alta" type="number" :disabled="saveChanges"
                   class="form-control shadow-none rounded-0 text-black">
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">Tarifa temporada baja</label>
            <input id="categoria_tarifa_baja" v-model="tarifa_baja" type="number" :disabled="saveChanges"
                   class="form-control shadow-none rounded-0 text-black">
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">Transmisi&oacute;n</label>
            <select id="categoria_transmision" v-model="transmision" :disabled="saveChanges"
                    class="form-select shadow-none rounded-0 text-black">
              <option value="">Seleccione el tipo de transmisi&oacute;n</option>
              <option value="Manual">Manual</option>
              <option value="Automática">Automática</option>
            </select>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
            <div class="form-check fs-md-15 fs-lg-16 text-black mb-0 d-inline-block me-15 me-md-25">
              <input v-model="puntos" class="form-check-input shadow-none" type="checkbox" name="communicationRadio"
                     id="activeRadio" checked="" :disabled="saveChanges">
              <label class="form-check-label" for="activeRadio"> Otorga puntos </label>
            </div>
          </div>
        </div>
        <div v-else class="row mb-3 text-start">
          <label class="form-label">Seleccione una Imagen</label>
          <input type="file" :disabled="saveChanges" class="form-control mb-3 p-2 border-dark border-bottom"
                 @change="onImagenSeleccionada">
          <img :src="preview" class="w-75" style="height: 50vh">
        </div>
        <div class="row mb-3">
          <div v-show="error_vacios" class="col-12 text-center p-2"
               style="background-color: rgba(255,19,28,0.08)">
              <span class="d-flex align-items-center justify-content-center">
                <i class="flaticon-warning me-2 fs-15"></i>
                No pueden haber campos vac&iacute;os
              </span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-lg-6 d-flex justify-content-start mb-3">
            <button v-if="primera_ventana" :disabled="saveChanges" @click="Close_nuevo"
                    class="btn btn-secondary d-block w-100" type="button">
              Cancelar
            </button>
            <button v-else :disabled="saveChanges" @click="primera_ventana=true" class="btn btn-secondary d-block w-100"
                    type="button">
              Regresar
            </button>
          </div>
          <div class="col-md-12 col-lg-6 d-flex justify-content-end mb-3">
            <button v-if="primera_ventana" :disabled="saveChanges" @click="primera_ventana=false"
                    class="btn btn-danger d-block w-100"
                    type="button">Siguiente
            </button>
            <button v-else @click="Crear" :disabled="saveChanges" class="btn btn-danger d-block w-100" type="button">
              <div v-if="saveChanges" class="spinner-border text-dark" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
              <span v-else>Guardar</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="Actualizar text-dark">
    <div v-if="Actualizar_Modal" :class="['modal', { 'show': Actualizar_Modal }]">
      <div class="modal-content bg-white">
        <div class="row mb-3 d-flex align-items-center">
          <h4 class="text-start fw-bold">
            Actualizar categor&iacute;a
            <i @click="Close_update" class="flaticon-close opacity-10 modal-icon fs-15"></i>
          </h4>
        </div>
        <div v-if="primera_ventana" class="row mb-3 text-start">
          <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">Nombre</label>
            <input id="categoria_name" autofocus v-model="name" :disabled="saveChanges"
                   class="form-control shadow-none rounded-0 text-black">
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">Descripci&oacute;n</label>
            <input id="categoria_descripcion" v-model="descripcion" :disabled="saveChanges"
                   class="form-control shadow-none rounded-0 text-black">
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">Tarifa temporada alta</label>
            <input id="categoria_tarifa_alta" v-model="tarifa_alta" type="number" :disabled="saveChanges"
                   class="form-control shadow-none rounded-0 text-black">
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">Tarifa temporada baja</label>
            <input id="categoria_tarifa_baja" v-model="tarifa_baja" type="number" :disabled="saveChanges"
                   class="form-control shadow-none rounded-0 text-black">
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">Transmisi&oacute;n</label>
            <select id="categoria_transmision" v-model="transmision" :disabled="saveChanges"
                    class="form-select shadow-none rounded-0 text-black">
              <option value="">Seleccione el tipo de transmisi&oacute;n</option>
              <option value="Manual">Manual</option>
              <option value="Automática">Autom&aacute;tica</option>
            </select>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
            <div class="form-check fs-md-15 fs-lg-16 text-black mb-0 d-inline-block me-15 me-md-25">
              <input :disabled="saveChanges" v-model="puntos" class="form-check-input shadow-none" type="checkbox"
                     name="communicationRadio"
                     id="activeRadio" checked="">
              <label class="form-check-label" for="activeRadio"> Otorga puntos </label>
            </div>
          </div>
        </div>
        <div v-else class="row mb-3 text-start">
          <label class="form-label">Seleccione una Imagen</label>
          <input :disabled="saveChanges" type="file" class="form-control mb-3 p-2 border-dark border-bottom"
                 @change="onImagenSeleccionada">
          <img :src="preview" class="w-75" style="height: 50vh">
        </div>
        <div class="row mb-3">
          <div v-show="error_vacios" class="col-12 text-center p-2"
               style="background-color: rgba(255,19,28,0.08)">
              <span class="d-flex align-items-center justify-content-center">
                <i class="flaticon-warning me-2 fs-15"></i>
                No pueden haber campos vac&iacute;os
              </span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-lg-6 d-flex justify-content-start mb-3">
            <button :disabled="saveChanges" v-if="primera_ventana" @click="Close_update"
                    class="btn btn-secondary d-block w-100" type="button">
              Cancelar
            </button>
            <button v-else :disabled="saveChanges" @click="primera_ventana=true" class="btn btn-secondary d-block w-100"
                    type="button">
              Regresar
            </button>
          </div>
          <div class="col-md-12 col-lg-6 d-flex justify-content-end mb-3">
            <button v-if="primera_ventana" :disabled="saveChanges" @click="primera_ventana=false"
                    class="btn btn-danger d-block w-100"
                    type="button">Siguiente
            </button>
            <button :disabled="saveChanges" v-else @click="Editar" class="btn btn-danger d-block w-100" type="button">
              <div v-if="saveChanges" class="spinner-border text-dark" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
              <span v-else>Guardar</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="Imagenes text-dark">
    <div v-if="File_Modal" :class="['modal', { 'show': File_Modal }]">
      <div class="modal-content bg-white">
        <div class="row mb-3 d-flex align-items-center">
          <h4 class="text-start fw-bold">
            Subir o editar imágenes de la categor&iacute;a
            <i @click="Close_imagenes" class="flaticon-close opacity-10 modal-icon fs-15"></i>
          </h4>
        </div>
        <div class="row mb-3 text-start">
          <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
            <label class="form-label">Seleccione una Imagen</label>
            <input :disabled="saveChanges" type="file" class="form-control mb-3 p-2 border-dark border-bottom"
                   @change="Imagen1">
            <img :src="preview_imagen1" class="w-75" style="height: 26vh">
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
            <label class="form-label">Seleccione una Imagen</label>
            <input :disabled="saveChanges" type="file" class="form-control mb-3 p-2 border-dark border-bottom"
                   @change="Imagen2">
            <img :src="preview_imagen2" class="w-75" style="height: 26vh">
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
            <label class="form-label">Seleccione una Imagen</label>
            <input :disabled="saveChanges" type="file" class="form-control mb-3 p-2 border-dark border-bottom"
                   @change="Imagen3">
            <img :src="preview_imagen3" class="w-75" style="height: 26vh">
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
            <label class="form-label">Seleccione una Imagen</label>
            <input :disabled="saveChanges" type="file" class="form-control mb-3 p-2 border-dark border-bottom"
                   @change="Imagen4">
            <img :src="preview_imagen4" class="w-75" style="height: 26vh">
          </div>

        </div>
        <div class="row mb-3">
          <div v-show="error_images" class="col-12 text-center p-2"
               style="background-color: rgba(255,19,28,0.08)">
              <span class="d-flex align-items-center justify-content-center">
                <i class="flaticon-warning me-2 fs-15"></i>
                No pueden haber campos vac&iacute;os
              </span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-lg-6 d-flex justify-content-start mb-3">
            <button :disabled="saveChanges" @click="Close_imagenes" class="btn btn-secondary d-block w-100"
                    type="button">Cancelar
            </button>
          </div>
          <div class="col-md-12 col-lg-6 d-flex justify-content-end mb-3">
            <button :disabled="saveChanges" @click="Guardar_Imagenes" class="btn btn-danger d-block w-100"
                    type="button">
              <div v-if="saveChanges" class="spinner-border text-dark" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
              <span v-else>Guardar</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import {
  searchCategories,
  searchNextCategories,
  searchPrevCategories,
  createCategory,
  updateCategory,
  uploadPhotos,
  uploadPhotoCategory,
  enableCategory,
  disableCategory
} from "@/actions";
import {decodeBase64} from "@/util";
import {srac_url_image} from "@/actions/constants";

export default {
  name: "CategoriaList",
  data() {
    return {
      categorias: [],
      search: "",
      Nuevo_Modal: false,
      Actualizar_Modal: false,
      File_Modal: false,
      name: '',
      descripcion: '',
      tarifa_alta: 0,
      tarifa_baja: 0,
      transmision: '',
      phone: "",
      id: 0,
      preview: null,
      error_vacios: false,
      imagen: null,
      primera_ventana: true,
      imagen1: '',
      imagen2: '',
      imagen3: '',
      imagen4: '',
      preview_imagen1: '',
      preview_imagen2: '',
      preview_imagen3: '',
      preview_imagen4: '',
      error_images: false,
      puntos: false,

      hasNextPage: false,
      hasPreviousPage: false,
      startCursor: "",
      lastStartCursor: "",
      endCursor: "",
      lastEndCursor: "",
      cant: 10,
      items: [],
      loading: false,
      saveChanges: false,
      toast: Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        customClass: {
          popup: 'bg-white',
          title: 'text-dark'
        },
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        }
      }),
    }
  },
  watch: {
    items() {
      this.loading = false;
    },
    cant() {
      this.loadDatas(true);
    },
    search() {
      this.loadDatas(true)
    }
  },
  methods: {
    getImageUrl(imagen) {
      return srac_url_image + imagen;
    },
    Nuevo() {
      this.Nuevo_Modal = true
    },
    Actualizar(item) {
      this.imagen = null
      this.name = item.name;
      this.transmision = item.transmision;
      this.tarifa_alta = item.tarifaAlta
      this.tarifa_baja = item.tarifaBaja
      this.descripcion = item.descripcion
      this.puntos = item.puntos
      this.id = item.id
      if (item.imagen) {
        this.preview = this.getImageUrl(item.imagen);
      }
      this.Actualizar_Modal = true;
    },
    Close_nuevo() {
      this.primera_ventana = true
      this.$store.state.Cargando = false
      this.name = ''
      this.transmision = ''
      this.tarifa_alta = 0
      this.tarifa_baja = 0
      this.descripcion = ''
      this.id = 0
      this.preview = null
      this.imagen = null
      this.Nuevo_Modal = false
    },
    Close_update() {
      this.primera_ventana = true
      this.$store.state.Cargando = false
      this.name = ''
      this.transmision = ''
      this.tarifa_alta = 0
      this.tarifa_baja = 0
      this.descripcion = ''
      this.id = 0
      this.preview = null
      this.imagen = null
      this.Actualizar_Modal = false
    },
    Crear() {
      this.$store.state.Cargando = true
      let validacion = true
      if (this.imagen == null) {
        this.error_vacios = true
        this.$store.state.Cargando = false
        return false
      }
      if (this.name === '') {
        document.getElementById('categoria_name').classList.add('is-invalid')
        validacion = false
      }
      if (this.transmision === '') {
        document.getElementById('categoria_transmision').classList.add('is-invalid')
        validacion = false
      }
      if (this.descripcion === '') {
        document.getElementById('categoria_descripcion').classList.add('is-invalid')
        validacion = false
      }
      if (this.tarifa_alta === 0) {
        document.getElementById('categoria_tarifa_alta').classList.add('is-invalid')
        validacion = false
      }
      if (this.tarifa_baja === 0) {
        document.getElementById('categoria_tarifa_baja').classList.add('is-invalid')
        validacion = false
      }
      if (validacion) {
        this.error_vacios = false;
        this.saveChanges = true;
        createCategory({
          name: this.name,
          tarifaAlta: this.tarifa_alta,
          tarifaBaja: this.tarifa_baja,
          descripcion: this.descripcion,
          transmision: this.transmision,
          puntos: this.puntos
        }).then(response => {
          if (response.data.data.createCategoria.success) {
            this.id = response.data.data.createCategoria.id
            this.error_vacios = false
            if (this.imagen !== null) {
              uploadPhotoCategory({
                id: decodeBase64(this.id),
                photo: this.imagen
              }).then(resp => {
                const Toast = Swal.mixin({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  customClass: {
                    popup: 'bg-white',
                    title: 'text-dark'
                  },
                  timerProgressBar: true,
                  didOpen: (toast) => {
                    toast.addEventListener("mouseenter", Swal.stopTimer);
                    toast.addEventListener("mouseleave", Swal.resumeTimer);
                  }
                });
                Toast.fire({
                  icon: "success",
                  title: "Operación realizada con éxito"
                });
                this.Close_nuevo();
                this.loadDatas();
              })
            }
          } else {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              customClass: {
                popup: 'bg-white',
                title: 'text-dark'
              },
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "error",
              title: response.data.data.createCategoria.error
            });
          }
        }).catch(error => {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            customClass: {
              popup: 'bg-white',
              title: 'text-dark'
            },
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "error",
            title: error
          });
        }).finally(() => {
          this.saveChanges = false;
        });
      } else {
        this.error_vacios = true
        return false
      }
    },
    Editar() {
      this.$store.state.Cargando = true
      let validacion = true
      if (this.name === '') {
        document.getElementById('categoria_name').classList.add('is-invalid')
        validacion = false
      }
      if (this.transmision === '') {
        document.getElementById('categoria_transmision').classList.add('is-invalid')
        validacion = false
      }
      if (this.descripcion === '') {
        document.getElementById('categoria_descripcion').classList.add('is-invalid')
        validacion = false
      }
      if (this.tarifa_alta === 0) {
        document.getElementById('categoria_tarifa_alta').classList.add('is-invalid')
        validacion = false
      }
      if (this.tarifa_baja === 0) {
        document.getElementById('categoria_tarifa_baja').classList.add('is-invalid')
        validacion = false
      }
      if (validacion) {
        this.error_vacios = false;
        this.saveChanges = true;
        updateCategory({
          name: this.name,
          tarifaAlta: this.tarifa_alta,
          tarifaBaja: this.tarifa_baja,
          descripcion: this.descripcion,
          transmision: this.transmision,
          id: decodeBase64(this.id),
          puntos: this.puntos
        }).then(response => {
          if (response.data.data.updateCategoria.success) {
            this.error_vacios = false
            if (this.imagen !== null) {
              return uploadPhotoCategory({
                id: decodeBase64(this.id),
                photo: this.imagen
              }).then(resp => {
                const Toast = Swal.mixin({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  customClass: {
                    popup: 'bg-white',
                    title: 'text-dark'
                  },
                  timerProgressBar: true,
                  didOpen: (toast) => {
                    toast.addEventListener("mouseenter", Swal.stopTimer);
                    toast.addEventListener("mouseleave", Swal.resumeTimer);
                  }
                });
                Toast.fire({
                  icon: "success",
                  title: "Operación realizada con éxito"
                });
                this.Close_update();
                this.loadDatas();
              })
            }
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              customClass: {
                popup: 'bg-white',
                title: 'text-dark'
              },
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "success",
              title: "Operación realizada con éxito"
            });
            this.Close_update();
            this.loadDatas();
          } else {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              customClass: {
                popup: 'bg-white',
                title: 'text-dark'
              },
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "error",
              title: response.data.data.updateCategoria.error
            });
          }
        }).catch(error => {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            customClass: {
              popup: 'bg-white',
              title: 'text-dark'
            },
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "error",
            title: error
          });
        }).finally(() => {
          this.saveChanges = false;
        });
      } else {
        this.error_vacios = true
        return false
      }
    },
    Activar(id) {
      enableCategory({
        id: decodeBase64(id)
      }).then(response => {
        if (response.data.data.activeCategoria.success) {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            customClass: {
              popup: 'bg-white',
              title: 'text-dark'
            },
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "success",
            title: "Operación realizada con éxito"
          });
          this.loadDatas();
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            customClass: {
              popup: 'bg-white',
              title: 'text-dark'
            },
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "error",
            title: "Error al activar la categoría"
          });
        }
      }).catch(error => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          customClass: {
            popup: 'bg-white',
            title: 'text-dark'
          },
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "error",
          title: error
        });
      })
    },
    Desactivar(id) {
      disableCategory({
        id: decodeBase64(id)
      }).then(response => {
        if (response.data.data.desactiveCategoria.success) {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            customClass: {
              popup: 'bg-white',
              title: 'text-dark'
            },
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "success",
            title: "Operación realizada con éxito"
          });
          this.loadDatas();
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            customClass: {
              popup: 'bg-white',
              title: 'text-dark'
            },
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "error",
            title: "Error al desactivar la categoría"
          });
        }
      }).catch(error => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          customClass: {
            popup: 'bg-white',
            title: 'text-dark'
          },
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "error",
          title: error
        });
      })
    },
    Autos(categoria) {
      this.$store.state.categoria = categoria
      this.$router.push({
        name: 'Autos'
      })
    },
    onImagenSeleccionada(event) {
      const file = event.target.files[0];
      this.preview = URL.createObjectURL(file);
      const reader = new FileReader();
      reader.onload = () => {
        this.imagen = reader.result
      }
      reader.readAsDataURL(file);
    },
    open_Imagenes(item) {
      if (item.image1) {
        this.preview_imagen1 = this.getImageUrl(item.image1);
      }
      if (item.image2) {
        this.preview_imagen2 = this.getImageUrl(item.image2);
      }
      if (item.image3) {
        this.preview_imagen3 = this.getImageUrl(item.image3);
      }
      if (item.image4) {
        this.preview_imagen4 = this.getImageUrl(item.image4);
      }
      this.id = item.id
      this.File_Modal = true
    },
    Imagen1(event) {
      const file = event.target.files[0];
      this.preview_imagen1 = URL.createObjectURL(file);
      const reader = new FileReader();
      reader.onload = () => {
        this.imagen1 = reader.result
      }
      reader.readAsDataURL(file);
    },
    Imagen2(event) {
      const file = event.target.files[0];
      this.preview_imagen2 = URL.createObjectURL(file);
      const reader = new FileReader();
      reader.onload = () => {
        this.imagen2 = reader.result
      }
      reader.readAsDataURL(file);
    },
    Imagen3(event) {
      const file = event.target.files[0];
      this.preview_imagen3 = URL.createObjectURL(file);
      const reader = new FileReader();
      reader.onload = () => {
        this.imagen3 = reader.result
      }
      reader.readAsDataURL(file);
    },
    Imagen4(event) {
      const file = event.target.files[0];
      this.preview_imagen4 = URL.createObjectURL(file);
      const reader = new FileReader();
      reader.onload = () => {
        this.imagen4 = reader.result
      }
      reader.readAsDataURL(file);
    },
    Guardar_Imagenes() {
      this.loading = true;
      this.saveChanges = true;
      uploadPhotos({
        id: decodeBase64(this.id),
        image1: this.imagen1,
        image2: this.imagen2,
        image3: this.imagen3,
        image4: this.imagen4
      }).then(response => {
        if (response.data.data.uploadAutoPhoto.success) {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            customClass: {
              popup: 'bg-white',
              title: 'text-dark'
            },
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "success",
            title: "Operación realizada con éxito"
          });
          this.Close_imagenes();
          this.loadDatas();
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            customClass: {
              popup: 'bg-white',
              title: 'text-dark'
            },
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "error",
            title: "Error al realizar la operación"
          });
        }
      }).catch(err => {
        this.toast.fire({
          icon: "error",
          title: err.message
        });
      }).finally(() => {
        this.saveChanges = false;
      });
    },
    Close_imagenes() {
      this.cargando = false
      this.imagen1 = ''
      this.imagen2 = ''
      this.imagen3 = ''
      this.imagen4 = ''
      this.preview_imagen1 = null
      this.preview_imagen2 = null
      this.preview_imagen3 = null
      this.preview_imagen4 = null
      this.id = 0
      this.error_images = false
      this.File_Modal = false
    },

    loadDatas(init = false) {
      this.loading = true
      let next = "";
      let before = "";
      if (init === false) {
        before = this.lastEndCursor;
        next = this.lastStartCursor;
      }
      searchCategories({
        name: this.search,
        before: before,
        after: next,
        first: this.cant
      }).then(resp => {
        if (init === true) {
          this.hasNextPage = resp.data.data.searchCategories.pageInfo.hasNextPage;
          this.hasPreviousPage = resp.data.data.searchCategories.pageInfo.hasPreviousPage;
        }
        this.startCursor = resp.data.data.searchCategories.pageInfo.startCursor;
        this.endCursor = resp.data.data.searchCategories.pageInfo.endCursor;
        this.items = resp.data.data.searchCategories.edges.map(value => {
          return value.node;
        });
      }).catch(err => {
        this.toast.fire({
          icon: "error",
          title: err.message
        });
      }).finally(() => {
        this.loading = false;
      });
    },
    nextPage() {
      this.loading = true;
      searchNextCategories({
        name: this.search,
        after: this.endCursor,
        first: this.cant
      }).then(resp => {
        this.lastEndCursor = "";
        this.lastStartCursor = this.endCursor;
        this.hasNextPage = resp.data.data.searchCategories.pageInfo.hasNextPage;
        this.hasPreviousPage = true;
        this.startCursor = resp.data.data.searchCategories.pageInfo.startCursor;
        this.endCursor = resp.data.data.searchCategories.pageInfo.endCursor;
        this.items = resp.data.data.searchCategories.edges.map(value => {
          return value.node;
        });
      }).catch((err) => {
        this.toast.fire({
          icon: "error",
          title: err.message
        });
      }).finally(() => {
        this.loading = false;
      });
    },
    prevPage() {
      this.loading = true;
      searchPrevCategories({
        name: this.search,
        before: this.startCursor,
        first: this.cant
      }).then(resp => {
        this.lastEndCursor = this.startCursor;
        this.lastStartCursor = "";
        this.hasNextPage = true;
        this.hasPreviousPage = resp.data.data.searchCategories.pageInfo.hasPreviousPage;
        this.startCursor = resp.data.data.searchCategories.pageInfo.startCursor;
        this.endCursor = resp.data.data.searchCategories.pageInfo.endCursor;
        this.items = resp.data.data.searchCategories.edges.map(value => {
          return value.node;
        });
      }).catch((err) => {
        this.toast.fire({
          icon: "error",
          title: err.message
        });
      }).finally(() => {
        this.loading = false;
      });
    },
  },
  mounted() {
    this.loadDatas(true);
  }
};
</script>
<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.modal-content {
  padding: 20px;
  width: 50%;
}

@media (max-width: 767px) {
  .modal-content {
    border-radius: 15px;
    padding: 10px;
    width: 90%;
  }
}

.modal {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.modal.show {
  opacity: 1;
}

.img-categoria {
  height: 50px;
  width: 50px;
  object-fit: cover;
}
</style>
